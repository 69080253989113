import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import styled from "styled-components";
import { Button } from "../../../../components/Button/Button";
import { Navbar } from "../../../../components/Navbar/Navbar";
import { fontSize, fontWeight, lineHeight, spacing } from "../../../../theme";
import { useTypedLocation } from "../../../../utils/hooks/useTypedLocation";
import useUser from "../../../../utils/hooks/useUser";
import { navigateToNewState } from "../../../../utils/location/navigateToNewState";
import { ManageProfile } from "../ManageProfile";
import { messages } from "../ManageProfile.messages";

export function ManageProfileButton() {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useTypedLocation();

  function openManageProfile() {
    sendAnalyticsInteractionEvent({
      category: "UserAccount",
      action: "Click:ManageProfile",
    });
    navigateToNewState(
      navigate,
      { preferencesScreen: "manageProfile" },
      location
    );
  }
  return (
    <StyledButton
      backgroundColor="transparent"
      textColor="pinkOnLight"
      size="large"
      inline
      onClick={openManageProfile}
    >
      {intl.formatMessage(messages.manageProfile)}
    </StyledButton>
  );
}

export function ManageProfileScreen() {
  const intl = useIntl();
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useTypedLocation();

  const handleBack = () => {
    navigateToNewState(navigate, { preferencesScreen: "main" }, location);
  };

  return (
    <>
      <Navbar
        headingText={intl.formatMessage(messages.manageProfile)}
        onBackClick={handleBack}
      />
      <Container>{user && <ManageProfile user={user} />}</Container>
    </>
  );
}

const StyledButton = styled(Button)`
  font-weight: ${fontWeight.normal};
  font-size: ${fontSize.body};
  line-height: ${lineHeight.tight};
  min-height: 22px;
  padding: 0;
`;

const Container = styled.div`
  padding: ${spacing.xxl} ${spacing.xl};
  // This is the height minus nav, fixed button and safari menu
  // 55px = bottom nav height
  // 44px = heading height
  // env(safe-area-inset-bottom) = safari menu
  min-height: calc(100vh - 55px - 44px - env(safe-area-inset-bottom));
  overflow-y: hidden;
`;
