import { useIntl } from "react-intl";
import { useNavigateToNewLanguage } from "src/utils/hooks/useNavigateToNewLanguage";
import useUser from "src/utils/hooks/useUser";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { RadioGroupList } from "../../components/RadioGroupList/RadioGroupList";
import {
  isSupportedLanguageCode,
  supportedLanguageCodes,
  supportedLanguageNames,
} from "../../utils/language";
import { Flag } from "../Flag/Flag";
import messages from "./LanguageScreen.messages";

export function useLanguagePreferenceScreen() {
  const intl = useIntl();

  return {
    heading: intl.formatMessage(messages.heading),
    description: intl.formatMessage(messages.description),
  };
}

export function LanguageScreenContent() {
  const { navigateToNewLanguage } = useNavigateToNewLanguage();
  const { language, saveLanguage } = useUser();

  function handleLanguageChange(languageCode: string) {
    if (!isSupportedLanguageCode(languageCode)) {
      throw new Error(
        `received ${languageCode} but expected one of: ${supportedLanguageCodes.join(
          ", "
        )}`
      );
    }

    sendAnalyticsInteractionEvent({
      category: "Preferences",
      action: "Click:SelectLanguage",
      label: `${language}->${languageCode}`,
    });

    saveLanguage(languageCode);

    // go back to main user preferences screen
    navigateToNewLanguage(languageCode);
  }

  let supportedLanguages = supportedLanguageCodes.map((language) => ({
    value: language,
    label: supportedLanguageNames[language],
    icon: <Flag language={language} />,
  }));

  return (
    <RadioGroupList
      name="language"
      selectedValue={language}
      onChange={handleLanguageChange}
      options={supportedLanguages}
    />
  );
}
