import type {
  ComponentType,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
} from "react";
import { spacing } from "src/design-system/tokens/spacing";
import { font_weight } from "src/design-system/tokens/typography";
import type { SvgProps } from "src/svg/common";
import { type iconSize, fontSize, textColor } from "src/theme";
import styled from "styled-components";
import { ButtonBase } from "../Button/ButtonBase";
import { Icon } from "../Icon/Icon";

type Props = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  fontSize?: keyof typeof fontSize;
  textColor?: keyof typeof textColor;
  leftIcon?: ReactNode;
  LeftIcon?: ComponentType<React.PropsWithChildren<SvgProps>>;
  leftIconSize?: keyof typeof iconSize;
  rightIcon?: ReactNode;
  RightIcon?: ComponentType<React.PropsWithChildren<SvgProps>>;
  rightIconSize?: keyof typeof iconSize;
  disabled?: boolean;
  underline?: boolean;
  className?: string;
};

export function TextButton({
  onClick,
  children,
  textColor = "pinkOnLight",
  fontSize = "md",
  leftIcon,
  LeftIcon,
  leftIconSize = "xs",
  rightIcon,
  RightIcon,
  rightIconSize = "xs",
  disabled,
  underline,
  className,
}: PropsWithChildren<Props>) {
  function renderIcon(
    element: ReactNode,
    component: ComponentType<React.PropsWithChildren<SvgProps>> | undefined,
    size: keyof typeof iconSize
  ) {
    if (element === undefined && component === undefined) {
      return;
    }
    // Hack to get around typescript complaining about component possibly being undefined
    const Component = component!;

    return <Icon size={size}>{element || <Component tint={textColor} />}</Icon>;
  }

  return (
    <TextButtonWrapper
      onClick={!disabled ? onClick : undefined}
      color={textColor}
      disabled={disabled}
      fontSize={fontSize}
      $underline={underline}
      className={className}
    >
      {renderIcon(leftIcon, LeftIcon, leftIconSize)}
      <span>{children}</span>
      {renderIcon(rightIcon, RightIcon, rightIconSize)}
    </TextButtonWrapper>
  );
}

const TextButtonWrapper = styled(ButtonBase)<{
  color: keyof typeof textColor;
  fontSize: keyof typeof fontSize;
  $underline?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: unset;
  gap: ${spacing.md};

  padding: ${spacing.md} 0;

  color: ${(props) => textColor[props.color]};
  font-size: ${(props) => fontSize[props.fontSize]};
  font-weight: ${font_weight.bold};
  line-height: 1; // No line height, so that adjacent icons align correctly

  ${(props) => props.$underline && `text-decoration: underline;`}

  &:hover {
    text-decoration: underline;
  }

  :disabled {
    color: ${textColor.disabledOnLight};
  }
`;
