import type { Mode } from "src/utils/types/mode";
import type { Screen } from "src/analytics/generateScreenComponentsRendered/Screen";
import type { ScreenComponent } from "src/analytics/generateScreenComponentsRendered/ScreenComponent";
import type { ScreenLocations } from "src/analytics/getLocations/getLocations";
import type { CustomDimensions } from "src/api/SearchResponse";

/**
 * Allows attaching arbitrary key-value pairs to a snowplow event.
 * Dimensions are strings and metrics are numbers.
 *
 * Passing an array as a value will result in multiple key-value pairs, whilst passing null or undefined will result in no key-value pair.
 *
 * ```
 * { "transport": ["plane", "bus"] } => [{ Key: "transport", Value: "plane" }, { Key: "transport", Value: "bus" }]
 * { "transport": undefined } => []
 * ```
 */
export type ExtraInfoProps = {
  dimensions?: Record<string, string | string[] | undefined | null>;
  metrics?: Record<string, number | number[] | undefined | null>;
};
export type SnowplowEntityName = keyof typeof snowplowEntities;

// An "Entity" here is what's sent to Snowplow and should be opaque to the app
export interface SnowplowEntity {
  schema: (typeof snowplowEntities)[SnowplowEntityName]["uri"];
  data: ReturnType<
    (typeof snowplowEntities)[SnowplowEntityName]["create"]
  >["data"];
}

// Each key-value here is an "Entity" as far as the app is concerned
export const snowplowEntities = {
  interaction: {
    uri: "iglu:com.rome2rio/event_trigger/jsonschema/1-0-0",
    create: createInteractionContext,
  },
  serverRequest: {
    uri: "iglu:com.rome2rio/server_request/jsonschema/1-0-0",
    create: createServerRequestContext,
  },
  windowIdentifiers: {
    uri: "iglu:com.rome2rio/window_identifiers/jsonschema/1-0-0",
    create: createWindowIdentifiersContext,
  },
  requestIdentifiers: {
    uri: "iglu:com.rome2rio/request_identifiers/jsonschema/1-0-0",
    create: createRequestIdentifiersContext,
  },
  search: {
    uri: "iglu:com.rome2rio/search/jsonschema/1-0-0",
    create: createSearchContext,
  },
  seoStore: {
    uri: "iglu:com.rome2rio/seo_store/jsonschema/1-0-0",
    create: createSeoStoreContext,
  },
  experiments: {
    uri: "iglu:com.rome2rio/experiments/jsonschema/1-0-0",
    create: createExperimentsContext,
  },
  contentSources: {
    uri: "iglu:com.rome2rio/content_sources/jsonschema/1-0-0",
    create: createContentSourcesContext,
  },
  screenLayout: {
    uri: "iglu:com.rome2rio/screen_layout/jsonschema/1-0-0",
    create: createScreenLayoutContext,
  },
  screenContent: {
    uri: "iglu:com.rome2rio/screen_content/jsonschema/1-0-0",
    create: createScreenContentContext,
  },
  extraInfo: {
    uri: "iglu:com.rome2rio/extra_info/jsonschema/1-0-0",
    create: createExtraInfoContext,
  },
  gtmPreview: {
    uri: "iglu:com.google.tag-manager.server-side/preview_mode/jsonschema/1-0-0",
    create: createGtmPreviewContext,
  },
} as const;

function createInteractionContext(isInteraction: boolean) {
  return {
    schema: snowplowEntities.interaction.uri,
    data: {
      interaction: isInteraction,
    },
  };
}

function createServerRequestContext(customDimensions: CustomDimensions) {
  return {
    schema: snowplowEntities.serverRequest.uri,
    data: {
      R2rBotName: customDimensions?.dimension1,
      R2rLanguage: customDimensions?.dimension37,
      R2rServer: customDimensions?.dimension3,
      R2rPageKind: customDimensions?.dimension36,
    },
  };
}

function createRequestIdentifiersContext(customDimensions: CustomDimensions) {
  return {
    schema: snowplowEntities.requestIdentifiers.uri,
    data: {
      Uid: customDimensions?.dimension41,
      Aqid: customDimensions?.dimension42,
      RequestId: customDimensions?.dimension40,
    },
  };
}

function createWindowIdentifiersContext(domId: String, localSessionId: String) {
  return {
    schema: snowplowEntities.windowIdentifiers.uri,
    data: {
      BrowserDomId: domId,
      BrowserSessionId: localSessionId,
    },
  };
}

function createSearchContext(customDimensions: CustomDimensions) {
  return {
    schema: snowplowEntities.search.uri,
    data: {
      Terms: [
        {
          Canonical: customDimensions?.dimension21,
          Kind: customDimensions?.dimension23,
          City: customDimensions?.dimension27,
          CountryCode: customDimensions?.dimension25,
          GeoCoderSource: customDimensions?.dimension43,
        },
        {
          Canonical: customDimensions?.dimension22,
          Kind: customDimensions?.dimension24,
          City: customDimensions?.dimension28,
          CountryCode: customDimensions?.dimension26,
          GeoCoderSource: customDimensions?.dimension44,
        },
      ],
    },
  };
}

function createSeoStoreContext(customDimensions: CustomDimensions) {
  return {
    schema: snowplowEntities.seoStore.uri,
    data: {
      Source: customDimensions?.dimension9,
      Weight: customDimensions?.dimension10
        ? Number(customDimensions?.dimension10)
        : undefined,
      FrequencyBand: customDimensions?.dimension33,
    },
  };
}

function createExperimentsContext(customDimensions: CustomDimensions) {
  return {
    schema: snowplowEntities.experiments.uri,
    data: {
      BackendEnrolmentsEncoded: customDimensions?.dimension32,
    },
  };
}

function createContentSourcesContext(customDimensions: CustomDimensions) {
  return {
    schema: snowplowEntities.contentSources.uri,
    data: {
      Agencies: customDimensions?.dimension35,
      DataSources: customDimensions?.dimension38,
    },
  };
}

function createScreenLayoutContext(
  screen?: Screen,
  components?: ScreenComponent[]
) {
  return {
    schema: snowplowEntities.screenLayout.uri,
    data: {
      Screen: screen,
      Components: components,
    },
  };
}

function createScreenContentContext(
  screen?: Screen,
  modes?: Mode[],
  locations?: ScreenLocations
) {
  return {
    schema: snowplowEntities.screenContent.uri,
    data: {
      Screen: screen,
      TravelKinds: modes,
      Locations: locations
        ? [
            {
              name:
                locations.origin.canonicalName ?? locations.origin.shortName,
              kind: locations.origin.kind,
            },
            {
              name:
                locations.destination.canonicalName ??
                locations?.destination.shortName,
              kind: locations.destination.kind,
            },
          ]
        : undefined,
    },
  };
}

function createExtraInfoContext({ dimensions, metrics }: ExtraInfoProps) {
  return {
    schema: snowplowEntities.extraInfo.uri,
    data: {
      Dimensions: dimensions && toSnowplowRecords(dimensions),
      Metrics: metrics && toSnowplowRecords(metrics),
    },
  };
}

function toSnowplowRecords<T>(
  obj: Record<string, T | T[] | undefined | null>
): { Key: string; Value: T }[] {
  return Object.entries(obj).flatMap(([Key, Value]) => {
    if (Value === undefined || Value === null) {
      return [];
    }
    if (Array.isArray(Value)) {
      return Value.map((v) => ({ Key, Value: v }));
    }
    return [{ Key, Value }];
  });
}

function createGtmPreviewContext(previewHeader: String) {
  return {
    schema: snowplowEntities.gtmPreview.uri,
    data: {
      "x-gtm-server-preview": previewHeader,
    },
  };
}
