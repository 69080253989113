import type { SupportedCurrencyCode } from "../utils/currency";
import type { HotelProviderKind } from "../utils/types/accommodationProviders";

export type HotelListResponse = {
  hotelList: Hotel[];
  lat: number;
  lng: number;
  currencyCode: SupportedCurrencyCode;
  pricePoints: number[];
  listViewUrl?: string;
  cityName?: string;
  maxHotels: number;
  cityId?: string;
  zoom: number;
  providerName: string;
  provider: HotelProviderKind;
  message?: string; // Error message on api error.
  maxReviewScore: number;
  minPriceBound: number;
  maxPriceBound: number;
  minPricePerNight: number;
};

export type Hotel = {
  lat: number;
  lng: number;
  hotelId: string;
  hotelName: string;
  rating: number;
  reviewScore: number;
  hotelTypeId: number;
  minRate: number;
  cityIds: string[];
  ranking: number;
  deal?: HotelDealInfo;
  freeCancellation?: boolean;
};

export type HotelDealTag = "Mobile";

export type HotelDealInfo = {
  discountPercentage: number;
  originalPrice: number;
  tags: HotelDealTag[];
};

/**
 * Should be kept in sync with the backend
 * Whilst not returned by the HotelList response, is used in the request.
 * @see https://github.com/rome2rio/rome2rio-core/blob/develop/rome2rio.integrations/Accommodation/FacilityType.cs
 */
export enum FacilityType {
  Parking = 1,
  FreeParking = 2,

  WiFi = 3,
  FreeWiFi = 4,

  NonSmokingRooms = 5,
  NonSmokingThroughout = 6,
  SmokingArea = 7,

  AirportShuttle = 8,

  FitnessCentre = 9,
  SpaWellnessCentre = 10,
  OutdoorPool = 11,
  IndoorPool = 12,

  Restaurant = 13,
  Bar = 14,
  FreeBreakfast = 15,
  AllHoursReception = 16,

  Laundry = 17,

  Garden = 18,
  Terrace = 19,
  FamilyRooms = 20,
  DisabledFacilities = 21,

  PetsAllowed = 22,
}

export type HotelPropertyKind = {
  hotelTypeId: HotelType;
  hoteTypeName: string;
};

// Although the Hotel Response returns hotelTypes: HotelPropertyKind[], all providers use the same type -> name mapping
export enum HotelType {
  Apartment = 201,
  Hostel = 203,
  Hotel = 204,
  Motel = 205,
  Resort = 206,
  BedAndBreakfast = 208,
  HolidayHome = 220,
}

export const hotelTypes = [
  HotelType.Apartment,
  HotelType.Hostel,
  HotelType.Hotel,
  HotelType.Motel,
  HotelType.Resort,
  HotelType.BedAndBreakfast,
  HotelType.HolidayHome,
];
