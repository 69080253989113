import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  sendAnalyticsInteractionEvent,
  sendAnalyticsNonInteractionEvent,
} from "src/analytics/sendAnalyticsEvent";
import { useFeature } from "src/feature/useFeature";
import { CloseCircle } from "src/svg/CloseCircle";
import { useCookieState } from "src/utils/hooks/useCookieState";
import { useLayout } from "src/utils/hooks/useLayout";
import styled from "styled-components";
import { useTripOrTransportIndexes } from "src/utils/hooks/useTripOrTransportIndexes";
import { useIsRouteSkipped } from "src/utils/hooks/useIsRouteSkipped";
import { breakpoints, color } from "../../theme";
import { DisplayAd } from "../DisplayAd/DisplayAd";
import { TieredDisplayAd } from "../DisplayAd/TieredDisplayAd";
import { ScrollableContainer } from "../ScrollableContainer/ScrollableContainer";
import { TextButton } from "../TextButton/TextButton";
import messages from "./RightRail.messages";

const HIDE_RIGHT_RAIL_COOKIE = "hideRightRail";
const HIDE_RIGHT_RAIL_COOKIE_EXPIRY = 0.004167; // 6 minutes
const MAX_NUMBER_OF_REFRESHES = 2;

export function RightRail() {
  const intl = useIntl();
  const layout = useLayout();

  const [hide, setHide] = useCookieState(
    HIDE_RIGHT_RAIL_COOKIE,
    false,
    HIDE_RIGHT_RAIL_COOKIE_EXPIRY
  );

  // Used to hide the right rail until the ads have filled
  const [hasAd, setHasAd] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);
  const [timerElapsed, setTimerElapsed] = useState(false);
  const [adViewed, setAdViewed] = useState(false);
  const [hasNavigatedSerpToRoute, setHasNavigatedSerpToRoute] = useState(false);

  const { routeIndex, routeSegmentIndex } = useTripOrTransportIndexes();
  const isRouteSkipped = useIsRouteSkipped(routeIndex);
  const [previousRouteIndex, setPreviousRouteIndex] = useState(routeIndex);

  const newRefreshCount = refreshCount + 1;
  const isRightRailBreakpointExp = useFeature("RightRailBP");
  const noWaterfallAds = useFeature("NoWaterfallAds");

  useEffect(() => {
    // If routeIndex is defined, the segment index is undefined and the route
    // page has not been skipped, we're on a route page.
    // If route index goes from undefined to defined we're going from a SERP
    // page to deeper in the funnel.
    if (
      routeIndex !== undefined &&
      routeSegmentIndex === undefined &&
      !isRouteSkipped &&
      previousRouteIndex === undefined
    ) {
      setHasNavigatedSerpToRoute(true);
    }

    if (routeIndex !== previousRouteIndex) {
      setPreviousRouteIndex(routeIndex);
    }
  }, [isRouteSkipped, previousRouteIndex, routeIndex, routeSegmentIndex]);

  useEffect(() => {
    if (!timerElapsed && adViewed) {
      setTimeout(() => {
        setTimerElapsed(true);
      }, 60 * 1000);
    }
  }, [adViewed, timerElapsed]);

  useEffect(() => {
    if (
      timerElapsed &&
      hasNavigatedSerpToRoute &&
      refreshCount < MAX_NUMBER_OF_REFRESHES &&
      layout === "desktop"
    ) {
      setTimerElapsed(false);
      setRefreshCount(newRefreshCount);
      sendAnalyticsNonInteractionEvent({
        category: "Ads",
        action: "Refresh",
        label: "rightRail2",
        value: newRefreshCount,
      });
      setHasNavigatedSerpToRoute(false);
    }
  }, [
    hasNavigatedSerpToRoute,
    layout,
    newRefreshCount,
    refreshCount,
    setHasNavigatedSerpToRoute,
    timerElapsed,
  ]);

  if (hide) {
    return null;
  }

  return (
    <ScrollableContainer>
      <Container isOpen={hasAd}>
        {showHideAdsButton(window.innerWidth) && (
          <TextButton
            textColor="primaryOnLight"
            onClick={() => {
              sendAnalyticsInteractionEvent({
                category: "Ads",
                action: "Click:HideRightRail",
              });
              setHide(true);
            }}
            rightIcon={<CloseCircle tint="secondaryOnLight" />}
            rightIconSize="lg"
          >
            {intl.formatMessage(messages.closeAdsLabel)}
          </TextButton>
        )}
        {noWaterfallAds ? (
          <DisplayAd
            slotConfigId={
              isRightRailBreakpointExp ? "rightRailVariant" : "rightRail"
            }
            onFilled={() => setHasAd(true)}
          />
        ) : (
          <TieredDisplayAd
            slotConfigId={
              isRightRailBreakpointExp ? "rightRailVariant" : "rightRail"
            }
            onFilled={() => setHasAd(true)}
          />
        )}
        <DisplayAd
          slotConfigId={
            isRightRailBreakpointExp ? "rightRail2Variant" : "rightRail2"
          }
          onFilled={() => setHasAd(true)}
          key={`display-count-${refreshCount}`}
          setAdViewed={setAdViewed}
          refreshCount={refreshCount}
        />
      </Container>
    </ScrollableContainer>
  );
}

function showHideAdsButton(screenWidth: number) {
  return screenWidth <= breakpoints.xl;
}

const Container = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  flex-direction: column;
  align-items: flex-end;

  background-color: ${color.grey1};
`;
