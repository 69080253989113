import {
  useSearchInput,
  useSearchKeyboard,
} from "src/utils/hooks/useSearchInput";
import styled, { css } from "styled-components";
import { A11yOutline } from "src/utils/accessibility";
import { spacing } from "src/design-system/tokens/spacing";
import { border_radius } from "src/design-system/tokens/border";
import { Label } from "src/design-system/components/Typography/Typography";
import { type InputHTMLAttributes } from "react";
import type React from "react";
import { AutocompleteDropdown } from "../AutocompleteDropdown/AutocompleteDropdown";
import { ClickOutsideListener } from "../ClickAwayListener/ClickOutsideListener";

type SearchInputProps = {
  id: string;
  search: ReturnType<typeof useSearchInput>;
  keyboard: ReturnType<typeof useSearchKeyboard>;
  LeftElement?: JSX.Element;
  RightElement?: JSX.Element;
  className?: string;
} & InputHTMLAttributes<HTMLInputElement>;
export function SearchInput({
  id,
  search,
  keyboard,
  LeftElement,
  RightElement,
  className,
  ...props
}: Readonly<SearchInputProps>) {
  const showResults = !!search.displayResults.length;
  const ariaControlsID = `search-results-${id}`;

  function highlightText() {
    if (search.inputRef.current) {
      const textLength = search.inputRef.current.value.length;
      search.inputRef.current.setSelectionRange(0, textLength);
    }
  }

  function handleFocus() {
    keyboard.setFocusedElement({ id, index: 0 }, search.displayResults.length);
    highlightText();
  }

  function handleKeydown(event: React.KeyboardEvent<HTMLInputElement>) {
    keyboard.onKeyDown(
      event,
      search.handleEnter,
      search.isResultsClosed,
      search.displayResults.length,
      search.reset
    );
  }

  return (
    <ClickOutsideListener onClickOutside={search.reset}>
      <InputBlock
        className={className}
        onClick={() => search.inputRef.current?.focus()}
      >
        {LeftElement}
        <HintAndInputDiv>
          <Hint size="lg">
            <span style={{ color: "transparent" }}>{search.displayValue}</span>
            {search.hint}
          </Hint>
          <Input
            ref={search.inputRef}
            type="search"
            value={search.displayValue}
            onChange={(e) => search.handleQueryChange(e.target.value)}
            onKeyDown={handleKeydown}
            onFocus={handleFocus}
            aria-controls={ariaControlsID}
            role="combobox"
            aria-autocomplete="list"
            aria-expanded={showResults}
            {...props}
          />
        </HintAndInputDiv>
        {RightElement}
        {showResults && (
          <StyledAutocompleteDropdown
            results={search.autocompleteState.results}
            onSelectPlace={search.handleSelect}
            ariaControlsID={ariaControlsID}
          />
        )}
      </InputBlock>
    </ClickOutsideListener>
  );
}

export const BlockStyles = css`
  ${A11yOutline}
  display: flex;
  align-items: center;
  box-sizing: content-box;
  height: ${spacing.xxxl};
  min-width: ${spacing.xxxl};
  padding: ${spacing.md} ${spacing.lg};
  background-color: #ebebeb;
  border-radius: ${border_radius.rounded_xs};
  &:hover {
    background-color: #efefef;
  }
  &:first-child {
    border-top-left-radius: ${border_radius.rounded_md};
    border-bottom-left-radius: ${border_radius.rounded_md};
  }
  &:last-child {
    border-top-right-radius: ${border_radius.rounded_md};
    border-bottom-right-radius: ${border_radius.rounded_md};
  }
`;

const InputBlock = styled.div`
  ${BlockStyles}
  position: relative;
  gap: ${spacing.md};
  white-space: nowrap;
  flex: 1;
`;

const HintAndInputDiv = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
`;

const Hint = styled(Label)`
  opacity: 0.5;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
`;

const Input = styled.input`
  background: transparent;
  font-size: 1rem;
  outline: unset;
  border: unset;
  flex: 1;
  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
    display: none;
  }
`;

const StyledAutocompleteDropdown = styled(AutocompleteDropdown)`
  top: 100%;
  width: 100%;
  box-shadow: 0px 14px 50px rgba(0, 0, 0, 0.12);
  border-radius: ${border_radius.rounded_md};
  background: ${(props) => props.theme.searchBar.autocompleteList.background};
  color: ${(props) => props.theme.searchBar.autocompleteList.text};
`;
