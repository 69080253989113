import { TIMER_COUNT } from "src/domain/SegmentScreen/CarrierSection/AdPage/AdPageWithTimer";

type Props = {
  timeLeft: number;
  onClick: () => void;
};

export function CircularTimer({ timeLeft, onClick }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      onClick={onClick}
    >
      <circle
        cx="32"
        cy="32"
        r="30"
        stroke="#DE007B"
        strokeWidth="4.8"
        fill="none"
      ></circle>

      {timeLeft > 0 && (
        <circle
          cx="32"
          cy="32"
          r="30"
          stroke="#F1F1F3"
          strokeWidth="2"
          fill="none"
          strokeDasharray="188.4"
          strokeDashoffset={
            188.4 * ((TIMER_COUNT - timeLeft + 1) / TIMER_COUNT)
          }
          strokeLinecap="round"
          transform="rotate(-90 32 32)"
        ></circle>
      )}

      <text
        x="32"
        y="32"
        dominantBaseline="middle"
        textAnchor="middle"
        fontFamily="Roboto"
        fontSize="20"
        fontWeight="700"
        fill="#000"
      >
        {timeLeft}
      </text>
    </svg>
  );
}
