import type { SearchResponse } from "../../api/SearchResponse";
import type { ScreenComponent } from "./ScreenComponent";

export function searchResultScreenComponentList(
  response: SearchResponse | undefined
): ScreenComponent[] {
  const result: ScreenComponent[] = [];

  const originCountryCode = getOriginCountryCode(response);
  const destinationCountryCode = getDestinationCountryCode(response);

  if (originCountryCode === "UA" || destinationCountryCode === "UA") {
    // Show a search notification for Ukraine.
    result.push("SearchNotification");
  }

  result.push("RouteList");

  const hasSearchResults = !!response?.routes?.length;
  if (hasSearchResults) {
    result.push("HotelCta");
    result.push("RentalCarCta");
  }

  result.push("DisplayAd");
  result.push("CsaAd");

  return result;
}

function getOriginCountryCode(searchResponse: SearchResponse | undefined) {
  return searchResponse
    ? searchResponse.places[searchResponse.result.originPlace].countryCode
    : undefined;
}

function getDestinationCountryCode(searchResponse: SearchResponse | undefined) {
  return searchResponse
    ? searchResponse?.places[searchResponse.result.destinationPlace].countryCode
    : undefined;
}
