import { useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { spacing } from "src/design-system/tokens/spacing";
import { useNavigate } from "react-router";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import useUser from "src/utils/hooks/useUser";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { navigateToNewStateHash } from "src/utils/location/navigateToNewStateHash";
import { Bookmark } from "src/svg/tripplanner/Bookmark";
import { Icon as OldIcon } from "src/components/Icon/Icon";
import { Button } from "src/design-system/components/Button/Button";
import { ChevronRight } from "src/svg/designSystem/ChevronRight";
import { Icon } from "src/design-system/components/Icon/Icon";
import messages from "../TripPlannerScreen.messages";
import { UserAccountTakeover } from "../UserAccountTakeover/UserAccountTakeover";

export function TripSaveButton() {
  const intl = useIntl();
  const [showSigninTakeover, setShowSigninTakeover] = useState(false);

  const navigate = useNavigate();
  const location = useTypedLocation();
  const { user } = useUser();

  const isLoggedOut = !user?.id;

  function handleBookmarkClick() {
    setShowSigninTakeover(true);
    sendAnalyticsInteractionEvent({
      category: "TripPlanner",
      action: "Click:SaveTrip:MultipleTrips",
    });
  }

  function handleSavedTrips() {
    navigateToNewStateHash(
      navigate,
      {
        highlightedTab: "trips",
      },
      `#trips/saved/`,
      {
        ...location,
      }
    );
  }

  return (
    <>
      <>
        {!isLoggedOut ? (
          <SaveWrapper>
            <Button onPress={handleSavedTrips} size="small" variant="subtle">
              {intl.formatMessage(messages.savedTrips)}
              <Icon size="small">
                {" "}
                <ChevronRight tint="cod" />
              </Icon>
            </Button>
          </SaveWrapper>
        ) : (
          <Button
            variant="primary"
            data-testid="save-trip-signin-button"
            onPress={handleBookmarkClick}
          >
            <OldIcon size="md">
              <Bookmark tint="white" />
            </OldIcon>
            {intl.formatMessage(messages.save)}
            <UserAccountTakeover
              isDropdownOpen={showSigninTakeover}
              handleClose={() => setShowSigninTakeover(false)}
              handleClickOffDropdown={() => setShowSigninTakeover(false)}
            />
          </Button>
        )}
      </>
    </>
  );
}

const SaveWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${spacing.lg};
  svg {
    width: 16px;
    height: 16px;
  }
`;
