import { useQuery } from "@tanstack/react-query";
import {
  SEARCH_SCHEDULE_REQUEST,
  sendTravelAudienceEvent,
} from "src/data-partners/travelAudience/sendTravelAudienceEvent";
import type { SchedulesRequest } from "src/api/SchedulesResponse";
import type { SearchResponse } from "src/api/SearchResponse";
import { airportsFromFlightRoute } from "src/utils/airportsFromFlightRoute";
import type { Mode } from "src/utils/types/mode";

export const useTravelAudience = (
  searchResponse?: SearchResponse,
  isTravelAudienceEnabled?: boolean,
  schedulesRequest?: SchedulesRequest,
  transitMode?: Mode
) => {
  const disableQuery =
    !searchResponse?.request.languageCode ||
    !isTravelAudienceEnabled ||
    (transitMode && !schedulesRequest);

  useQuery({
    queryKey: ["useTravelAudience", searchResponse?.request, schedulesRequest],
    queryFn: () => {
      if (searchResponse && searchResponse.request.languageCode) {
        const airports = airportsFromFlightRoute(searchResponse, 0);

        // Render Travel Audience pixel and push relevant params to their global variable: _ttq.
        sendTravelAudienceEvent({
          level: SEARCH_SCHEDULE_REQUEST,
          language: searchResponse.request.languageCode,
          origin: searchResponse.request.oName,
          destination: searchResponse.request.dName,
          departureDate: schedulesRequest?.outboundDateTime
            ? schedulesRequest?.outboundDateTime.split("T")[0]
            : undefined,
          returnDate: schedulesRequest?.inboundDateTime
            ? schedulesRequest?.inboundDateTime.split("T")[0]
            : undefined,
          departureAirport: airports[0]?.code,
          destinationAirport: airports[airports.length - 1]?.code,
          transitMode: transitMode,
        });
      }

      // Queries must return a value.
      return "";
    },
    refetchOnWindowFocus: false,
    enabled: !disableQuery,
  });
};
