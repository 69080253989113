import { useQuery } from "@tanstack/react-query";
import type { SearchResponse } from "src/api/SearchResponse";
import {
  destinationPlaceFromSearch,
  originPlaceFromSearch,
} from "src/utils/adapters/place";

export function useGooglePixel(
  searchResponse?: SearchResponse,
  isGoogleEnabled?: boolean
) {
  const searchRequest = searchResponse?.request;

  useQuery({
    queryKey: ["googlePixel", searchRequest, isGoogleEnabled], // keys to invalidate the query
    enabled: !!searchRequest,
    queryFn: () => {
      gtag("event", "page_view", {
        send_to: "AW-968290294",
        items: [
          {
            origin: searchRequest?.oName,
            originCountryCode: searchResponse
              ? originPlaceFromSearch(searchResponse).countryCode
              : undefined,
            destination: searchRequest?.dName,
            destinationCountryCode: searchResponse
              ? destinationPlaceFromSearch(searchResponse).countryCode
              : undefined,
            route:
              searchRequest?.oName && searchRequest?.dName
                ? `${searchRequest?.oName}_${searchRequest?.dName}`
                : undefined,
            google_business_vertical: "travel",
          },
        ],
      });
      // Queries must return a value.
      return "";
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity, // ensures we only send the pixel once for each unique query key
  });
}
