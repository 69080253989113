export type TripPlannerScreenKey =
  | "transport"
  | "hotels"
  | "destination"
  | "saved"
  | "create";

export function getScreenKey(hash: string): TripPlannerScreenKey | undefined {
  if (hash.includes("/transport/") || hash.includes("#r/")) {
    return "transport";
  } else if (hash.includes("/hotels/")) {
    return "hotels";
  } else if (hash.includes("/saved")) {
    return "saved";
  } else if (hash.includes("/create")) {
    return "create";
  }
}
