import type { PartialSearchResponse } from "src/domain/TripPlanner/util/getPartialSearchResponse";
import type { SearchResponse } from "../api/SearchResponse";

/**
 * Returns true if the route is eligible to be skipped when the SkipRoutes
 * feature is enabled, or false otherwise.
 */
export function isRouteSkippable(
  searchResponse: SearchResponse | PartialSearchResponse,
  routeIndex: number
) {
  const route = searchResponse.routes[routeIndex];

  if (!route) {
    // routeIndex was likely out-of-bounds.
    return false;
  }

  if (route.segments.length <= 1) {
    // Routes with only one leg are not skippable because they go straight to
    // the segment screen already.
    return false;
  }

  if (!route.scheduleInfo) {
    // Routes that are not fully schedulable are not skippable.
    return false;
  }

  return true;
}
