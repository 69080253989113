import type { ComponentProps } from "react";
import type { AccomPromoLoggingCategory } from "src/analytics/analyticsEventTypes";
import { SegmentAccommodationPromoDriveVariant } from "src/domain/SegmentScreen/SegmentAccommodationPromoDriveVariant/SegmentAccommodationPromoDriveVariant";
import type { HotelProviderKind } from "src/utils/types/accommodationProviders";
import styled from "styled-components";
import {
  color,
  fontSize,
  fontWeight,
  screenMinWidth,
  spacing,
} from "../../../../theme";
import { Timeline } from "../../Timeline";
import type { DestinationCell } from "../cell/DestinationCell";

type ConditionalDriveSegmentProps =
  | {
      isDriveSegment: boolean;
      loggingFrom: AccomPromoLoggingCategory;
      hotelProvider: HotelProviderKind;
    }
  | {
      isDriveSegment?: false;
      loggingFrom?: never;
      hotelProvider?: never;
    };

type Props = {
  cell: DestinationCell;
  className?: string;
} & ConditionalDriveSegmentProps;

export function TroniconTimelineDestination(props: Props) {
  return (
    <Wrapper className={props.className}>
      <FullWidthTimeline
        type={props.cell.isLastSegment ? "tripEnd" : "destination"}
        transitMode={props.cell.transitMode}
      />
      <Content>
        <LeftAligned>
          <DestinationName
            shouldbold={props.cell.isLastSegment || props.cell.isStandalone}
          >
            {props.cell.placeName}
          </DestinationName>

          {props.cell.tripDestinationName && (
            <AlternativeDestinationName>
              {props.cell.tripDestinationName}
            </AlternativeDestinationName>
          )}
        </LeftAligned>
        {props.isDriveSegment && (
          <SegmentAccommodationPromoDriveVariant
            location={props.cell.placeName}
            kind={props.cell.kind}
            loggingFrom={props.loggingFrom}
            hotelProvider={props.hotelProvider}
            id={props.cell.id}
          />
        )}
      </Content>
    </Wrapper>
  );
}

const FullWidthTimeline = styled(Timeline)<ComponentProps<typeof Timeline>>`
  flex-shrink: 0;
  align-self: flex-start;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-bottom: 6px;
`;

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: ${spacing.md};
  padding: 0 ${spacing.xl};
  ${screenMinWidth.md} {
    flex-direction: row;
  }
`;

const LeftAligned = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  margin-right: auto;
`;

const DestinationName = styled.span<{ shouldbold: boolean }>`
  font-size: ${fontSize.h5};
  font-weight: ${(props) =>
    props.shouldbold ? fontWeight.medium : fontWeight.normal};
`;

const AlternativeDestinationName = styled.span`
  font-size: ${fontSize.h6};
  color: ${color.n90};
`;
