import { useState } from "react";
import styled from "styled-components";
import { TripPlannerAutocomplete } from "src/domain/TripPlanner/TripPlannerAutocomplete/TripPlannerAutocomplete";
import { DottedLine } from "src/domain/TripPlanner/DottedLine/DottedLine";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import type { AutocompletePlace } from "../../../api/AutocompleteResponse";
import { spacing } from "../../../theme";
import { AddDestinationTimelineButton } from "../AddDestinationTimelineButton/AddDestinationTimelineButton";

type AddDestinationButtonProps = {
  onSelectDestination: (place: AutocompletePlace) => void;
};

export function AddDestinationButton(props: AddDestinationButtonProps) {
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);

  function toggleDialog() {
    const action = `${!isDialogOpen ? "Open" : "Close"}:AddDestination`;
    sendAnalyticsInteractionEvent({ category: "TripPlanner", action: action });
    setDialogOpen(!isDialogOpen);
  }

  function onSelectDestination(place: AutocompletePlace) {
    toggleDialog();
    if (place) {
      props.onSelectDestination(place);
    }
  }

  return (
    <AddDestinationContainer>
      <AddDestinationWrapper>
        <DottedLine />
        <AddDestinationTimelineButton
          data-testid="add-destination-button"
          isDisabled={false}
          onClick={toggleDialog}
          isTimeline
        />
      </AddDestinationWrapper>

      {isDialogOpen && (
        <TripPlannerAutocomplete
          onSelectOption={onSelectDestination}
          handleClose={toggleDialog}
        />
      )}
    </AddDestinationContainer>
  );
}

const AddDestinationContainer = styled.div`
  padding-top: ${spacing.lg};
`;

const AddDestinationWrapper = styled.div`
  margin-top: -${spacing.sm};
  margin-left: 3px;
`;
