import type { NavigateFunction } from "react-router";
import type { LocationState, TypedLocation } from "../hooks/useTypedLocation";
import { hashAndStateChange } from "./hashAndStateChange";

export function navigateToNewStateHash(
  navigate: NavigateFunction,
  newState: Partial<LocationState>,
  newHash: string | undefined,
  current: TypedLocation,
  replace?: boolean,
  newTab?: boolean
) {
  let newLocation = hashAndStateChange(newHash, newState, current);

  if (newTab) {
    window.open(
      `${newLocation.pathname}${newLocation.search}${newLocation.hash}`,
      "_blank"
    );
  } else
    navigate(newLocation, { state: newLocation.state, replace: !!replace });
}
