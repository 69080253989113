import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { FloatingButton } from "src/domain/HotelsScreen/FloatingButton/FloatingButton";
import { useHotelsContext } from "src/domain/HotelsScreen/HotelsContext";
import { useTripList } from "src/domain/TripPlanner/hooks/useTripList";
import { useIsTripCreated } from "src/domain/TripPlanner/hooks/useTripURL";
import { ListSvg } from "src/svg/ListSvg";
import { MapReverseFill } from "src/svg/MapReverseFill";
import { removeDisabledAdsUrlParams } from "src/utils/hooks/useDisableAdsUrlParams";
import { useNavigateToHotelsPage } from "src/utils/hooks/useNavigateToHotelsPage";
import useUser from "src/utils/hooks/useUser";
import styled from "styled-components";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import type { MobileTabs } from "../../App";
import { Hotel } from "../../svg/Hotel";
import { Search } from "../../svg/Search";
import { Suitcase } from "../../svg/Suitcase";
import { UserCircle } from "../../svg/UserCircle";
import type { SvgProps } from "../../svg/common";
import { color, fontSize, fontWeight, lineHeight, spacing } from "../../theme";
import { useRouteCanonical, useRouteIndex } from "../../utils/hooks/useRoute";
import { useRouteSegmentIndex } from "../../utils/hooks/useSegment";
import { useTheme } from "../../utils/hooks/useTheme";
import { useTypedLocation } from "../../utils/hooks/useTypedLocation";
import { navigateToNewState } from "../../utils/location/navigateToNewState";
import { navigateToNewStateHash } from "../../utils/location/navigateToNewStateHash";
import { Icon } from "../Icon/Icon";
import messages from "./BottomNavbar.messages";

type Props = {
  active: MobileTabs;
  setScrollTop: (top: number) => void;
  className?: string;
};

export const bottomNavBarHeight = "54px";

export function BottomNavbar({
  active: highlightedTab,
  setScrollTop,
  className,
}: Props) {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useTypedLocation();
  const routeIndex = useRouteIndex();
  const routeCanonical = useRouteCanonical();
  const segmentIndex = useRouteSegmentIndex();
  const { navigateToHotels } = useNavigateToHotelsPage();
  const { showMap, setShowMap, targetPinHotelId } = useHotelsContext();
  const { user } = useUser();
  const { data } = useTripList();
  const [tripsCount, setTripsCount] = useState(0);
  const isTripCreated = useIsTripCreated();
  const isUserLoggedIn = !!user?.id;

  const handleHotelsClick = () => {
    setScrollTop(0);
    location.search = removeDisabledAdsUrlParams(location);
    sendAnalyticsInteractionEvent({
      category: "BottomNav",
      action: "Click:Hotels",
    });
    navigateToHotels();
  };

  const handleSearchClick = () => {
    // On switch of tab, reset scroll to top
    if (showMap === true) setShowMap(false);
    setScrollTop(0);
    location.search = removeDisabledAdsUrlParams(location);
    let navDestination;

    if (highlightedTab !== "search") {
      const routePart = routeIndex !== undefined ? `#r/${routeCanonical}` : "";
      const segmentPart =
        routeIndex !== undefined && segmentIndex !== undefined
          ? `/s/${segmentIndex}`
          : "";
      navDestination = `${routePart}${segmentPart}`;

      sendAnalyticsInteractionEvent({
        category: "NavigateHome",
        action: "Click:Search",
      });
    }

    navigateToNewStateHash(
      navigate,
      { highlightedTab: "search" },
      navDestination,
      location
    );

    sendAnalyticsInteractionEvent({
      category: "BottomNav",
      action: "Click:Search",
    });
  };

  const handlePreferencesClick = () => {
    // On switch of tab, reset scroll to top
    if (showMap === true) setShowMap(false);
    setScrollTop(0);
    location.search = removeDisabledAdsUrlParams(location);

    navigateToNewState(
      navigate,
      { highlightedTab: "preferences", preferencesScreen: "main" },
      location
    );

    sendAnalyticsInteractionEvent({
      category: "BottomNav",
      action: "Click:Profile",
    });
  };

  const handleTripClick = () => {
    // On switch of tab, reset scroll to top
    if (showMap === true) setShowMap(false);
    setScrollTop(0);
    location.search = removeDisabledAdsUrlParams(location);

    let tripURL = "#trips";

    if (!isTripCreated) {
      tripURL = `#trips/${isUserLoggedIn ? "saved" : "create"}`;
    }

    navigateToNewStateHash(
      navigate,
      { highlightedTab: "trips" },
      tripURL,
      location
    );

    sendAnalyticsInteractionEvent({
      category: "BottomNav",
      action: "Click:Trips",
    });
  };

  const handleFloatingMapListClick = () => {
    sendAnalyticsInteractionEvent({
      category: "Hotels",
      action: "Click:ViewToggle",
      label: `Show ${showMap ? "List" : "Map"}`,
    });
    setShowMap((prev) => !prev);
  };

  useEffect(() => {
    if (isUserLoggedIn && data?.length) {
      setTripsCount(data.length);
    }
  }, [data, intl, isUserLoggedIn]);

  // The switch button overlaps with the mobile map card, so we hide it until the user dismisses the card
  const isLookingAtMapCard =
    showMap && targetPinHotelId.focusedId !== undefined;
  const showHotelSwitchButton =
    highlightedTab === "hotels" && !isLookingAtMapCard;

  return (
    <BottomNavbarWrapper className={className}>
      {showHotelSwitchButton ? (
        <FloatingButton
          leftIcon={
            showMap ? (
              <ListSvg tint="primaryOnDark" />
            ) : (
              <MapReverseFill tint="primaryOnDark" />
            )
          }
          onClick={handleFloatingMapListClick}
          text={intl.formatMessage(showMap ? messages.list : messages.map)}
        />
      ) : null}

      <NavOptions>
        <BottomNavbarOption
          isActive={highlightedTab === "search"}
          onClick={handleSearchClick}
          text={intl.formatMessage(messages.search)}
          Svg={Search}
          optionVariant="search"
        />
        <BottomNavbarOption
          isActive={highlightedTab === "hotels"}
          onClick={handleHotelsClick}
          text={intl.formatMessage(messages.hotels)}
          Svg={Hotel}
        />
        <BottomNavbarOption
          isActive={highlightedTab === "trips"}
          onClick={handleTripClick}
          text={intl.formatMessage(messages.trips)}
          badgeCount={tripsCount}
          Svg={Suitcase}
        />
        <BottomNavbarOption
          isActive={highlightedTab === "preferences"}
          onClick={handlePreferencesClick}
          text={intl.formatMessage(messages.profile)}
          Svg={UserCircle}
        />
      </NavOptions>
    </BottomNavbarWrapper>
  );
}

export type BottomNavbarOptionProps = {
  isActive: boolean;
  onClick: () => void;
  text: string;
  Svg: React.FC<React.PropsWithChildren<SvgProps>>;
  optionVariant?: string;
  badgeCount?: number;
};

export function BottomNavbarOption(props: BottomNavbarOptionProps) {
  const theme = useTheme();
  const { Svg, badgeCount } = props;
  const hasBadge = badgeCount && badgeCount > 0;

  return (
    <NavOption
      isActive={props.isActive}
      onClick={props.onClick}
      data-testid={props.optionVariant ? `${props.optionVariant}NavBar` : null}
    >
      <StyledIcon size="xxl">
        <Svg
          title={props.text}
          tint={props.isActive ? "pink" : theme.bottomNavbar.iconTint}
        />
        {hasBadge ? <BadgeContainer>{props.badgeCount}</BadgeContainer> : null}
      </StyledIcon>
      {props.text}
    </NavOption>
  );
}

const BottomNavbarWrapper = styled.div`
  z-index: 50;
  width: 100%;
`;

const BadgeContainer = styled.div`
  position: absolute;
  top: -2px;
  left: 20px;
  color: ${color.white};
  background: ${color.pink};
  border-radius: ${spacing.md};
  padding: ${spacing.xs} 6px;
  line-height: initial;
`;

const NavOptions = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  font-size: ${fontSize.label};
  background-color: ${(props) => props.theme.bottomNavbar.background};
  box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.05);
  padding-bottom: env(safe-area-inset-bottom);
`;

const NavOption = styled.button<{
  isActive: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: ${spacing.xl};
  padding-left: ${spacing.xl};
  padding-top: ${spacing.md};
  width: calc(2 * ${spacing.xl} + 34px);
  padding-bottom: calc(${spacing.md} - 3px);
  color: ${(props) =>
    props.isActive ? color.pink : props.theme.bottomNavbar.text};
  // Font style
  font-size: ${fontSize.sm};
  font-weight: ${fontWeight.medium};
  line-height: ${lineHeight.tight};
`;

const StyledIcon = styled(Icon)`
  margin: 0 auto ${spacing.sm}; /* auto is for iOS 10 */
  position: relative;
`;
