import {
  type Metric,
  onCLS,
  onFID,
  onLCP,
  onTTFB,
  onFCP,
  onINP,
} from "web-vitals";
import {
  queueCoreWebVital,
  setCwvRequestId,
  flushCoreWebVitalsQueue,
} from "../../api/logCoreWebVitals";

function backendLoggingFactory() {
  return ({ name, delta, id }: Metric) => {
    // Google Analytics metrics must be integers, so the value is rounded.
    // For CLS the value is first multiplied by 1000 for greater precision
    // (note: increase the multiplier for greater precision if needed).
    const eventValue = Math.round(name === "CLS" ? delta * 1000 : delta);

    queueCoreWebVital(name, id, eventValue);
  };
}

export function webVitals(requestId?: string) {
  setCwvRequestId(requestId);

  onCLS(backendLoggingFactory());
  onFID(backendLoggingFactory());
  onLCP(backendLoggingFactory());
  onTTFB(backendLoggingFactory());
  onFCP(backendLoggingFactory());
  onINP(backendLoggingFactory());

  // https://github.com/GoogleChrome/web-vitals#batch-multiple-reports-together

  window.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "hidden") {
      flushCoreWebVitalsQueue();
    }
  });

  window.addEventListener("pagehide", flushCoreWebVitalsQueue);
}
