import { useIntl } from "react-intl";
import styled from "styled-components";
import type { AuthType } from "src/auth/utils/session";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { Button } from "../../../../components/Button/Button";
import { spacing } from "../../../../theme";
import { AppleSignInButton } from "../../AppleSignInButton/AppleSignInButton";
import { GoogleSignInButton } from "../../GoogleSignInButton/GoogleSignInButton";
import { Heading, PageContent } from "../Components";
import { messages } from "../UserAccess.messages";
import type { FormState } from "../UserAccess";

type Props = {
  onStateChange: (newState: FormState) => void;
  title?: string;
};

export function PreSignIn({ onStateChange, title }: Props) {
  const intl = useIntl();

  const handleClick = () => {
    const authType: AuthType = "OneTimePassword";
    sendAnalyticsInteractionEvent({
      category: "UserAccount",
      action: "Click:ThirdPartyAuthButton",
      label: authType,
    });
    onStateChange("emailLogin");
  };

  return (
    <>
      <Heading>{title ?? intl.formatMessage(messages.preSignInTitle)}</Heading>
      {/* Stopprogation so the dropdown doesn't close when clicking on the buttons */}
      <PageContent onClick={(event) => event.stopPropagation()}>
        <Row>
          <GoogleSignInButton />
        </Row>
        <Row>
          <AppleSignInButton />
        </Row>
        <Row>
          <Button
            backgroundColor="pink"
            textColor="primaryOnDark"
            size="large"
            onClick={handleClick}
          >
            {intl.formatMessage(messages.useEmail)}
          </Button>
        </Row>
      </PageContent>
    </>
  );
}

const Row = styled.div`
  margin: ${spacing.xl} 0;
  width: 100%;
`;
