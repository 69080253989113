import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import type { GeocodedPlace } from "src/PrefetchData";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { useApiConfig } from "src/api/ApiConfigProvider";
import type { AutocompletePlace } from "src/api/AutocompleteResponse";
import { ButtonBase } from "src/components/Button/ButtonBase";
import { Icon } from "src/components/Icon/Icon";
import { border_radius } from "src/design-system/tokens/border";
import { spacing } from "src/design-system/tokens/spacing";
import type { TripPlannerTransportKey } from "src/domain/TripPlanner/TripPlannerProvider";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";
import { Close } from "src/svg/Close";
import { MenuLine } from "src/svg/MenuLine";
import { color, iconSize } from "src/theme";
import type { MergeElementProps } from "src/utils/MergeElementProps";
import { autocompleteToGeocodedPlace } from "src/utils/autocompleteToGeocodedPlace";
import { localeToLanguageCode } from "src/utils/conversions/languageCode";
import styled, { css } from "styled-components";
import {
  font_line_height,
  font_size,
  font_weight,
} from "src/design-system/tokens/typography";
import { AddDestinationDialog } from "../../AddDestinationButton/AddDestinationDialog/AddDestinationDialog";
import { PlaceNumber } from "../../Headings/PlaceTitle";
import messages from "./TripCardHeading.messages";

type Props = MergeElementProps<
  "div",
  {
    canonicalPair: TripPlannerTransportKey;
    geocodedPlace: GeocodedPlace;
    index: number;
    onRemove: () => void;
    dragRef?: (ref: HTMLElement | null) => void;
    isDragging: boolean;
    isOverlay?: boolean;
  }
>;

export function TripCardInputHeading({
  canonicalPair,
  index,
  geocodedPlace,
  onRemove,
  dragRef,
  isDragging,
  isOverlay = false,
  ...other
}: Props) {
  const intl = useIntl();
  const { dispatch, tripPlannerDetails } = useTripPlannerContext();
  const languageCode = localeToLanguageCode(intl.locale);
  const apiConfig = useApiConfig();
  const queryClient = useQueryClient();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleEditDrawer = useCallback(() => {
    const action = `${!isDrawerOpen ? "Open" : "Close"}:EditDestination`;
    sendAnalyticsInteractionEvent({ category: "TripPlanner", action: action });
    setIsDrawerOpen(!isDrawerOpen);
  }, [isDrawerOpen, setIsDrawerOpen]);

  const onSelectDestination = useCallback(
    (newPlace: AutocompletePlace, place: GeocodedPlace) => {
      setIsDrawerOpen(false);
      if (newPlace.canonicalName !== place.canonicalName) {
        sendAnalyticsInteractionEvent({
          category: "TripPlanner",
          action: "Select:EditDestinationInline",
          label: newPlace.canonicalName,
        });
        autocompleteToGeocodedPlace(
          newPlace,
          (geocodedPlace: GeocodedPlace) =>
            dispatch({
              type: "EDIT_DESTINATION",
              index,
              newPlace: geocodedPlace,
            }),
          languageCode,
          queryClient,
          apiConfig
        );
      }
    },
    [apiConfig, dispatch, index, languageCode, queryClient]
  );

  const onClickDelete = useCallback(() => {
    if (index === tripPlannerDetails.places.length - 1) {
      onRemove();
    }

    sendAnalyticsInteractionEvent({
      category: "TripPlanner",
      action: "Click:RemoveDestinationInline",
      label: canonicalPair.split("_")[0],
    });
    dispatch({
      type: "REMOVE_PLACE",
      index,
    });
  }, [index, onRemove, dispatch, canonicalPair, tripPlannerDetails.places]);

  return (
    <Container
      data-testid={`trip-card-heading-${index}`}
      ref={dragRef}
      $isDragging={isDragging}
      $isOverlay={isOverlay}
      {...other}
    >
      <DragHandleButton data-testid={`drag-handle-${index}`}>
        <Icon size="md">
          <MenuLine title={intl.formatMessage(messages.dragPlace)} tint="n80" />
        </Icon>
      </DragHandleButton>
      <PlaceNumberDesktop>{index + 1}</PlaceNumberDesktop>
      <InnerContentContainer>
        <PlaceDetailsContainer>
          <PlaceNameButton
            title={intl.formatMessage(messages.edit)}
            onClick={toggleEditDrawer}
            data-testid="place-name-btn"
          >
            <TruncatedText>
              {geocodedPlace.longName ?? geocodedPlace.shortName}
            </TruncatedText>
          </PlaceNameButton>
        </PlaceDetailsContainer>
        <PlaceDeleteButton
          data-testid="remove-place-btn"
          onClick={onClickDelete}
        >
          <Icon size="xs">
            <Close
              title={intl.formatMessage(messages.removePlace)}
              tint="cod"
            />
          </Icon>
        </PlaceDeleteButton>
      </InnerContentContainer>

      <AddDestinationDialog
        onSelectOption={(newPlace) => {
          onSelectDestination(newPlace, geocodedPlace);
        }}
        onBackdropClicked={toggleEditDrawer}
        onCloseClicked={toggleEditDrawer}
        isOpen={isDrawerOpen}
        place={geocodedPlace}
        initialValue={geocodedPlace.longName ?? geocodedPlace.shortName}
      />
    </Container>
  );
}
type LoadingProps = {
  index: number;
  name?: string;
};
export function LoadingHeading({ index, name }: LoadingProps) {
  return (
    <Container $isDragging={false} $isOverlay={false}>
      <InnerContentContainer>
        <PlaceDetailsContainer>
          <PlaceNumberDesktop>{index + 1}</PlaceNumberDesktop>
          <PlaceNameButton>
            <TruncatedText>{name}</TruncatedText>
          </PlaceNameButton>
        </PlaceDetailsContainer>
      </InnerContentContainer>
    </Container>
  );
}

const DragHandleButton = styled.div`
  position: absolute;
  left: -${spacing.xs};

  padding-top: ${spacing.xl};
  padding-left: 10px; // spacing.md + 2px;
  transform: translateX(-100%);
  opacity: 0;
  height: 48px;
  width: 30px;
  align-items: center;
  justify-content: center;
  cursor: grab;
`;

const PlaceEdit = styled.div`
  display: inline-block;
  transition: opacity 0.3s ease;
  opacity: 1;
  margin: ${spacing.sm} ${spacing.xs} 0 ${spacing.lg};
`;

export const PlaceNameButton = styled(ButtonBase)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: ${font_weight.medium};
  font-size: ${font_size.text_base};
  line-height: ${font_line_height.leading_tight};
  color: ${color.cod};
  background-color: ${color.n20};
  text-align: center;
  border: 1px solid transparent;
  border-radius: ${border_radius.rounded_md};

  max-height: 40px;
  padding: ${spacing.lg} ${spacing.xl};
  margin-left: ${spacing.sm};
  max-width: 90%;
  min-width: min(280px, 90%);
`;

const PlaceNumberDesktop = styled(PlaceNumber)`
  position: absolute;
  margin: 0 ${spacing.md} 0 0;
  left: 10px;
  top: 10px;
  transform: translateX(-100%);
  cursor: grab;
`;

const PlaceDeleteButton = styled(ButtonBase)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-self: center;
  width: ${iconSize.xxxl};

  fill: ${color.n50};
  opacity: 0;
  transition: opacity 0.3s ease;
`;
const PlaceDetailsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  border-radius: ${border_radius.rounded_xl};
  cursor: grab;
  width: 90%;
`;

const Container = styled.div<{ $isDragging: boolean; $isOverlay: boolean }>`
  margin-bottom: ${spacing.xs};
  position: relative;
  ${({ $isOverlay }) =>
    !$isOverlay &&
    css`
      margin-left: -${spacing.xxl};
    `}

  ${({ $isDragging }) => {
    if ($isDragging) {
      return css`
        opacity: 0.2;
      `;
    }
  }}

  &:hover {
    background-color: ${color.n10};
    border-top-right-radius: ${border_radius.rounded_md};
    border-bottom-right-radius: ${border_radius.rounded_md};

    ${PlaceNumberDesktop} {
      opacity: 0;
    }

    ${PlaceDeleteButton}, ${DragHandleButton}, ${PlaceEdit} {
      opacity: 1;
    }

    ${DragHandleButton} {
      transform: translateX(-70%);
      background-color: ${color.n10};
      border-top-left-radius: ${border_radius.rounded_md};
      border-bottom-left-radius: ${border_radius.rounded_md};
    }

    ${PlaceNameButton} {
      border: 1px solid ${color.cod};
      background-color: ${color.white};
    }
  }

  &:focus,
  &:active {
    background-color: ${color.n10};
    border-top-right-radius: ${border_radius.rounded_md};
    border-bottom-right-radius: ${border_radius.rounded_md};

    ${PlaceNumberDesktop} {
      opacity: 0;
    }

    ${DragHandleButton} {
      opacity: 1;
      transform: translateX(-70%);
      background-color: ${color.n10};
      border-top-left-radius: ${border_radius.rounded_md};
      border-bottom-left-radius: ${border_radius.rounded_md};
    }

    ${PlaceNameButton} {
      background-color: ${color.n20};
      border: 1px solid ${color.n30};
    }
  }
`;

const InnerContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.3s ease;
  margin-left: 20px;
  height: 48px;
  width: 100%;
  padding-right: ${spacing.xxl};
`;

export const TruncatedText = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
