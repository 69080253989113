import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { format24HourTime } from "src/components/FormattedIsoTime/FormattedIsoTime";
import { RadioGroupList } from "src/components/RadioGroupList/RadioGroupList";
import { timeFormatMessages } from "src/domain/TimeFormatScreen/TimeFormatScreen.messages";
import { formatIsoTime } from "src/utils/dateTime";
import { useLayout } from "src/utils/hooks/useLayout";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import useUser from "src/utils/hooks/useUser";
import { navigateToNewState } from "src/utils/location/navigateToNewState";
import { getSupportedTimeFormat } from "src/utils/timeFormat";

export function TimeFormatScreenContent() {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useTypedLocation();
  const layout = useLayout();
  const isDesktop = layout === "desktop";

  const { prefers12Hour, savePrefers12Hour } = useUser();

  function handleChange(prefers12HourValue: string) {
    const newPrefers12HourValue = prefers12HourValue === "true";

    const { message: timeFormatGA } = getSupportedTimeFormat(prefers12Hour);
    const { message: newTimeFormatGA } = getSupportedTimeFormat(
      newPrefers12HourValue
    );

    sendAnalyticsInteractionEvent({
      category: "Preferences",
      action: "Click:SelectTimeFormat",
      label: `${timeFormatGA}->${newTimeFormatGA}`,
    });

    savePrefers12Hour(newPrefers12HourValue);
    // go back to main user preferences screen
    if (!isDesktop) {
      navigateToNewState(navigate, { preferencesScreen: "main" }, location);
    }
  }

  // get hours and minutes now in string format
  const timeNow = formatIsoTime(new Date());

  return (
    <RadioGroupList
      name="time-format"
      selectedValue={`${prefers12Hour}`}
      onChange={handleChange}
      options={[true, false].map((value) => ({
        value: `${value}`,
        label: intl.formatMessage(getSupportedTimeFormat(value).text),
        subtitle: value ? (
          <time dateTime="hh:mm">{format24HourTime(timeNow, true, intl)}</time>
        ) : (
          <time dateTime="HH:mm">{timeNow}</time>
        ),
      }))}
    />
  );
}

export function useTimeFormatPreferenceScreen() {
  const intl = useIntl();

  return {
    heading: intl.formatMessage(timeFormatMessages.timeFormatTitle),
    description: intl.formatMessage(timeFormatMessages.description),
  };
}
