import { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import type { GeocodedPlace } from "src/PrefetchData";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { ButtonBase } from "src/components/Button/ButtonBase";
import { ClickAwayListener } from "src/components/ClickAwayListener/ClickAwayListener";
import { Dropdown } from "src/components/Dropdown/Dropdown";
import { Skeleton } from "src/components/Skeleton/Skeleton";
import { border_radius } from "src/design-system/tokens/border";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";
import { ChevronRightBold } from "src/svg/ChevronRightBold";
import { Hotel } from "src/svg/Hotel";
import { MeatBalls } from "src/svg/MeatBalls";
import { OutlineMinus } from "src/svg/OutlineMinus";
import { Switch } from "src/svg/Switch";
import { Edit } from "src/svg/tripplanner/Edit";

import {
  color,
  fontSize,
  fontWeight,
  lineHeight,
  spacing,
  zIndex,
} from "src/theme";
import { useHotelListBase } from "src/utils/hooks/useHotelList";
import { useNavigateToHotelsPage } from "src/utils/hooks/useNavigateToHotelsPage";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { navigateToNewState } from "src/utils/location/navigateToNewState";
import styled, { type CSSProperties, css } from "styled-components";
import type { TripPlannerTransportKey } from "../../../domain/TripPlanner/TripPlannerProvider";
import { Icon } from "../../Icon/Icon";
import { placeNumberOffsetPx } from "../Headings/PlaceTitle";
import { messages as tripCardMessages } from "../TripCard/TripCard.messages";
import messages from "./MoreOptionsButton.messages";

type MoreOptionsButtonProps = {
  canonicalPair: TripPlannerTransportKey;
  place: GeocodedPlace;
  index: number;
  onClickEdit: () => void;
};

export function MoreOptionsButton(props: MoreOptionsButtonProps) {
  const analyticsLabel = props.place.canonicalName;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  function showDropdown() {
    if (!isDropdownOpen) {
      sendAnalyticsInteractionEvent({
        category: "TripPlanner",
        action: "Open:DestinationOptions",
        label: analyticsLabel,
      });
    }
    setIsDropdownOpen(true);
  }

  function hideDropdown() {
    sendAnalyticsInteractionEvent({
      category: "TripPlanner",
      action: "Close:DestinationOptions",
      label: analyticsLabel,
    });
    setIsDropdownOpen(false);
  }

  const intl = useIntl();

  return (
    <Wrapper data-testid={`more-options-button-${props.index}`}>
      <OpenMenuButton
        title={intl.formatMessage(messages.moreOptions, {
          placeName: props.place.shortName,
        })}
        isDropdownOpen={isDropdownOpen}
        onClick={showDropdown}
      >
        <Icon size="xxl">
          <MeatBalls tint={isDropdownOpen ? "white" : "cod"} />
        </Icon>
      </OpenMenuButton>
      {isDropdownOpen && (
        <ClickAwayListener onClickAway={hideDropdown}>
          <MenuDropdown anchor="right">
            <MenuOptions {...props} {...{ hideDropdown }} />
          </MenuDropdown>
        </ClickAwayListener>
      )}
    </Wrapper>
  );
}

function MenuOptions({
  canonicalPair,
  place,
  index,
  onClickEdit,
  hideDropdown,
}: MoreOptionsButtonProps & { hideDropdown: () => void }) {
  const intl = useIntl();
  const location = useTypedLocation();
  const navigate = useNavigate();
  const { navigateToHotels } = useNavigateToHotelsPage();
  const { dispatch, tripPlannerDetails } = useTripPlannerContext();

  function handleRemovePlace() {
    sendAnalyticsInteractionEvent({
      category: "TripPlanner",
      action: "Click:RemoveDestination",
      label: canonicalPair.split("_")[0],
    });
    dispatch({
      type: "REMOVE_PLACE",
      index,
    });
  }

  function handleReorderTrip() {
    sendAnalyticsInteractionEvent({
      category: "TripPlanner",
      action: "Open:ReorderTrip",
    });
    navigateToNewState(navigate, { reorderingTripPlan: true }, location);
  }

  function handleAccomClick() {
    const originCanonical =
      index === 0
        ? place.canonicalName
        : tripPlannerDetails.places[index - 1].canonicalName;

    const canonicalOverrides = {
      destinationCanonical: place.canonicalName,
      originCanonical,
    };
    sendAnalyticsInteractionEvent({
      category: "TripPlanner",
      action: "Click:AccomCard",
    });
    navigateToHotels({ ...canonicalOverrides });
  }

  return (
    <List role="menu" onClick={(e) => e.stopPropagation()}>
      <Item role="menuitem" data-testid="edit-place">
        <ButtonMenu
          onClick={() => {
            onClickEdit();
            hideDropdown();
          }}
        >
          <Icon size="lg">
            <Edit title="edit" tint="n300" />
          </Icon>
          <span>{intl.formatMessage(messages.edit)}</span>
        </ButtonMenu>
      </Item>

      <Item role="menuitem" data-testid="reorder-trip">
        <ButtonMenu
          onClick={handleReorderTrip}
          disabled={tripPlannerDetails.places.length < 2}
        >
          <RotatedIcon size="lg">
            <Switch
              tint={tripPlannerDetails.places.length < 2 ? "n50" : "n300"}
            />
          </RotatedIcon>
          <TextButton>{intl.formatMessage(messages.reorderTrip)}</TextButton>
        </ButtonMenu>
      </Item>

      <Item role="menuitem" data-testid="accomm-cta">
        <HotelCTA place={place} handleAccomClick={handleAccomClick} />
      </Item>

      {tripPlannerDetails.places.length > 2 && (
        <Item role="menuitem" data-testid="remove-place">
          <ButtonMenu onClick={handleRemovePlace}>
            <Icon size="lg">
              <OutlineMinus tint="high" />
            </Icon>
            <TextButton colorKey="high">
              {intl.formatMessage(messages.removePlace)}
            </TextButton>
          </ButtonMenu>
        </Item>
      )}
    </List>
  );
}

function HotelCTA({
  place,
  handleAccomClick,
}: {
  place: GeocodedPlace;
  handleAccomClick: () => void;
}) {
  const intl = useIntl();
  const hotelData = useHotelListBase({ ...place, enabled: true });
  const hotelCurrency = hotelData.hotelListResponse?.currencyCode;
  const hotelPrice = hotelData.hotelListResponse?.minPricePerNight;
  const hasHotelPrice =
    !hotelData.isLoading && hotelPrice !== undefined && hotelPrice > 0;

  return (
    <ButtonMenu
      $padding={hotelData.isLoading || hasHotelPrice ? `${spacing.lg} 0` : `0`}
      $alignItems={
        hotelData.isLoading || hasHotelPrice ? "flex-start" : "center"
      }
      onClick={handleAccomClick}
    >
      <HotelButtonIcon>
        <Icon size="lg">
          <Hotel tint="n300" />
        </Icon>
      </HotelButtonIcon>
      <TextButton>
        {intl.formatMessage(messages.exploreHotels)}
        {hotelData.isLoading && (
          <Skeleton width="50px" height={`${lineHeight.tight}em`} />
        )}
        {hasHotelPrice && (
          <Subtext>
            {intl.formatMessage(tripCardMessages.fromPrice, {
              price: intl.formatNumber(hotelPrice, {
                notation: "compact",
                currency: hotelCurrency,
                style: "currency",
              }),
            })}
          </Subtext>
        )}
      </TextButton>
      <RightAlignedIcon
        $margin={
          hotelData.isLoading || hasHotelPrice
            ? `${spacing.xs} ${spacing.sm} 0 auto`
            : `0 ${spacing.sm} 0 auto`
        }
        size="sm"
      >
        <ChevronRightBold tint="n300" />
      </RightAlignedIcon>
    </ButtonMenu>
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 24px;
  // height of the number - 1/2 of the icon size
  margin-top: calc(${placeNumberOffsetPx}px - 12px);
`;
const OpenMenuButton = styled(ButtonBase)<{ isDropdownOpen: boolean }>`
  width: ${placeNumberOffsetPx * 2}px;
  height: ${placeNumberOffsetPx * 2}px;

  ${({ isDropdownOpen }) =>
    isDropdownOpen &&
    css`
      background-color: ${color.n300};
      border-radius: 50%;
    `}

  &:focus-visible {
    outline: 3px solid ${color.pink};
  }
`;

const MenuDropdown = styled(Dropdown)`
  position: absolute;
  z-index: ${zIndex.dropdown};
  top: 110%;
  min-width: 216px;
  width: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: ${border_radius.rounded_md};
  padding: 0 ${spacing.xl};
  background: ${color.white};
  max-width: 360px;

  // Minimise the scrollbar, this will only be scrollable if the dropdown
  // hit's the bottom/top edge of the viewport
  scrollbar-width: 4px; /* Firefox */
  &::-webkit-scrollbar {
    width: 4px;
  }
`;

const List = styled.ul`
  list-style: none;
`;
const Item = styled.li`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  border-bottom: 1px solid ${color.n30};
  width: 100%;
  min-height: 48px;

  &:last-child {
    border-bottom: none;
  }
`;

const ButtonMenu = styled(ButtonBase)<{
  $alignItems?: "center" | "flex-start";
  $padding?: CSSProperties["padding"];
}>`
  width: 100%;
  text-align: left;
  align-items: ${({ $alignItems = "center" }) => $alignItems};
  padding: ${({ $padding }) => Boolean($padding) && $padding};
  justify-content: flex-start;
  font-weight: ${fontWeight.normal};
  font-size: ${fontSize.body};
  line-height: ${lineHeight.tight};
  color: ${color.cod};
  gap: ${spacing.xl};

  &:focus-visible {
    outline: 3px solid ${color.pink};
  }
  &:disabled {
    color: ${color.n50};
  }
`;
const RotatedIcon = styled(Icon)`
  transform: rotate(90deg);
`;

const RightAlignedIcon = styled(Icon)<{ $margin?: CSSProperties["margin"] }>`
  margin: ${({ $margin = `0` }) => $margin};
`;

const TextButton = styled.span<{ colorKey?: keyof typeof color }>`
  max-width: 320px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ colorKey }) =>
    colorKey &&
    css`
      color: ${color[colorKey]};
    `}
`;

const HotelButtonIcon = styled.div`
  margin-top: ${spacing.xs};
`;

const Subtext = styled.span`
  display: block;
  font-size: ${fontSize.h6};
  color: ${color.n70};
  font-weight: ${fontWeight.normal};
  line-height: ${lineHeight.snug};
`;
