import { useMemo } from "react";
import { useIntl } from "react-intl";
import { formatPriceRange } from "src/components/FormattedPriceRange/FormattedPriceRange";
import { Skeleton } from "src/components/Skeleton/Skeleton";
import { spacing } from "src/design-system/tokens/spacing";
import { font_size } from "src/design-system/tokens/typography";
import { color, screenMinWidth } from "src/theme";
import { lowestPriceRangeFromSearch } from "src/utils/adapters/priceRange";
import type { useGetTripRoutes } from "src/utils/hooks/useGetTripRoutes";
import useUser from "src/utils/hooks/useUser";
import styled from "styled-components";
import messages from "../TripPlannerScreen.messages";
import { useTripPlannerContext } from "../hooks/useTripPlannerContext";
import { TripSaveButton } from "./TripSaveButton";

export default function LargeHeading() {
  const intl = useIntl();
  const { currencyCode } = useUser();
  const { tripRoutes, tripPlannerDetails, apiState, tripDestination } =
    useTripPlannerContext();
  const { isLoading } = tripDestination;

  const locationCount = tripPlannerDetails.places.length;
  const subheadingText = intl.formatMessage(
    locationCount === 1
      ? messages.destinationsSingular
      : messages.destinationsPlural,
    { destinationCount: locationCount }
  );

  const priceRange = useMemo(() => {
    const { lowRange, highRange } = getTripPriceRange(tripRoutes);
    if (highRange && lowRange) {
      return formatPriceRange(intl, lowRange, highRange, currencyCode);
    }
  }, [currencyCode, intl, tripRoutes]);

  return (
    <TripHeaderContainer>
      <InnerContentContainer>
        {isLoading || apiState.fetchState === "fetching" ? (
          <HeaderSkeleton />
        ) : (
          <>
            <FirstRow>
              <Heading data-testid="trip-planner-title">
                {intl.formatMessage(messages.tripOverview)}
              </Heading>
              <ActionsContainer>
                <TripSaveButton />
              </ActionsContainer>
            </FirstRow>

            <Subheading data-testid="trip-subheading">
              <span>{subheadingText}</span>
              {priceRange && (
                <>
                  <Dot $light />
                  <span>
                    {intl.formatMessage(messages.travel)} {priceRange}
                  </span>
                </>
              )}
            </Subheading>
          </>
        )}
      </InnerContentContainer>
    </TripHeaderContainer>
  );
}

function HeaderSkeleton() {
  return (
    <>
      <Heading>
        <Skeleton
          height={spacing.xxl}
          width="256px"
          margin={`0 0 ${spacing.md}`}
        />
      </Heading>
      <Subheading>
        <Skeleton inline height={spacing.xl} width="25%" />
        <Dot $light />
        <Skeleton inline height={spacing.xl} width="25%" />
      </Subheading>
    </>
  );
}

function getTripPriceRange(tripRoutes: ReturnType<typeof useGetTripRoutes>) {
  let lowRange = 0;
  let highRange = 0;
  tripRoutes.queries.forEach((query) => {
    if (query.data) {
      const priceRange = lowestPriceRangeFromSearch(query.data);
      lowRange += priceRange ? priceRange.low : 0;
      highRange += priceRange ? priceRange.high : 0;
    }
  });
  return { lowRange, highRange };
}

const InnerContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 100%;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.xl};
  margin-top: 0;
  ${screenMinWidth.sm} {
    margin-top: -5px;
  }
`;

const FirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Heading = styled.h3`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: ${font_size.text_xl};
  margin-bottom: ${spacing.sm};

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  ${screenMinWidth.sm} {
    white-space: normal;
    display: -webkit-box;
  }
`;

const Subheading = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  gap: ${spacing.md};
  align-items: center;
  font-size: ${font_size.text_sm};
  flex-wrap: wrap;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }
`;

const TripHeaderContainer = styled.div`
  position: relative;
  z-index: 1;
  padding: ${spacing.md} 0 0;

  ${screenMinWidth.sm} {
    background-color: ${color.white};
    max-width: 100%;
    padding: ${spacing.xxl} ${spacing.xl} ${spacing.xxl} ${spacing.xxl};
    margin-bottom: 0;

    &:after {
      content: "";
      display: block;
      width: 100%;
      background-color: ${color.n30};
      height: 1px;
      position: absolute;
      bottom: 0;
      margin-top: ${spacing.xxl};
      left: 0;
    }
  }
`;

const Dot = styled.span<{ $light?: boolean }>`
  display: block;
  min-width: ${spacing.sm};
  min-height: ${spacing.sm};
  border-radius: 50%;
  background-color: ${(props) => (props.$light ? color.n40 : color.n100)};
`;
