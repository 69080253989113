import type React from "react";
import { border_radius } from "src/design-system/tokens/border";
import color from "src/design-system/tokens/color";
import { spacing } from "src/design-system/tokens/spacing";
import { font_line_height } from "src/design-system/tokens/typography";
import { desktopLayout } from "src/utils/hooks/useLayout";
import styled, { css } from "styled-components";

const TAIL_SIZE = {
  width: 16,
  height: 8,
};

type Props = {
  id: string;
  placement?: "top" | "bottom";
  anchor?: "left" | "right" | "center";
  anchorOffset?: number;
  placementOffset?: number;
  tooltipContent: React.ReactNode;
  children: React.ReactNode;
  forceShow?: boolean;
  className?: string;
  disableAnimation?: boolean;
  showOnMobileFocus?: boolean;
};

export default function Tooltip({
  id,
  tooltipContent,
  placement = "top",
  anchor = "center",
  anchorOffset = 0,
  placementOffset = 0,
  children,
  forceShow,
  showOnMobileFocus,
  className,
  disableAnimation = false,
}: Props) {
  return (
    <TooltipContainer
      aria-describedby={id}
      $forceShow={forceShow}
      $showOnMobileFocus={showOnMobileFocus}
      className={className}
    >
      {children}
      {tooltipContent && (
        <TooltipPopout
          id={id}
          $anchorOffset={anchorOffset}
          $placementOffset={placementOffset}
          $anchor={anchor}
          $placement={placement}
          $disableTransition={disableAnimation}
          role="tooltip"
        >
          <Tail
            xmlns="http://www.w3.org/2000/svg"
            width={TAIL_SIZE.width}
            height={TAIL_SIZE.height}
            viewBox="0 0 16 8"
            fill="none"
          >
            <path
              d="M9.41421 1.41421C8.63317 0.633165 7.36684 0.633164 6.58579 1.41421L0 8H16L9.41421 1.41421Z"
              fill="currentColor"
            />
          </Tail>
          {tooltipContent}
        </TooltipPopout>
      )}
    </TooltipContainer>
  );
}

const Tail = styled.svg``;

const TooltipPopout = styled.div<{
  $placement: "top" | "bottom";
  $anchor: "left" | "right" | "center";
  $anchorOffset: number;
  $placementOffset: number;
  $disableTransition: boolean;
}>`
  --anchor-offset: ${(props) => `${props.$anchorOffset}px`};
  --placement-offset: ${(props) => `${props.$placementOffset}px`};
  --background-color: ${color.tooltip.bg};

  position: absolute;
  z-index: 2;
  width: max-content;
  border-radius: ${border_radius.rounded_md};
  line-height: ${font_line_height.leading_normal};
  background-color: var(--background-color);
  color: ${color.text.onBgFill};

  pointer-events: none;
  opacity: 0;

  ${(props) =>
    !props.$disableTransition &&
    css`
      transition: opacity 0.2s ease;
    `}

  ${(props) => PlacementCSS[props.$placement]}
  ${(props) => AnchorCSS[props.$anchor]}


  ${Tail} {
    position: absolute;
    color: var(--background-color);
  }
`;

const PlacementCSS = {
  bottom: css`
    top: 100%;
    margin-top: var(--placement-offset);

    ${Tail} {
      bottom: 100%;
    }
  `,
  top: css`
    bottom: 100%;
    margin-bottom: var(--placement-offset);

    ${Tail} {
      top: 100%;
      transform: scale(1, -1);
    }
  `,
};

const AnchorCSS = {
  left: css`
    left: var(--anchor-offset);
    ${Tail} {
      left: min(10%, ${spacing.xl});
    }
  `,
  right: css`
    right: var(--anchor-offset);
    ${Tail} {
      right: min(10%, ${spacing.xxl});
    }
  `,
  center: css`
    left: 50%;
    transform: translateX(-50%);
    ${Tail} {
      left: calc(50% - ${TAIL_SIZE.width / 2}px);
    }
  `,
};

const tooltipVisibleCSS = css`
  ${TooltipPopout} {
    opacity: 1;
    pointer-events: auto;
  }
`;

const TooltipContainer = styled.div<{
  $forceShow?: boolean;
  $showOnMobileFocus?: boolean;
}>`
  position: relative;

  ${desktopLayout} {
    &:hover {
      ${tooltipVisibleCSS};
    }
  }

  ${({ $showOnMobileFocus }) =>
    $showOnMobileFocus
      ? css`
          &:focus-within,
          &:hover {
            ${tooltipVisibleCSS};
          }
        `
      : css`
          ${desktopLayout} {
            &:focus-within {
              ${tooltipVisibleCSS};
            }
          }
        `}

  ${({ $forceShow }) =>
    $forceShow &&
    css`
      ${tooltipVisibleCSS};
    `}
`;
