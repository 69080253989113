import type { ComponentType, PropsWithChildren } from "react";
import { type FallbackProps, ErrorBoundary } from "react-error-boundary";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import * as Sentry from "@sentry/react";
import {
  isImportError,
  isHandledImportError,
} from "src/utils/vitePreloadErrorHandler";
import { sendAnalyticsNonInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { useTypedLocation } from "../../utils/hooks/useTypedLocation";
import { mobileLayout, useLayout } from "../../utils/hooks/useLayout";
import { color, spacing, textColor } from "../../theme";
import { ErrorNavbar } from "../Navbar/Navbar";
import { useRouteParams } from "../../utils/useRouteParams";
import logError from "../../utils/logError";
import messages from "./DefaultErrorBoundary.messages";

type ErrorFallbackProps = {
  defaultMessage?: boolean;
  ErrorFallback?: ComponentType<FallbackProps>;
};

type DefaultErrorBoundaryProps = PropsWithChildren<ErrorFallbackProps>;

export function DefaultErrorBoundary(props: DefaultErrorBoundaryProps) {
  const { defaultMessage, ErrorFallback, children } = props;
  const { originCanonical, destinationCanonical } = useRouteParams();
  const location = useTypedLocation();

  const FallbackComponent = getFallbackComponent({
    defaultMessage,
    ErrorFallback,
  });

  return (
    <ErrorBoundary
      FallbackComponent={FallbackComponent}
      onError={handleError}
      resetKeys={[originCanonical, destinationCanonical, location.hash]}
    >
      {children}
    </ErrorBoundary>
  );
}

function handleError(error: Error) {
  if (isHandledImportError(error)) {
    // If the page is being automatically refreshed due to a preload error,
    // we don't want to log it as a sentry exception.
    return;
  }

  logError(error, ["caught-by-custom-error-boundary"]);

  // Send the error to Sentry.
  Sentry.captureException(error, {
    tags: {
      caughtBy: "custom-react-error-boundary",
    },
  });

  sendAnalyticsNonInteractionEvent({
    category: "GenericExplore",
    action: "AppErrorBoundary:ErrorCaught",
    label: String(error),
  });
}

// HOC to get the component we will display
function getFallbackComponent({
  defaultMessage,
  ErrorFallback,
}: ErrorFallbackProps) {
  if (ErrorFallback) {
    return ErrorFallback;
  } else if (defaultMessage) {
    return LayoutErrorFallback;
  } else {
    return NoOpFallbackComponent;
  }
}

function NoOpFallbackComponent() {
  return <></>;
}

function LayoutErrorFallback({ error }: FallbackProps) {
  const layout = useLayout();
  const intl = useIntl();

  const handleClick = () => {
    sendAnalyticsInteractionEvent({
      category: "ErrorBoundary",
      action: `Click:StartOver`,
    });
  };

  const message = isImportError(error)
    ? messages.couldntLoadSite
    : messages.resultsNotFound;

  return (
    <>
      {layout === "mobile" && <ErrorNavbar />}
      <ErrorMessage>
        <FormattedMessage
          {...message}
          values={{
            homeLink: (
              <Link
                href="https://www.rome2rio.com"
                rel="noopener"
                onClick={handleClick}
              >
                {intl.formatMessage(messages.startOver)}
              </Link>
            ),
          }}
        />
      </ErrorMessage>
    </>
  );
}

export class MapLoadError extends Error {
  constructor(message: string, cause?: unknown) {
    super(message);
    this.cause = cause;
    this.name = "MapLoadError";
  }
}

export function MapErrorFallback({ error }: { error: any }) {
  const layout = useLayout();
  const intl = useIntl();

  const handleClick = () => {
    sendAnalyticsInteractionEvent({
      category: "ErrorBoundary",
      action: `Click:StartOver`,
      label: "Map",
    });
  };

  let message;
  if (isImportError(error)) {
    message = messages.couldntLoadSite;
  } else if (error instanceof MapLoadError) {
    message = messages.couldntLoadMap;
  } else {
    message = messages.resultsNotFoundMap;
  }

  return (
    <>
      {layout === "mobile" && <ErrorNavbar />}
      <ErrorMessage>
        <FormattedMessage
          {...message}
          values={{
            homeLink: (
              <Link
                href="https://www.rome2rio.com"
                rel="noopener"
                onClick={handleClick}
              >
                {intl.formatMessage(messages.startOver)}
              </Link>
            ),
          }}
        />
      </ErrorMessage>
    </>
  );
}

const ErrorMessage = styled.div`
  ${mobileLayout} {
    margin-top: 44px; /* height of top navbar */
  }
  height: 100%; /* screen height minus top navbar */
  background: ${color.white};
  padding: ${spacing.xl};
`;

const Link = styled.a`
  color: ${textColor.pinkOnLight};
  text-decoration: none;
`;
