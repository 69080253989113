import { registerOnConsent } from "@rome2rio/tcf-api";

export type GoogleInit = {
  (eventMethod: "init", apiKey: string, dpId: number): void;
};
export type GoogleIdentity = {
  (
    eventMethod: "identity",
    identifier: {
      uid: string;
    }
  ): void;
};

const GOOGLE_CONSENT_PURPOSES_REQUIRED = [1, 3, 5];
const GOOGLE_LEGITIMATE_INTEREST_PURPOSES_REQUIRED: number[] = [];
const GOOGLE_SALES_CONSENT_REQUIRED = true;
const GOOGLE_SHARING_CONSENT_REQUIRED = true;

export function registerGoogleConsent() {
  registerOnConsent({
    onGiven: loadGoogleConsentTag,
    onRevoked: disableGoogle,
    gdpr: {
      consentPurposesRequired: GOOGLE_CONSENT_PURPOSES_REQUIRED,
      legitimateInterestPurposesRequired:
        GOOGLE_LEGITIMATE_INTEREST_PURPOSES_REQUIRED,
    },
    us: {
      saleConsentRequired: GOOGLE_SALES_CONSENT_REQUIRED,
      sharingConsentRequired: GOOGLE_SHARING_CONSENT_REQUIRED,
    },
  });
}

function loadGoogleConsentTag() {
  const googleScript = document.createElement("script");

  googleScript.type = "text/javascript";
  googleScript.src = "https://www.googletagmanager.com/gtag/js?id=AW-968290294";
  googleScript.async = true;

  document.body.appendChild(googleScript);
}

function disableGoogle() {
  // Only way to stop the running script is to reload the page
  window.location.reload();
}
