import { getSegmentIndexFromHash } from "../location/segmentIndexFromHash";
import { useTypedLocation } from "./useTypedLocation";
import { useRouteIndex } from "./useRoute";
import { useIsRouteSkipped } from "./useIsRouteSkipped";

/**
 * @note when skipping a route page the location hash will no longer
 * contain the segment index so this method will return undefined.
 */
export function useRouteSegmentIndex(): number | undefined {
  const location = useTypedLocation();
  const segmentIndex = getSegmentIndexFromHash(location.hash);

  const routeIndex = useRouteIndex();
  const isRouteSkipped = useIsRouteSkipped(routeIndex);

  return isRouteSkipped ? undefined : segmentIndex;
}
