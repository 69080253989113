import { useQuery } from "@tanstack/react-query";
import type { PassengerDetails } from "src/PassengerDetailsProvider";
import type { SchedulesResponse } from "src/api/SchedulesResponse";
import type { SearchResponse } from "src/api/SearchResponse";
import type { Mode } from "src/utils/types/mode";
import { useEffect } from "react";
import { pushCrimtanEvent } from "./pushDataLayerEvent";

type CrimtanParams = {
  searchResponse?: SearchResponse;
  isCrimtanEnabled?: boolean;
  schedulesResponse?: SchedulesResponse;
  passengers?: PassengerDetails;
  transitMode?: Mode;
};

const useCrimtan = ({
  searchResponse,
  isCrimtanEnabled,
  schedulesResponse,
  passengers,
  transitMode,
}: CrimtanParams) => {
  useEffect(() => {
    window.cmtnDataLayer = window.cmtnDataLayer || [];
  }, []);

  const customDimensions = searchResponse?.analytics?.custom_dimensions;

  const origin = customDimensions?.dimension21;
  const destination = customDimensions?.dimension22;
  const isOriginString = typeof origin === "string";
  const isDestinationString = typeof destination === "string";

  const disableQuery =
    !isCrimtanEnabled ||
    !origin ||
    !isOriginString ||
    !destination ||
    !isDestinationString ||
    (transitMode && schedulesResponse === undefined);

  useQuery({
    queryKey: [
      "useCrimtan",
      isCrimtanEnabled,
      searchResponse?.request,
      schedulesResponse,
    ],
    queryFn: () => {
      if (isOriginString && isDestinationString) {
        pushCrimtanEvent({
          event: "transportSearch",
          origin,
          destination,
          travelMode: transitMode,
          travelCarriers: getCarriers(schedulesResponse) ?? [],
          outboundDate: schedulesResponse?.request.outboundDateTime,
          inboundDate: schedulesResponse?.request.inboundDateTime,
          passengerCount: passengers?.length,
        });
      }

      // Queries must return a value.
      return "";
    },
    refetchOnWindowFocus: false,
    enabled: !disableQuery,
  });
};

const getCarriers = (
  schedulesResponse?: SchedulesResponse
): string[] | undefined => {
  if (!schedulesResponse?.carriers) return;

  return schedulesResponse?.carriers.map((c) => c.name);
};

export default useCrimtan;
