import { useFeature } from "src/feature/useFeature";
import { getSingleMajorFlightSegment } from "../getSingleMajorFlightSegment";
import useSearch from "./useSearch";

export function useSkipToFlightSegment(routeIndex?: number) {
  const { searchResponse } = useSearch();
  const skipRoutesFeature = useFeature("SkipRoutes");

  const skipRoutes =
    skipRoutesFeature === "skip" || skipRoutesFeature === "breadcrumbs";

  const singleMajorFlightSegment = getSingleMajorFlightSegment(
    searchResponse,
    routeIndex
  );

  const shouldSkipToFlight =
    skipRoutes &&
    !!searchResponse &&
    routeIndex !== undefined &&
    singleMajorFlightSegment !== undefined;

  return shouldSkipToFlight ? singleMajorFlightSegment : undefined;
}
