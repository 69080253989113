import { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { useCreateTripFromSearch } from "src/utils/useCreateTripFromSearch";
import { hashAndStateChange } from "src/utils/location/hashAndStateChange";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import type { AutocompletePlace } from "../../../api/AutocompleteResponse";
import { Drawer } from "../../../components/Drawer/Drawer";
import { localeToLanguageCode } from "../../../utils/conversions/languageCode";
import { encodeSearchParamsForUrl } from "../../../utils/encodeSearchParamsForUrl";
import { useIsHotelsUrlDeeplink } from "../../../utils/hooks/useNavigateToHotelsPage";
import useSearch from "../../../utils/hooks/useSearch";
import {
  type LocationState,
  useTypedLocation,
} from "../../../utils/hooks/useTypedLocation";
import { navigateToNewState } from "../../../utils/location/navigateToNewState";
import { getPath } from "../../../utils/url";
import {
  type AutocompleteKind,
  AutocompleteScreen,
} from "../../AutocompleteScreen/AutocompleteScreen";
import { SearchEdit } from "../SearchEdit/SearchEdit";
import { SmallSearchBox } from "../SmallSearchBox/SmallSearchBox";

export const defaultSearchState: Partial<LocationState> = {
  searchBar: "static",
  autocomplete: undefined,
  userSearch: true,
  returnsFlowLocation: "departing",
};

export function SearchBar(props: {
  className?: string;
  textOverride?: string;
}) {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useTypedLocation();
  const { origin: searchOrigin, destination: searchDestination } = useSearch();
  const isHotelScreen = useIsHotelsUrlDeeplink();

  const [selectedOrigin, setSelectedOrigin] = useState<
    | {
        canonicalName: string;
        shortName: string;
      }
    | undefined
  >(searchOrigin);
  const [selectedDestination, setSelectedDestination] = useState<
    | {
        canonicalName: string;
        shortName: string;
      }
    | undefined
  >(searchDestination);

  const { places, addOrChangePlaceInPosition, createTrip, setNewOrder } =
    useCreateTripFromSearch({
      origin: selectedOrigin,
      destination: selectedDestination,
      setOrigin: setSelectedOrigin,
      setDestination: setSelectedDestination,
    });

  const autocompleteState = location.state?.autocomplete;
  const searchBarState = location.state?.searchBar;

  function handleAutocompleteSelectOption(place: AutocompletePlace) {
    if (autocompleteState === "origin") {
      setSelectedOrigin(place);
    } else if (autocompleteState === "destination") {
      setSelectedDestination(place);
    } else if (typeof autocompleteState === "number") {
      sendAnalyticsInteractionEvent({
        category: "SearchEdit",
        action: "Select:AddDestination",
      });
      addOrChangePlaceInPosition(place, autocompleteState);
    }

    // Close autocomplete screen.
    navigateToNewState(navigate, { autocomplete: undefined }, location, true);
  }

  function handleSearch() {
    sendAnalyticsInteractionEvent({
      category: "SearchEdit",
      action: "Click:Search",
    });

    const params = new URLSearchParams(location.search);

    navigate(
      {
        pathname: getPath(
          selectedOrigin?.canonicalName,
          selectedDestination?.canonicalName,
          localeToLanguageCode(intl.locale)
        ),
        hash: isHotelScreen ? location.hash : undefined,
        search: encodeSearchParamsForUrl(params),
      },
      {
        replace: true,
        state: defaultSearchState,
      }
    );
  }

  function handleSmallSearchClick() {
    sendAnalyticsInteractionEvent({ category: "SearchBar", action: "Click" });

    if (isHotelScreen) {
      // in HEx, go straight to choosing the new destination and skip intermediate screen.
      navigateToNewState(
        navigate,
        {
          autocomplete: "destination",
          searchBar: "edit",
        },
        location
      );
    } else {
      navigateToNewState(
        navigate,
        { searchBar: "edit", userSearch: false },
        location
      );
    }
  }

  function handleSwitch() {
    const savedDestination = selectedDestination;
    setSelectedDestination(selectedOrigin);
    setSelectedOrigin(savedDestination);
  }

  function handleClose() {
    navigateToNewState(
      navigate,
      { searchBar: "static", userSearch: false },
      location
    );
  }

  function handleAddDestination() {
    sendAnalyticsInteractionEvent({
      category: "SearchEdit",
      action: "Open:AddDestination",
    });
    navigateToNewState(
      navigate,
      {
        autocomplete: places.length,
        searchBar: "edit",
      },
      location
    );
  }

  const autocompleteInitialQuery = getAutocompleteInitialQuery(
    places.map((place) => place.shortName),
    autocompleteState
  );

  function handleCreateTrip() {
    createTrip();
    navigate(
      hashAndStateChange(
        "#trips",
        { highlightedTab: "trips", searchBar: "static", userSearch: false },
        location
      )
    );
  }

  return (
    <>
      <SmallSearchBox
        className={props.className}
        origin={searchOrigin?.shortName}
        destination={searchDestination?.shortName}
        onClick={handleSmallSearchClick}
        textOverride={props.textOverride}
      />
      <Drawer
        isOpen={searchBarState === "edit"}
        onBackdropClicked={() => {
          sendAnalyticsInteractionEvent({
            category: "SearchEdit",
            action: "Click:Shadow",
          });
          handleClose();
        }}
        onCloseClicked={() => {
          sendAnalyticsInteractionEvent({
            category: "SearchEdit",
            action: "Click:Close",
          });
          handleClose();
        }}
        anchor="top"
        hasPadding={false}
        hasRoundedCorners={false}
        hideCloseButton={true}
      >
        <SearchEdit
          origin={selectedOrigin?.shortName}
          destination={selectedDestination?.shortName}
          onOriginClick={() => {
            navigateToNewState(
              navigate,
              {
                autocomplete: "origin",
                searchBar: "edit",
              },
              location
            );
          }}
          onDestinationClick={() =>
            navigateToNewState(
              navigate,
              {
                autocomplete: "destination",
                searchBar: "edit",
              },
              location
            )
          }
          onSwitchClick={handleSwitch}
          onSearchClick={places.length > 2 ? handleCreateTrip : handleSearch}
          onCloseClick={() => {
            sendAnalyticsInteractionEvent({
              category: "SearchEdit",
              action: "Click:Close",
            });
            handleClose();
          }}
          places={places}
          onAddDestinationClick={handleAddDestination}
          onPlaceClick={(index) => {
            navigateToNewState(
              navigate,
              {
                autocomplete: index,
                searchBar: "edit",
              },
              location
            );
          }}
          setNewOrder={setNewOrder}
        />
      </Drawer>
      {autocompleteState !== undefined && (
        <AutocompleteScreen
          onSelectOption={handleAutocompleteSelectOption}
          initialQuery={autocompleteInitialQuery}
        />
      )}
    </>
  );
}

function getAutocompleteInitialQuery(
  places: string[],
  autocompleteState?: AutocompleteKind
) {
  if (autocompleteState === "origin") {
    return places[0];
  } else if (autocompleteState === "destination") {
    return places[1];
  } else if (typeof autocompleteState === "number") {
    return places[autocompleteState];
  }
}
