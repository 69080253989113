export type UrlConfig = {
  apiHost: string;
  exitHost: string;
  homepageHost: string;
};

export const urlConfig: UrlConfig = {
  apiHost: import.meta.env.VITE_API_HOST ?? window.location.origin,
  exitHost: import.meta.env.VITE_EXIT_HOST ?? window.location.origin,
  homepageHost: import.meta.env.VITE_HOMEPAGE_HOST ?? window.location.origin,
};
