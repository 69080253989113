import { useIntl } from "react-intl";
import color from "src/design-system/tokens/color";
import { spacing } from "src/design-system/tokens/spacing";
import styled from "styled-components";
import ChevronDown from "src/svg/ChevronDown.svg?react";
import ChevronUp from "src/svg/ChevronUp.svg?react";
import { Button } from "src/design-system/components/Button/Button";
import { messages } from "./ShowMoreButton.messages";

type ShowMoreButtonProps = {
  onOpenClick: () => void;
  onCloseClick: () => void;
  numResults: number;
  searchResultsLength: number;
  index?: number;
};

export function ShowMoreButton(props: ShowMoreButtonProps) {
  const intl = useIntl();

  return (
    <ButtonWrapper>
      {props.numResults < props.searchResultsLength ? (
        <Button
          variant="subtle"
          size="large"
          onPress={props.onOpenClick}
          data-testid={`show-more-${props.index ? `${props.index}` : "0"}`}
        >
          {intl.formatMessage(messages.showMoreResults, {
            count: props.searchResultsLength - props.numResults,
          })}
          <ChevronDown color={color.text.text} width={14} height={14} />
        </Button>
      ) : (
        <Button variant="subtle" size="large" onPress={props.onCloseClick}>
          {intl.formatMessage(messages.showLessResults)}
          <ChevronUp color={color.text.text} width={14} height={14} />
        </Button>
      )}
    </ButtonWrapper>
  );
}

const ButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${spacing.lg} ${spacing.md} ${spacing.sm};
`;
