import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { border_radius } from "src/design-system/tokens/border";
import color from "src/design-system/tokens/color";
import { spacing } from "src/design-system/tokens/spacing";
import { localeToLanguageCode } from "src/utils/conversions/languageCode";
import {
  useSearchInput,
  useSearchKeyboard,
} from "src/utils/hooks/useSearchInput";
import { getPath } from "src/utils/url";
import styled, { css } from "styled-components";
import { Button } from "src/design-system/components/Button/Button";
import { useFeature } from "src/feature/useFeature";
import { A11yOutline } from "src/utils/accessibility";
import {
  BlockStyles,
  SearchInput,
} from "src/components/SearchInput/SearchInput";
import { Label } from "src/design-system/components/Typography/Typography";
import { type FocusedElement, FocusContext } from "../../FocusContext";
import type { AutocompletePlace } from "../../api/AutocompleteResponse";
import { Icon } from "../../components/Icon/Icon";
import { Switch } from "../../svg/Switch";
import useSearch from "../../utils/hooks/useSearch";
import { useTheme } from "../../utils/hooks/useTheme";
import messages from "./LargeSearchBar.messages.ts";
import inputMessages from "./SearchInput/SearchInput.messages.ts";

export function LargeSearchBar() {
  const intl = useIntl();
  const navigate = useNavigate();
  const theme = useTheme();
  const isGlobalHeaderFeature = useFeature("GlobalHeader");
  const { origin, destination } = useSearch();
  const keyboard = useSearchKeyboard();
  const originSearch = useSearchInput(
    {
      id: "origin",
      place: origin,
      onSelect: handleOriginAutocompleteSelect,
    },
    keyboard
  );

  const destinationSearch = useSearchInput(
    {
      id: "destination",
      place: destination,
      onSelect: handleDestinationAutocompleteSelect,
    },
    keyboard
  );

  function handleSwitch() {
    sendAnalyticsInteractionEvent({
      category: "SearchBox",
      action: "Click:Switch",
    });

    if (origin?.canonicalName === destination?.canonicalName) return;

    destinationSearch.autocompleteState.changeDisplayValueWithoutResults(
      originSearch.autocompleteState.displayValue
    );

    originSearch.autocompleteState.changeDisplayValueWithoutResults(
      destinationSearch.autocompleteState.displayValue
    );

    originSearch.setIsResultsClosed(true);
    destinationSearch.setIsResultsClosed(true);
    originSearch.setSelectedPlace(destinationSearch.selectedPlace);
    destinationSearch.setSelectedPlace(originSearch.selectedPlace);
    navigate({
      pathname: getPath(
        destination?.canonicalName,
        origin?.canonicalName,
        localeToLanguageCode(intl.locale)
      ),
      search: window.location.search,
    });
  }

  function handleOriginAutocompleteSelect(place: AutocompletePlace) {
    sendAnalyticsInteractionEvent({
      category: "Autocomplete",
      action: "Click:AutocompleteOption",
    });
    if (!isGlobalHeaderFeature) {
      navigate({
        pathname: getPath(
          place.canonicalName,
          destination?.canonicalName,
          localeToLanguageCode(intl.locale)
        ),
        search: window.location.search,
      });
    }
    destinationSearch.inputRef.current?.focus();
  }

  function handleDestinationAutocompleteSelect(place: AutocompletePlace) {
    sendAnalyticsInteractionEvent({
      category: "Autocomplete",
      action: "Click:AutocompleteOption",
    });
    navigate({
      pathname: getPath(
        origin?.canonicalName,
        place.canonicalName,
        localeToLanguageCode(intl.locale)
      ),
      search: window.location.search,
    });
    destinationSearch.inputRef.current?.blur();
  }

  function onFocusChanged(newFocusedElement: FocusedElement) {
    const numResults =
      newFocusedElement.id === "origin"
        ? originSearch.displayResults.length
        : destinationSearch.displayResults.length;
    keyboard.setFocusedElement(newFocusedElement, numResults);
  }

  function handleSearch() {
    navigate({
      pathname: getPath(
        originSearch.selectedPlace?.canonicalName,
        destinationSearch.selectedPlace?.canonicalName,
        localeToLanguageCode(intl.locale)
      ),
      search: window.location.search,
    });
  }
  const originLabel = intl.formatMessage(inputMessages.originLabel);
  const originPlaceholder = intl.formatMessage(inputMessages.originPlaceholder);
  const destinationLabel = intl.formatMessage(inputMessages.destinationLabel);
  const destinationPlaceholder = intl.formatMessage(
    inputMessages.destinationPlaceholder
  );

  return (
    <FocusContext.Provider
      value={{ focusedElement: keyboard.focusedElement, onFocusChanged }}
    >
      <InputBars $isBaseline={!isGlobalHeaderFeature}>
        <InputBar $isBaseline={!isGlobalHeaderFeature}>
          <StyledSearchInput
            $isBaseline={!isGlobalHeaderFeature}
            id="origin"
            search={originSearch}
            keyboard={keyboard}
            aria-label={originLabel}
            placeholder={originPlaceholder}
            LeftElement={
              isGlobalHeaderFeature ? (
                <InputLabel size="lg">{originLabel}</InputLabel>
              ) : undefined
            }
          />
          <SwapButton
            $isBaseline={!isGlobalHeaderFeature}
            onClick={handleSwitch}
            aria-label={intl.formatMessage(messages.switchLabel)}
          >
            <Icon size="lg">
              <Switch
                title="switch"
                tint={theme.searchBar.switchButton.iconTint}
              />
            </Icon>
          </SwapButton>
          <StyledSearchInput
            $isBaseline={!isGlobalHeaderFeature}
            id="destination"
            search={destinationSearch}
            keyboard={keyboard}
            aria-label={destinationLabel}
            placeholder={destinationPlaceholder}
            LeftElement={
              isGlobalHeaderFeature ? (
                <InputLabel size="lg">{destinationLabel}</InputLabel>
              ) : undefined
            }
          />
        </InputBar>
        {isGlobalHeaderFeature && (
          <SearchButton
            size="large"
            onPress={handleSearch}
            isDisabled={
              origin?.canonicalName ===
                originSearch.selectedPlace?.canonicalName &&
              destination?.canonicalName ===
                destinationSearch.selectedPlace?.canonicalName
            }
          >
            {intl.formatMessage(messages.searchLabel)}
          </SearchButton>
        )}
      </InputBars>
    </FocusContext.Provider>
  );
}

const StyledSearchInput = styled(SearchInput)<{ $isBaseline: boolean }>`
  ${(props) =>
    props.$isBaseline &&
    css`
      box-sizing: border-box;
      flex: unset;
      width: 240px;
      height: 48px;
      background-color: ${color.bg.surface.active};
      border-radius: ${border_radius.rounded_md};
      border: 1px solid ${color.border.secondary};

      &:hover {
        background-color: ${color.input.bgSurfaceActive};
      }
    `}
`;

const InputLabel = styled(Label)`
  color: ${color.text.secondary};
`;

const InputBars = styled.div<{ $isBaseline: boolean }>`
  display: flex;
  gap: ${spacing.md};
  margin: ${spacing.lg} ${spacing.xxl};
  ${(props) =>
    props.$isBaseline &&
    css`
      margin: ${spacing.xs} ${spacing.xxl} ${spacing.md};
    `}
`;

const InputBar = styled.div<{ $isBaseline: boolean }>`
  display: flex;
  gap: ${spacing.sm};
  flex: 1;
  ${(props) =>
    props.$isBaseline &&
    css`
      gap: ${spacing.md};
    `}
`;

const SwapButton = styled.button<{ $isBaseline: boolean }>`
  ${BlockStyles}
  ${A11yOutline}
  justify-content: center;
  padding: ${spacing.md};
  ${(props) =>
    props.$isBaseline &&
    css`
      box-sizing: border-box;
      width: ${spacing.xxxxl};
      height: ${spacing.xxxxl};
      border-radius: ${border_radius.rounded_md};
      background-color: ${color.bg.surface.active};
      border: 1px solid ${color.border.secondary};
      &:hover {
        background-color: ${color.input.bgSurfaceActive};
      }
    `}
`;

const SearchButton = styled(Button)``;
