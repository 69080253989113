import type { PartialSearchResponse } from "src/domain/TripPlanner/util/getPartialSearchResponse";
import { getSingleMajorFlightSegment } from "src/utils/getSingleMajorFlightSegment";
import { createTripHashForCard } from "src/utils/location/createTripHashForCard";
import type { Mode } from "../../../../utils/types/mode";
import {
  type TripCardTypeProps,
  getTripCardAnalyticsLabel,
  getTripRouteTransitModes,
} from "../TripCard";
import type { SearchResponse } from "../../../../api/SearchResponse";
import { durationInMinutesFromRoute } from "../../../../utils/adapters/duration";
import { priceRangeFromRoute } from "../../../../utils/adapters/priceRange";
import type { TripPlannerURL } from "../../../../domain/TripPlanner/TripPlannerProvider";

export type RouteTripCardProps = {
  title?: string;
  majorModes?: Mode[];
} & TripCardTypeProps;

export function getRoutePropsFromSearchResponse(
  searchResponse: SearchResponse | PartialSearchResponse,
  routeIndex: number,
  url: TripPlannerURL
): RouteTripCardProps {
  const routeData = searchResponse.routes[routeIndex];
  const title = routeData.name;
  const transitModes = getTripRouteTransitModes(searchResponse, routeIndex);
  const primaryMode = getPrimaryTransitMode(transitModes);
  const durationInMinutes = durationInMinutesFromRoute(
    searchResponse,
    routeData
  );
  const isScheduleable =
    !!routeData.scheduleInfo ||
    getSingleMajorFlightSegment(searchResponse, routeIndex) !== undefined;

  return {
    origin: searchResponse.places[0],
    destination: searchResponse.places[1],
    title,
    searchResponse,
    durationInMinutes,
    priceRange: priceRangeFromRoute(routeData),
    majorModes: transitModes,
    transitMode: primaryMode,
    url: {
      ...url,
      hash: createTripHashForCard(searchResponse, url.hash),
    },
    type: "route",
    gaLabel: getTripCardAnalyticsLabel(false, searchResponse, routeIndex),
    isScheduleable,
  };
}

function getPrimaryTransitMode(transitModes: Mode[]) {
  return transitModes.length > 1 ? "multi" : transitModes[0];
}
