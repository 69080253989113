import { Suspense, useState } from "react";
import { useIntl } from "react-intl";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { ButtonBase } from "src/components/Button/ButtonBase";
import { DefaultErrorBoundary } from "src/components/DefaultErrorBoundary/DefaultErrorBoundary";
import { Dialog } from "src/components/Dialog/Dialog";
import { Icon } from "src/components/Icon/Icon";
import { border_radius } from "src/design-system/tokens/border";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";
import { MapsApiProvider } from "src/MapPane";
import { Close } from "src/svg/Close";
import { Expand } from "src/svg/tripplanner/Expand";
import { color, iconSize, spacing, zIndex } from "src/theme";
import useSearch from "src/utils/hooks/useSearch";
import styled from "styled-components";
import { MapLoadingPlaceholder } from "../MapLoadingPlaceholder";
import { LazyTripPlannerMap } from "./LazyTripPlannerMap";
import messages from "./TripPlannerMap.messages";
import { MapError } from "./util/MapError";

export function TripPlannerMobileMap() {
  const { searchResponse } = useSearch();
  const { loadingIndex } = useTripPlannerContext();
  const intl = useIntl();
  const [errorHidden, setErrorHidden] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const isLoading = !searchResponse || loadingIndex?.length;

  const MapContainer = isExpanded ? MapExpanded : MapContracted;

  function mapViewCloseCallback() {
    sendAnalyticsInteractionEvent({
      category: "TripPlannerMap",
      action: "Click:Close",
    });
    setIsExpanded(false);
  }

  function mapViewExpandCallback() {
    sendAnalyticsInteractionEvent({
      category: "TripPlanner",
      action: "Click:Map",
    });
    setIsExpanded(true);
  }

  return (
    <DefaultErrorBoundary
      ErrorFallback={
        !errorHidden
          ? () => <MapError hideCallback={() => setErrorHidden(true)} />
          : undefined
      }
    >
      <MapContainer
        isOpen={isExpanded}
        backdropClickCallback={mapViewCloseCallback}
        setOpen={mapViewExpandCallback}
      >
        <MapsApiProvider>
          <MapLoadingPlaceholder />
          {!isLoading && (
            <Suspense fallback={null}>
              {searchResponse && (
                <LazyTripPlannerMap searchResponse={searchResponse} />
              )}
              <ExpandContractButton
                expanded={isExpanded}
                onClick={isExpanded ? mapViewCloseCallback : () => {}}
              >
                <Icon size="md">
                  {isExpanded ? (
                    <Close
                      title={intl.formatMessage(messages.closeMobileMap)}
                      tint="n300"
                    />
                  ) : (
                    <Expand
                      title={intl.formatMessage(messages.expandMobileMap)}
                      tint="n300"
                    />
                  )}
                </Icon>
              </ExpandContractButton>
            </Suspense>
          )}
        </MapsApiProvider>
      </MapContainer>
    </DefaultErrorBoundary>
  );
}

type MapExpandedProps = {
  isOpen: boolean;
  children: React.ReactNode;
  backdropClickCallback: () => void;
};

function MapExpanded({
  isOpen,
  children,
  backdropClickCallback,
}: MapExpandedProps) {
  return (
    <Dialog
      isOpen={isOpen}
      onBackdropClicked={backdropClickCallback}
      variant="fullScreen"
      backgroundColor="white"
      overflow="hidden"
    >
      {children}
    </Dialog>
  );
}

type MapContractedProps = {
  children: React.ReactNode;
  setOpen: VoidFunction;
};

function MapContracted({ children, setOpen }: MapContractedProps) {
  return (
    <MapContainerContracted data-testid="map-contracted" onClick={setOpen}>
      {children}
    </MapContainerContracted>
  );
}

const ExpandContractButton = styled(ButtonBase)<{ expanded: boolean }>`
  position: absolute;
  bottom: ${spacing.md};
  right: ${spacing.md};
  z-index: ${zIndex.map};

  width: ${iconSize.xxxxl};
  height: ${iconSize.xxxxl};
  border-radius: 50%;
  background-color: ${color.white};
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.15));
  ${({ expanded }) => expanded && `top: ${spacing.xl}; bottom: auto;`};
`;

const MapContainerContracted = styled.div`
  position: relative;
  height: 140px;
  border-radius: ${border_radius.rounded_md};
  overflow: hidden;

  // Some safari versions won't render border-radius without this
  transform: translateZ(0);

  // We don't want the map to be interactive when it's contracted
  .gm-style {
    pointer-events: none;
  }
`;
