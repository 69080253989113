import { useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { Debug } from "src/svg/Debug";
import { Icon } from "src/components/Icon/Icon";
import { DebugSelectors } from "src/domain/DebugWindow/DebugSelectors";
import { useGetPermaLink } from "../../../utils/hooks/useGetPermaLink";
import { fontSize, spacing, fontWeight } from "../../../theme";
import { NavDropdown } from "../NavDropdown";
import { messages } from "./DebugDropdown.messages";

export function DebugDropdown() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const intl = useIntl();
  const permalink = useGetPermaLink();

  function handleClickButton() {
    setIsDropdownOpen(true);
  }

  return (
    <NavDropdown
      text={
        <Icon size="xxl">
          <Debug
            title={intl.formatMessage(messages.debugTitle)}
            tint={isDropdownOpen ? "pink" : "cod"}
          />
        </Icon>
      }
      anchor="right"
      isDropdownOpen={isDropdownOpen}
      handleClickButton={handleClickButton}
      handleClickOffDropdown={() => setIsDropdownOpen(false)}
      $padding={`${spacing.lg} 0`}
      hideChevron
    >
      <Text>
        <a href={permalink.href}>Current Feature Permalink</a>
      </Text>
      {isDropdownOpen && <DebugSelectors />}
    </NavDropdown>
  );
}

const Text = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${fontSize.h6};
  font-weight: ${fontWeight.normal};
  border-bottom: 1px solid #ebebeb;
`;
