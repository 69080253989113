import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { DefaultErrorBoundary } from "src/components/DefaultErrorBoundary/DefaultErrorBoundary";
import { DisplayAd } from "src/components/DisplayAd/DisplayAd";
import styled from "styled-components";
import color from "src/design-system/tokens/color";
import {
  sendAnalyticsNonInteractionEvent,
  sendAnalyticsInteractionEvent,
} from "src/analytics/sendAnalyticsEvent";
import {
  NON_COMMERCIAL_PROVIDER,
  useLogExitCallback,
} from "src/analytics/useLogExitCallback/useLogExitCallback";
import type { Mode } from "src/utils/types/mode";
import type { Trigger } from "src/analytics/generateExitPoint/generateExitPoint";
import { spacing } from "src/design-system/tokens/spacing";
import { border_radius } from "src/design-system/tokens/border";
import { CircularTimer } from "src/svg/CirularTimer";
import { mediumBreakpoint, useScreenSize } from "src/utils/hooks/useScreenSize";
import type { getTargeting } from "src/components/DisplayAd/targeting";
import { Typography } from "src/design-system/components/Typography/Typography";
import { color as themeColor } from "../../../../theme";
import messages from "./AdPageWithTimer.messages";

export const TIMER_COUNT = 15;

export function AdPageWithTimer() {
  const intl = useIntl();
  const isMobile = useScreenSize() === "small";

  const { transitMode, externalLink, trigger, agencyIds } = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const transitMode = urlParams.get("transitMode") ?? "";
    const trigger = urlParams.get("trigger") ?? "";
    const code = urlParams.get("code") ?? "";
    const agencyIds = code ? [code] : undefined;
    const externalLink = urlParams.get("link") ?? "";

    return {
      transitMode,
      externalLink,
      trigger,
      agencyIds,
    };
  }, []);

  const logTransitUrlExit = useLogExitCallback(
    "TransitAgency",
    "segment",
    "transitUrl",
    transitMode as Mode | undefined
  );

  const storedTargeting = useMemo(() => {
    const storedTargeting = localStorage.getItem("adPageTargeting");
    if (storedTargeting) {
      return JSON.parse(storedTargeting) as ReturnType<typeof getTargeting>;
    }
    return undefined;
  }, []);

  const [timeLeft, setTimeLeft] = useState(TIMER_COUNT);
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);
    if (timeLeft === 0) {
      clearInterval(timer);

      localStorage.removeItem("adPageTargeting");

      sendAnalyticsNonInteractionEvent({
        category: "Ads",
        action: "InterstitialTimerExpired",
      });
      logTransitUrlExit(
        externalLink,
        NON_COMMERCIAL_PROVIDER,
        agencyIds,
        trigger as Trigger | undefined
      );

      window.location.href = externalLink ?? "";
    }
    return () => clearInterval(timer);
  }, [agencyIds, externalLink, logTransitUrlExit, timeLeft, trigger]);

  return (
    <DefaultErrorBoundary>
      <Container>
        <AdWithTimer>
          <ContentWrapper>
            <Title variant="heading-lg">
              {intl.formatMessage(messages.heading)}
            </Title>
            <CircularTimer
              timeLeft={timeLeft}
              onClick={() => {
                sendAnalyticsInteractionEvent({
                  category: "Ads",
                  action: "Click:InterstitialTimer",
                });
              }}
            />
            <Typography variant="body-lg" color={color.text.primary.primary}>
              {intl.formatMessage(messages.redirectMessage)} &nbsp;
              <Typography
                as="a"
                variant="heading-sm"
                href={externalLink}
                onClick={() => {
                  sendAnalyticsInteractionEvent({
                    category: "Ads",
                    action: "Click:InterstitialLink",
                  });
                  logTransitUrlExit(
                    externalLink,
                    NON_COMMERCIAL_PROVIDER,
                    agencyIds,
                    trigger as Trigger | undefined
                  );
                }}
                color={color.text.text}
              >
                {isMobile
                  ? intl.formatMessage(messages.tapHere)
                  : intl.formatMessage(messages.clickLink)}
              </Typography>
            </Typography>
          </ContentWrapper>
          <AdWrapper>
            <DisplayAd
              slotConfigId="interstitialVideoAd"
              targetingData={storedTargeting}
            />
          </AdWrapper>
        </AdWithTimer>
      </Container>
    </DefaultErrorBoundary>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${color.bg.fill.fill};
  padding: 0;
  margin: 0;
  ${mediumBreakpoint} {
    background-color: ${themeColor.greyMap};
  }
`;

const AdWithTimer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${spacing.xxxxl};
  background-color: ${color.bg.fill.fill};
  border-radius: ${border_radius.rounded_lg};
  flex-direction: column;
  padding: ${spacing.xxxxl} ${spacing.xxl};
  ${mediumBreakpoint} {
    padding: ${spacing.xxxxl};
    flex-direction: row;
    max-width: 1200px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: ${spacing.xxl};
  ${mediumBreakpoint} {
    padding: 0px ${spacing.xxl};
  }
`;

const Title = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spacing.xxl} 0px ${spacing.md} 0px;
`;

const AdWrapper = styled.div`
  width: 400px;
  height: 300px;
  background-color: ${color.bg.fill.fill};
  flex-shrink: 0;
  ${mediumBreakpoint} {
    width: 640px;
    height: 480px;
  }
`;
