import { defineMessages } from "react-intl";

const messages = defineMessages({
  heading: {
    id: "adPageWithTimer.heading",
    description:
      "Text to display that page can be skipped in the following seconds",
    defaultMessage: "Skip this page in...",
  },
  redirectMessage: {
    id: "adPageWithTimer.redirectMessage",
    description:
      "Text display that tells the user will be redirected to the third party site",
    defaultMessage:
      "You'll be redirected in just a few seconds. If you aren’t redirected automatically,",
  },
  clickLink: {
    id: "adPageWithTimer.clickLink",
    description:
      "Text display to tell the user to click on the link if not redirected to third party site on desktop",
    defaultMessage: "click here",
  },
  tapHere: {
    id: "adPageWithTimer.tapHere",
    description:
      "Text display to tell the user to click on the link if not redirected to third party site on mobile",
    defaultMessage: "tap here",
  },
});

export default messages;
