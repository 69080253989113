import styled from "styled-components";
import { rgba } from "polished";
import { Fragment, useEffect } from "react";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { sendAnalyticsNonInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import {
  fontSize,
  fontWeight,
  spacing,
  textColor,
  lineHeight,
  notificationColor,
} from "../../../theme";
import { Info } from "../../../svg/Info";
import { Icon } from "../../../components/Icon/Icon";
import useSearch from "../../../utils/hooks/useSearch";

type NotificationLink = {
  url: string;
  text: string;
  linkType?: string;
};

type Notification = {
  level: number;
  message: string;
  generic?: boolean;
  links?: NotificationLink[];
};

export function SearchNotification(props: Notification) {
  const color = getNotificationColor(props.level);

  // Log that we showed a notification but only log once per search.
  const { searchResponse } = useSearch();
  const requestId = searchResponse?.request.requestId;

  useEffect(() => {
    if (requestId) {
      sendAnalyticsNonInteractionEvent({
        category: "SearchNotification",
        action: "Shown",
        label: `${props.level}|${props.message}`,
      });
    }
  }, [requestId, props.level, props.message]);

  function handleClick(url: string) {
    sendAnalyticsInteractionEvent({
      category: "SearchNotification",
      action: "Click:Link",
      label: `${props.level}|${url}`,
    });
  }

  return (
    <Root bg={color} data-testid="search-notification">
      <StyledIcon size="sm">
        <Info tint="black"></Info>
      </StyledIcon>
      <Text>
        {props.message}
        {props.links?.map((link, index) => {
          // Add space before a link and separate links with a bar.
          const separator = index === 0 ? " " : " | ";
          return (
            <Fragment key={index}>
              {separator}
              <Link
                href={link.url}
                target="_blank"
                rel="noreferrer noopener"
                onClick={() => handleClick(link.url)}
              >
                {link.text}
              </Link>
            </Fragment>
          );
        })}
      </Text>
    </Root>
  );
}

function getNotificationColor(level: number) {
  const colors = [
    notificationColor.low,
    notificationColor.medium,
    notificationColor.high,
  ];
  return colors[level] ?? "inherit";
}

const Root = styled.div<{ bg?: string }>`
  display: inline-block;
  background-color: ${(props) => (props.bg ? rgba(props.bg, 0.05) : null)};
  padding: ${spacing.md} ${spacing.lg};
  line-height: ${lineHeight.normal};
  font-size: ${fontSize.h6};
  width: 100%;
  display: flex;
`;

const StyledIcon = styled(Icon)`
  margin-top: ${spacing.sm};
`;

const Text = styled.div`
  padding-left: ${spacing.md};
`;

const Link = styled.a`
  color: ${textColor.primaryOnLight};
  font-weight: ${fontWeight.medium};
`;
