import type {
  ExperimentName,
  ExperimentVariant,
} from "../../../experiment/ExperimentDefinition";
import type { ExperimentConfig } from "../../../experiment/useExperimentConfig";
import type { FeatureConfig } from "../../FeatureConfig";

type FeatureExperimentConfig = {
  [E in ExperimentName]?: {
    [V in ExperimentVariant<E>]: Partial<FeatureConfig>;
  };
};

// experimentFeatureMap describes which features should be controlled by which
// experiments.
//
// The way it's structured means that multiple experiments can control the same
// feature. However, if you do define multiple experiments for the same feature
// the last one will overwrite any previous ones.
//
// Note that omitted an Experiment Variant will use the default variant from
// FeatureConfig.
const experimentFeatureMap: FeatureExperimentConfig = {
  HotelsCTAPortuguese: {
    None: { HExCTACopyPortuguese: "Baseline" },
    Baseline: { HExCTACopyPortuguese: "Baseline" },
    ViewOffer: { HExCTACopyPortuguese: "ViewOffer" },
    CheckOutTheHotel: { HExCTACopyPortuguese: "CheckOutTheHotel" },
    SeeTheHotel: { HExCTACopyPortuguese: "SeeTheHotel" },
    SeeMore: { HExCTACopyPortuguese: "SeeMore" },
    SeeDetails: { HExCTACopyPortuguese: "SeeDetails" },
    ViewOptions: { HExCTACopyPortuguese: "ViewOptions" },
  },
  HotelsCTAFrench: {
    None: { HExCTACopyFrench: "Baseline" },
    Baseline: { HExCTACopyFrench: "Baseline" },
    MoreInfo: { HExCTACopyFrench: "MoreInfo" },
    DisplayDeal: { HExCTACopyFrench: "DisplayDeal" },
    SeeHotel: { HExCTACopyFrench: "SeeHotel" },
    SeeStay: { HExCTACopyFrench: "SeeStay" },
    ViewNow: { HExCTACopyFrench: "ViewNow" },
    ViewTheOptions: { HExCTACopyFrench: "ViewTheOptions" },
  },
  HotelsCTASpanish: {
    None: { HExCTACopySpanish: "Baseline" },
    Baseline: { HExCTACopySpanish: "Baseline" },
    ViewDeal: { HExCTACopySpanish: "ViewDeal" },
    FindDeal: { HExCTACopySpanish: "FindDeal" },
    SeeHotel: { HExCTACopySpanish: "SeeHotel" },
    SeeDetails: { HExCTACopySpanish: "SeeDetails" },
    ViewNow: { HExCTACopySpanish: "ViewNow" },
  },
  HotelsCTAGerman: {
    None: { HExCTACopyGerman: "Baseline" },
    Baseline: { HExCTACopyGerman: "Baseline" },
    FindDeal: { HExCTACopyGerman: "FindDeal" },
    ViewMore: { HExCTACopyGerman: "ViewMore" },
    SeeDetails: { HExCTACopyGerman: "SeeDetails" },
    View: { HExCTACopyGerman: "View" },
  },
  RightRailBP: {
    None: { RightRailBP: false },
    Baseline: { RightRailBP: false },
    RightRailBP: { RightRailBP: true },
  },
  SchedulesSearchDefaultTomorrow: {
    None: { DefaultTomorrowSchedules: false },
    Baseline: { DefaultTomorrowSchedules: false },
    Enabled: { DefaultTomorrowSchedules: true },
  },
  InterstitialAdV2: {
    None: { InterstitialVideoAd: false },
    Baseline: { InterstitialVideoAd: false },
    VideoAd: { InterstitialVideoAd: true },
  },
  HybridSchedules: {
    None: { HybridSchedules: false },
    Baseline: { HybridSchedules: false },
    HybridSchedules: { HybridSchedules: true },
  },
  FlightConnections: {
    None: { SkipRoutes: "skip" },
    Baseline: { SkipRoutes: "skip" },
    NoSkip: { SkipRoutes: "noSkip" },
    Breadcrumbs: { SkipRoutes: "breadcrumbs" },
  },
  NoWaterfallAdsExploreV3: {
    None: { NoWaterfallAds: false },
    Baseline: { NoWaterfallAds: false },
    NoWaterfall: { NoWaterfallAds: true },
  },
  MapPromoDMOs: {
    None: { DmoMapPromos: false },
    Baseline: { DmoMapPromos: false },
    MapPromo: { DmoMapPromos: true },
  },
};

export function getFeatureConfigForExperimentConfig(
  experimentConfig?: ExperimentConfig
): Partial<FeatureConfig> {
  if (!experimentConfig) {
    return {};
  }
  let result: Partial<FeatureConfig> = {};
  const experimentNameList = Object.keys(
    experimentFeatureMap
  ) as ExperimentName[];
  for (let experimentName of experimentNameList) {
    const experimentPolicy = experimentFeatureMap[experimentName]!;
    // Get the experiment variant that has been set by the backend.
    const experimentVariant = experimentConfig[experimentName];

    // TypeScript is trying to protect us from indexing a value that doesn't exist
    // but it doesn't matter if we do because if we do it'll return undefined
    // anyway.
    const featureConfig = (experimentPolicy as any)[experimentVariant]!;

    if (featureConfig !== undefined) {
      result = { ...result, ...featureConfig };
    }
  }

  return result;
}
