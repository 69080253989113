import { ButtonBase } from "src/components/Button/ButtonBase";
import styled from "styled-components";
import color from "src/design-system/tokens/color";
import { spacing } from "src/design-system/tokens/spacing";
import { fontSize, screenMinWidth } from "src/theme";
import { useIntl } from "react-intl";
import type { AutocompletePlace } from "src/api/AutocompleteResponse";
import {
  type ForwardedRef,
  forwardRef,
  Fragment,
  useId,
  useRef,
  useState,
} from "react";
import Close from "src/svg/Close.svg?react";
import Marker from "src/svg/BrandDestination.svg?react";
import { AddDestinationTimelineButton } from "src/components/TripPlanner/AddDestinationTimelineButton/AddDestinationTimelineButton";
import { getSurroundingPlaces } from "src/utils/getSurroundingPlaces";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { FocusContext, type FocusedElement } from "src/FocusContext";
import { useScreenMinWidth } from "src/utils/hooks/useScreenMinWidth";
import { A11yOutline } from "src/utils/accessibility";
import { Skeleton } from "src/components/Skeleton/Skeleton";
import {
  useSearchInput,
  useSearchKeyboard,
} from "src/utils/hooks/useSearchInput";
import { SearchInput } from "src/components/SearchInput/SearchInput";
import { TripPlannerAutocomplete } from "../TripPlannerAutocomplete/TripPlannerAutocomplete";
import { DottedLine } from "../DottedLine/DottedLine";
import messages from "./PlaceInputList.messages";

const inputIconOffset = `10.5px`;
const inputIconWidth = `6px`;
const inputIconBorderWidth = `5.5px`;
function PlaceInputSkeleton() {
  return <Skeleton width="100%" height="48px" />;
}

type PlaceInputListProps = {
  places: string[];
  loadingIndex?: number;
  handleRemoveDestination: (index: number) => void;
  handleSelectPlace: (
    autocompletePlace: AutocompletePlace,
    index: number
  ) => void;
  isInline?: boolean;
};
export function PlaceInputList({
  places,
  handleSelectPlace,
  handleRemoveDestination,
  isInline,
  loadingIndex,
}: PlaceInputListProps) {
  const intl = useIntl();
  const listRef = useRef<HTMLUListElement>(null);
  const InputComponent = isInline ? Input : ButtonInput;
  const hasAtleast2Legs = places.filter((place) => !!place).length > 1;
  const { isDesktop } = useScreenMinWidth();

  function selectAndProceed(place: AutocompletePlace, index: number) {
    handleSelectPlace(place, index);
    const nextInput = listRef.current?.querySelectorAll("input")[index + 1];
    if (nextInput && !nextInput.value) {
      nextInput.focus();
    }
  }

  return (
    <WrapperDiv>
      <List ref={listRef}>
        {places.map((place, index) => {
          const placeHolder =
            index === 0
              ? intl.formatMessage(messages.startingFrom)
              : intl.formatMessage(messages.goingTo);
          return (
            // We want the key to stable be based on order,
            // so the inputs do not remount each time the place changed
            <Fragment key={index}>
              <InputComponent
                {...{
                  index,
                  place,
                  places,
                  placeHolder,
                  handleSelectPlace: selectAndProceed,
                  handleRemoveDestination,
                }}
              />
              {places.length - 1 !== index && !isDesktop ? <LinkDiv /> : null}
            </Fragment>
          );
        })}
        {loadingIndex === places.length && <PlaceInputSkeleton />}
        {!isDesktop && <DottedLine />}
        <AddDestinationButton
          handleAddDestination={selectAndProceed}
          isDisabled={!hasAtleast2Legs}
          places={places}
        />
      </List>
    </WrapperDiv>
  );
}

type InputProps = {
  index: number;
  place: string;
  places: string[];
  placeHolder: string;
  handleSelectPlace: (place: AutocompletePlace, index: number) => void;
  handleRemoveDestination: (index: number) => void;
};

function ButtonInput({
  index,
  handleSelectPlace,
  handleRemoveDestination,
  place,
  places,
  placeHolder,
}: Readonly<InputProps>) {
  const [isAutocompleteActive, setIsAutocompleteActive] = useState(false);
  const intl = useIntl();

  return (
    <>
      {isAutocompleteActive && (
        <TripPlannerAutocomplete
          onSelectOption={(place) => {
            handleSelectPlace(place, index);
            setIsAutocompleteActive(false);
          }}
          handleClose={() => setIsAutocompleteActive(false)}
          places={getSurroundingPlaces(index, places)}
        />
      )}
      <Item>
        <InputIcon type={getIconType(index, places)} isEmpty={!place} />
        <InputButton
          $isEmpty={!place}
          onClick={() => setIsAutocompleteActive(true)}
        >
          <InputSpan>{!!place ? place : placeHolder}</InputSpan>
        </InputButton>

        {handleRemoveDestination && (
          <RemoveButton
            onClick={() => handleRemoveDestination(index)}
            role="button"
            title={intl.formatMessage(messages.removePlace)}
          >
            <Close width={12} height={12} color={color.icon.secondary} />
          </RemoveButton>
        )}
      </Item>
    </>
  );
}

const Input = forwardRef(
  (
    {
      place,
      handleSelectPlace,
      index,
      places,
      placeHolder,
      handleRemoveDestination,
    }: InputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const id = useId();
    const intl = useIntl();
    const keyboard = useSearchKeyboard();
    const search = useSearchInput(
      {
        id,
        initialQuery: place,
        onSelect: onSelect,
      },
      keyboard
    );

    function onSelect(place: AutocompletePlace) {
      handleSelectPlace(place, index);
    }

    function onFocusChanged(focusedElement: FocusedElement) {
      const numResults = search.displayResults.length;
      keyboard.setFocusedElement(focusedElement, numResults);
    }

    return (
      <FocusContext.Provider
        value={{
          focusedElement: keyboard.focusedElement,
          onFocusChanged,
        }}
      >
        <StyledSearchInput
          id={id}
          search={search}
          keyboard={keyboard}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={
            (index === 0 && !search.displayValue) ||
            (!search.displayValue && !!places[0])
          }
          placeholder={placeHolder}
          LeftElement={
            <InputIcon
              type={getIconType(index, places)}
              isEmpty={!search.displayValue}
            />
          }
          RightElement={
            places.length > 2 ? (
              <RemoveButton
                onClick={() => handleRemoveDestination(index)}
                role="button"
                title={intl.formatMessage(messages.removePlace)}
              >
                <Close width={12} height={12} color={color.icon.secondary} />
              </RemoveButton>
            ) : undefined
          }
        />
      </FocusContext.Provider>
    );
  }
);
Input.displayName = "Input";

function getIconType(index: number, places: string[]) {
  if (index === 0 && places.length <= 2) {
    return "origin";
  }
  if (index === 1 && places.length <= 2) {
    return "destination";
  }
  return "place";
}

function InputIcon({
  type,
  isEmpty,
}: {
  type: "origin" | "destination" | "place";
  isEmpty?: boolean;
}) {
  switch (type) {
    case "origin":
      return <StarterDiv $isEmpty={isEmpty} />;
    case "destination":
      return <MarkerSvg $isEmpty={isEmpty} />;
    case "place":
      return <DotDiv />;
  }
}

function AddDestinationButton({
  handleAddDestination,
  isDisabled,
  places,
}: {
  handleAddDestination: (place: AutocompletePlace, index: number) => void;
  isDisabled: boolean;
  places: string[];
}) {
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isInputVisible, setIsInputVisible] = useState(false);
  const { isSmScreen } = useScreenMinWidth();
  const isInline = isSmScreen && isInputVisible;
  const isFullScreen = !isSmScreen && isInputVisible;

  return (
    <>
      {isFullScreen && (
        <TripPlannerAutocomplete
          handleClose={() => setIsInputVisible(false)}
          onSelectOption={(place) => {
            handleAddDestination(place, places.length);
            setIsInputVisible(false);
          }}
          places={[places[places.length - 1]]}
        />
      )}

      {isInline && (
        <Input
          place=""
          ref={inputRef}
          placeHolder={intl.formatMessage(messages.goingTo)}
          index={places.length}
          places={places}
          handleSelectPlace={(place, index) => {
            handleAddDestination(place, index);
            setIsInputVisible(false);
          }}
          handleRemoveDestination={() => {
            setIsInputVisible(false);
          }}
        />
      )}

      <AddDestinationTimelineButton
        isDisabled={isDisabled || isInputVisible}
        onClick={() => {
          sendAnalyticsInteractionEvent({
            category: "CreateTrip",
            action: "Click:AddDestination",
          });
          setIsInputVisible(true);
        }}
      />
    </>
  );
}

const StyledSearchInput = styled(SearchInput)`
  box-sizing: border-box;
  min-height: ${spacing.xxxxl};
  background-color: ${color.input.bgSurface};
  border: 1px solid ${color.primitive.neutral700};
  &:hover {
    background-color: ${color.input.bgSurfaceActive};
  }
`;

const WrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xs};
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xs};
`;

const Item = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${color.bg.surface.active};
  :first-child {
    border-radius: ${spacing.md} ${spacing.md} 0 0;
  }
  :last-child {
    border-radius: 0 0 ${spacing.md} ${spacing.md};
  }
`;

const DotDiv = styled.div`
  position: absolute;
  top: 50%;
  left: ${inputIconOffset};
  transform: translateY(-50%);
  margin-left: ${inputIconBorderWidth};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${color.icon.icon};
  ${screenMinWidth.sm} {
    position: unset;
    transform: unset;
  }
`;

const InputSpan = styled.span`
  text-align: left;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RemoveButton = styled(ButtonBase)`
  display: flex;
  padding: ${spacing.xl};
  position: absolute;
  right: ${spacing.md};
  top: 50%;
  transform: translateY(-50%);
  ${A11yOutline};
`;

const LinkDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 10px;
  margin: 0 ${spacing.xl};
  z-index: 1;
  ::before {
    position: absolute;
    content: "";
    height: 44px;
    border-left: 1px solid ${color.icon.secondary};
  }
`;

const StarterDiv = styled.div<{ $isEmpty?: boolean }>`
  position: absolute;
  top: 50%;
  left: ${inputIconOffset};
  transform: translateY(-50%);
  width: ${inputIconWidth};
  height: ${inputIconWidth};
  box-sizing: content-box;
  margin-left: 1.5px;
  border: ${inputIconBorderWidth} solid
    ${({ $isEmpty }) => ($isEmpty ? color.bg.brand.fill : color.icon.icon)};
  border-radius: 50%;
  z-index: 2;
  ${screenMinWidth.sm} {
    position: unset;
    transform: unset;
  }
`;

const MarkerSvg = styled(Marker)<{ $isEmpty?: boolean }>`
  position: absolute;
  top: 50%;
  left: ${inputIconOffset};
  transform: translateY(-50%);
  z-index: 1;
  path {
    fill: ${({ $isEmpty }) =>
      $isEmpty ? color.bg.brand.fill : color.icon.icon};
  }
  ${screenMinWidth.sm} {
    position: unset;
    transform: unset;
  }
`;

const InputButton = styled(ButtonBase)<{ $isEmpty?: boolean }>`
  display: flex;
  justify-content: flex-start;
  padding: ${spacing.xl} 0 ${spacing.xl}
    calc(${inputIconOffset} + ${inputIconWidth} + ${spacing.xxl});
  flex: 1;
  font-size: ${fontSize.h5};
  overflow: hidden;
  white-space: nowrap;
  color: ${({ $isEmpty }) =>
    $isEmpty ? color.icon.icon : color.text.primary.active};
`;
