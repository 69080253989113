import type { FeatureConfig } from "src/feature/FeatureConfig";

export type BackendFeatureNames = typeof BackendFeatureList;
export type BackendFeatureName = BackendFeatureNames[number];

export type BackendFeatureMap = {
  [key in BackendFeatureName]: Partial<FeatureConfig>;
};

// This is the list of features that are defined in the rome2rio-core backend.
// These features are *separate to the frontend features, but they may have the same names.
// Add new backend features here: https://github.com/rome2rio/rome2rio-core/blob/develop/rome2rio.common/Features/FeatureEnum.cs
// The features must be included in this array in order to show up in this list.
// https://github.com/rome2rio/rome2rio-core/blob/develop/rome2rio.common/Features/FeaturePolicy.cs#L197
//
// ***Important***
// Experiment configuration of features takes precedence over this mapping.
// So if an experiment is running and configured in ./experiment/getFeatureConfigForExperimentConfig.ts
// Any mapping here will NOT take effect.
// See precedence order here: https://github.com/rome2rio/rome2rio-explore/blob/6aa02e41dfb026a65595675c4ccf4dd2cf82effd/apps/explore/src/feature/internal/useConfigureFeatures.ts#L7
export const BackendFeatureList = ["ShowDebug"] as const;

// If the backend feature is enabled, set the frontend features here.
export const backendFeatureMapping: Partial<BackendFeatureMap> = {
  ShowDebug: {
    Debug: true,
  },
};
