import { registerOnConsent } from "@rome2rio/tcf-api";
import { sendAnalyticsNonInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import type { Mode } from "../../utils/types/mode";
import { getUidCookie } from "../../utils/uid";

type DataPartnerEventParams = {
  origin?: string;
  destination?: string;
  departureDate?: string; // ISO
  returnDate?: string; // ISO
  departureAirport?: string;
  destinationAirport?: string;
  isNonStopFlight?: boolean; // No flight transfer.
  transitMode?: Mode;
  language: string;
  currency?: string;
  price?: string;
  airlineCode?: string;
  oneWayTrip?: string;
  mobileAdId?: string;
  mobileAdType?: string;
  operator?: string;
  level: number;
};

let isRegistered = false;

// level 3 events denote Search & Schedule Searches
// level 4 events denote Ticket Selection & Live Schedule Exits
export const SEARCH_SCHEDULE_REQUEST = 3;
export const TICKET_SELECTION_LIVE_SCHEDULE_EXIT = 4;

const TRAVEL_AUDIENCE_CONSENT_PURPOSES_REQUIRED = [1, 3, 5];
const TRAVEL_AUDIENCE_LEGITIMATE_INTEREST_PURPOSES_REQUIRED: number[] = [];

const TRAVEL_AUDIENCE_SALE_CONSENT_REQUIRED = true;
const TRAVEL_AUDIENCE_SHARING_CONSENT_REQUIRED = true;

export function sendTravelAudienceEvent(params: DataPartnerEventParams) {
  // Map transitMode to Travel Audience's product parameter.
  let product: string | undefined;

  switch (params.transitMode) {
    case "bus":
      product = "8";
      break;
    case "train":
      product = "7";
      break;
    case "plane":
      product = "5";
      break;
    default:
      product = undefined;
  }

  const uidCookie = getUidCookie();

  if (!uidCookie) return;

  window._ttq = window._ttq || [];
  window._ttq.push(["_setUser", uidCookie]); // mandatory parameter
  window._ttq.push(["_setAccount", "RTR"]); // mandatory parameter
  window._ttq.push(["_setDataSource", "dp"]); // mandatory parameter
  window._ttq.push(["_setLevel", params.level.toString()]); // mandatory parameter
  window._ttq.push(["_setLanguage", params.language]); // mandatory parameter, current language (2 characters, ISO 639-1)
  window._ttq.push(["_setAdults", "1"]); // Number of adults (persons in general)
  window._ttq.push(["_setExternalId", uidCookie]); // To be able to receive Rome2Rio's first party cookies, an additional parameter needs to be included to the existing pixel.

  // Travel Audience doesn't accept undefined values so check we have a value before pushing.
  if (product) {
    window._ttq.push(["_setProduct", product]); // value determined by transitMode
  }
  if (params?.departureAirport) {
    window._ttq.push(["_setDepartureAirport", params.departureAirport]); // Users departure airport. IATA 3 letter code.
  }
  if (params?.destinationAirport) {
    window._ttq.push(["_setDestinationAirport", params.destinationAirport]); // Users destination airport. IATA 3 letter code.
  }
  if (params?.origin) {
    window._ttq.push(["_setOrigin", params.origin]); // Departure city
  }
  if (params?.destination) {
    window._ttq.push(["_setPlace", params.destination]); // Destination searched for
  }
  if (params?.price) {
    window._ttq.push(["_setPrice", params.price]); // Free text, containing basket value
  }
  if (params?.currency) {
    window._ttq.push(["_setCurrency", params.currency]); // Currency for the given price (ISO 4217). Mandatory for non-EUR price.
  }
  if (params?.departureDate) {
    window._ttq.push(["_setDateFrom", params.departureDate]); // Users earliest departure date in yyyy-MM-dd
  }
  if (params?.returnDate) {
    window._ttq.push(["_setDateTo", params.returnDate]); // Users latest arrival date/return date in yyyy-MM-dd
  }
  if (params?.airlineCode) {
    window._ttq.push(["_setAirlineCode", params.airlineCode]); // IATA airline code with 2 alphanumeric characters (letters or digits)
  }
  if (params?.oneWayTrip) {
    window._ttq.push(["_setOneWay", params.oneWayTrip]); // One way trip filter. 0 = false, 1 = true
  }
  if (params?.mobileAdId) {
    window._ttq.push(["_setMai", params.mobileAdId]); // Freetext, containing mobile advertiser id
  }
  if (params?.mobileAdType) {
    window._ttq.push(["_setMat", params.mobileAdType]); // Freetext, containing mobile advertiser type
  }
  if (params?.operator) {
    window._ttq.push(["_setOperator", params.operator]); // (Tour) operator filter.
  }

  window._ttq.push(["_track"]);

  if (!isRegistered) {
    registerOnConsent({
      onGiven: () => {
        initialiseTravelAudience(params.level);
      },
      onRevoked: () => {
        removeTravelAudience();
      },
      gdpr: {
        consentPurposesRequired: TRAVEL_AUDIENCE_CONSENT_PURPOSES_REQUIRED,
        legitimateInterestPurposesRequired:
          TRAVEL_AUDIENCE_LEGITIMATE_INTEREST_PURPOSES_REQUIRED,
      },
      us: {
        saleConsentRequired: TRAVEL_AUDIENCE_SALE_CONSENT_REQUIRED,
        sharingConsentRequired: TRAVEL_AUDIENCE_SHARING_CONSENT_REQUIRED,
      },
    });
    isRegistered = true;
  } else {
    sendAnalyticsNonInteractionEvent({
      category: "InterestData",
      action: "TriggerIFrame",
      label: `TravelAudience:${params.level}`,
    });
  }
}

function initialiseTravelAudience(level: number) {
  const ttr = document.createElement("script");

  ttr.type = "text/javascript";
  ttr.src =
    ("https:" === document.location.protocol ? "https://" : "http://") +
    "ads.travelaudience.com/js/ta.js";

  document.body.appendChild(ttr);

  sendAnalyticsNonInteractionEvent({
    category: "InterestData",
    action: "LoadIFrame",
    label: `TravelAudience:${level}`,
  });
}

function removeTravelAudience() {
  // Only way to stop the running script is to reload the page
  window.location.reload();
}
