import { useMutation } from "@tanstack/react-query";
import {
  type Dispatch,
  type PropsWithChildren,
  useEffect,
  useReducer,
} from "react";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import type { SortableObject } from "src/components/DragAndDropList/DraggableItem";
import { useReorderingPlaces } from "src/components/Map/TripPlannerMap/util/useReorderingPlaces";
import { useTripHoveredPlace } from "src/components/Map/TripPlannerMap/util/useTripHoveredPlace";
import { useGetTripRoutes } from "src/utils/hooks/useGetTripRoutes";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import useUser from "src/utils/hooks/useUser";
import type { GeocodedPlace } from "../../PrefetchData";
import type { SchedulesResponse } from "../../api/SchedulesResponse";
import type { SearchResponse } from "../../api/SearchResponse";
import { destinationPlaceFromSearch } from "../../utils/adapters/place";
import useAccountTrip from "./hooks/useAccountTrip";
import { useInitialTripFromURL } from "./hooks/useInitialTripFromURL";
import { useInteractionMade } from "./hooks/useInteractionMade";
import { usePostTripPlan } from "./hooks/usePostTripPlan";
import { usePutTripPlan } from "./hooks/usePutTripPlan";
import {
  MAX_SEARCH_PARAM_PLACES,
  useSwitchURLToTripId,
} from "./hooks/useSwitchURLToTripId";
import { useTripDestination } from "./hooks/useTripDestination";
import { TripPlannerContext } from "./hooks/useTripPlannerContext";
import { useTripPlanningState } from "./hooks/useTripPlanningState";
import useUpdatePathForTrip from "./hooks/useUpdatePathForTrip";
import { adjustTripPlannerDetailsForNearby } from "./util/adjustTripPlannerDetailsForNearby";
import type { ApiState, TripPlanWithID } from "./util/api";
import { createTransportKey } from "./util/createTransportKey";
import {
  type PartialSearchResponse,
  getPartialSearchResponse,
} from "./util/getPartialSearchResponse";
import { getPlacePairs } from "./util/getPlacePairs";
import { getPlaceTransportKey } from "./util/getPlaceTransportKey";
import { removeDuplicateConsequential } from "./util/removeDuplicateConsequential";
import { removeTransportWithoutPlaces } from "./util/removeTransportWithoutPlaces";
import { transportKeyHasPlacePair } from "./util/transportKeyHasPlacePair";

export const OVERWRITE_MESSAGE_TIMEOUT = 4000;

export type Action =
  | PassiveUpdateAction
  | SaveAction
  | AddDestinationAction
  | RemoveTransportAction
  | RemovePlaceAction
  | RemoveIDAction
  | ReorderTripAction
  | EditDestinationAction
  | CreateTripFromSearchAction
  | ReplaceTripFromSearchAction
  | EditStartDateAction
  | ClearAction
  | SetTripAction;

type PassiveUpdateAction = {
  type: "PASSIVE_UPDATE";
  trip: TripPlannerDetails;
};

type SaveAction = {
  type: "SAVE_SEARCH";
  searchResponse: SearchResponse;
  url: TripPlannerURL;
  routeIndex?: number;
};

type AddDestinationAction = {
  type: "ADD_DESTINATION";
  destination: GeocodedPlace;
};

type RemoveTransportAction = {
  type: "REMOVE_TRANSPORT";
  originDestinationKey: TripPlannerTransportKey;
};

type RemovePlaceAction = {
  type: "REMOVE_PLACE";
  index: number;
};

type RemoveIDAction = {
  type: "REMOVE_ID";
};

type ClearAction = {
  type: "CLEAR";
};

type ReorderTripAction = {
  type: "REORDER_TRIP";
  newOrder: SortableObject<GeocodedPlace>[];
};

type EditDestinationAction = {
  type: "EDIT_DESTINATION";
  index: number;
  newPlace: GeocodedPlace;
};

type CreateTripFromSearchAction = {
  type: "CREATE_TRIP_FROM_SEARCH_TRIP";
  places: GeocodedPlace[];
};

type ReplaceTripFromSearchAction = {
  type: "REPLACE_TRIP_FROM_SEARCH_TRIP";
  places: GeocodedPlace[];
};

type EditStartDateAction = {
  type: "EDIT_START_DATE";
  date: Date | undefined;
};

type SetTripAction = {
  type: "SET_TRIP";
  trip: TripPlannerDetails;
};

export type TripPlannerURL = {
  pathname: string;
  hash: string | undefined;
};

export type TripPlannerCardType = "search" | "route";

export type TripPlannerEntryType = {
  type: TripPlannerCardType;
  url: TripPlannerURL;
  searchResponse: SearchResponse | PartialSearchResponse;
  scheduleResponse?: SchedulesResponse;
  selectedRouteIndex?: number;
};

export type PlaceIdentifier = GeocodedPlace["canonicalName"];
export type TripPlannerTransportKey = `${PlaceIdentifier}_${PlaceIdentifier}`;

export type TripPlannerDetails = {
  id?: string;
  slug?: string;
  name?: string;
  places: GeocodedPlace[];
  startDate?: string;
  transport: {
    [key: TripPlannerTransportKey]: TripPlannerEntryType | undefined;
  };
  accomodation?: {
    [key: PlaceIdentifier]:
      | {
          type: "suggestion" | "booked" | "saved";
          data: any; // AccomodationCardViewModel
        }[];
  };
  attraction?: {
    [key: PlaceIdentifier]:
      | {
          type: "suggestion" | "saved";
          data: any; // AttractionCardViewModel
        }[];
  };
};

function useRemoteTripPlan(dispatch: Dispatch<Action>, isEnabled = true) {
  const { addTripFromURL, hasUsedSearch, isFromSearchParam } =
    useInitialTripFromURL(dispatch);
  const shouldCreateTrip = isFromSearchParam && isEnabled;

  useEffect(() => {
    if (shouldCreateTrip) {
      addTripFromURL?.();
    }
  }, [addTripFromURL, shouldCreateTrip]);

  return {
    isLoadingRemote: shouldCreateTrip && !hasUsedSearch && isEnabled,
  };
}

export function TripPlannerProvider(props: PropsWithChildren<{}>) {
  const { user } = useUser();
  const sendToRemote = usePutTripPlan();
  const initRemoteTripPlan = usePostTripPlan();
  const { switchToTripId } = useSwitchURLToTripId();
  const { setHoveredPlaceIndex, hoveredPlaceIndex } = useTripHoveredPlace();
  const { reorderingPlaces, setReorderingPlaces } = useReorderingPlaces();
  const tripPlanningState = useTripPlanningState();
  const tripInteraction = useInteractionMade();
  const { updateTripPath, updatedPathRef } = useUpdatePathForTrip();

  const location = useTypedLocation();
  const isTripHash = location.hash.includes("trips");

  const [tripPlannerDetails, dispatch] = useReducer(
    tripPlannerActionReducer,
    { transport: {}, places: [] },
    () => {
      return { transport: {}, places: [] };
    }
  );

  const mutate = useMutation({
    mutationKey: ["tripPlan"],
    mutationFn: async (tripPlannerDetails: TripPlannerDetails) => {
      if (!isTripHash) return;
      if (tripPlannerDetails.id) {
        return await sendToRemote(tripPlannerDetails as TripPlanWithID, !!user);
      }
      const isTransportSelected = Object.keys(
        tripPlannerDetails.transport
      ).length;
      if (
        tripPlannerDetails.places.length > MAX_SEARCH_PARAM_PLACES ||
        user?.id ||
        isTransportSelected
      ) {
        return await initRemoteTripPlan(tripPlannerDetails, !!user);
      }
    },
    onMutate: (tripPlannerDetails: TripPlannerDetails) => {
      if (!isTripHash) return;
      updateTripPath(tripPlannerDetails);
      const eventLabel = tripPlannerDetails.places
        .map((place) => place.canonicalName)
        .join("|");
      sendAnalyticsInteractionEvent({
        category: "TripPlanner",
        action: "TripPlanUpdated",
        label: eventLabel,
      });
    },
    onSuccess: (data) => {
      if (!data) {
        return;
      }
      if (data.slug) {
        switchToTripId({
          tripId: data.slug,
          pathname: updatedPathRef.current,
          tripPlannerDetails: data,
        });
      }
      if (tripPlannerDetails.id === data.id) {
        dispatch({ type: "PASSIVE_UPDATE", trip: data });
      } else {
        dispatch({ type: "SET_TRIP", trip: data });
      }
    },
  });

  const tripRoutes = useGetTripRoutes(getPlacePairs(tripPlannerDetails));

  const tripDestination = useTripDestination({
    tripPlannerDetails: tripPlannerDetails,
    dispatch: dispatchAndSendToRemote,
    tripPlanningState,
  });

  const updatedTripPlannerDetails = adjustTripPlannerDetailsForNearby(
    tripRoutes.queries,
    tripPlannerDetails
  );

  // Browser forward and back button navigation syncing
  useEffect(() => {
    if (location.state?.tripPlannerDetails) {
      dispatch({
        type: "PASSIVE_UPDATE",
        trip: location.state?.tripPlannerDetails,
      });
    }
  }, [location]);

  async function dispatchAndSendToRemote(action: Action) {
    dispatch(action);
    let newTripPlannerDetails = tripPlannerActionReducer(
      tripPlannerDetails,
      action
    );
    await mutate.mutateAsync(newTripPlannerDetails);
  }

  // New trips created through the trip create screen will populate the initial tripPlannerDetails,
  // bypassing the need to fetch details about a trip plan remotely
  const isTripDetailsAlreadyFilled = !!tripPlannerDetails.places.length;

  const { isLoadingRemote } = useRemoteTripPlan(
    dispatch,
    !isTripDetailsAlreadyFilled
  );
  useAccountTrip(dispatchAndSendToRemote, tripPlannerDetails, user?.id);

  return (
    <TripPlannerContext.Provider
      value={{
        tripRoutes,
        tripPlannerDetails: updatedTripPlannerDetails,
        dispatch: dispatchAndSendToRemote,
        hoveredPlaceIndex,
        setHoveredPlaceIndex,
        tripPlanningState,
        apiState: {
          fetchState: (isLoadingRemote
            ? "fetching"
            : "fetched") as ApiState["fetchState"],
        },
        reorderingPlaces,
        setReorderingPlaces,
        tripInteraction,
        tripDestination,
      }}
      {...props}
    />
  );
}

export function tripPlannerActionReducer(
  state: TripPlannerDetails,
  action: Action
): TripPlannerDetails {
  let result: TripPlannerDetails;

  switch (action.type) {
    case "PASSIVE_UPDATE":
    case "SET_TRIP":
      result = action.trip;
      break;
    case "SAVE_SEARCH":
      result = reduceSaveSearchAction(state, action);
      break;
    case "ADD_DESTINATION":
      result = reduceAddDestinationAction(state, action);
      break;
    case "REMOVE_TRANSPORT":
      result = reduceRemoveTransportAction(state, action);
      break;
    case "REMOVE_PLACE":
      result = reduceRemovePlaceAction(state, action);
      break;
    case "REORDER_TRIP":
      result = reorderTripAction(state, action);
      break;
    case "EDIT_DESTINATION":
      result = editDestinationAction(state, action);
      break;
    case "REMOVE_ID":
      result = reduceRemoveIDAction(state, action);
      break;
    case "CREATE_TRIP_FROM_SEARCH_TRIP":
      result = reduceCreateTripFromSearchAction(state, action);
      break;
    case "REPLACE_TRIP_FROM_SEARCH_TRIP":
      result = reduceReplaceTripFromSearchAction(state, action);
      break;
    case "EDIT_START_DATE":
      result = reduceEditStartDateAction(state, action);
      break;
    case "CLEAR":
      result = {
        places: [],
        transport: {},
      };
      break;
  }

  return result;
}

function reduceSaveSearchAction(
  state: TripPlannerDetails,
  action: SaveAction
): TripPlannerDetails {
  // First two places in search response **always** have a canonical name
  const origin = action.searchResponse.places[0].canonicalName as string;
  const destination = action.searchResponse.places[1].canonicalName as string;

  const transportKey = createTransportKey(origin, destination);

  const newPlaces = addPlaces(action, state);

  const selectedRouteIndex = action.routeIndex;

  return {
    ...state,
    places: newPlaces,
    transport: {
      ...state.transport,
      [transportKey]: {
        type: "search",
        url: action.url,
        searchResponse: getPartialSearchResponse(
          action.searchResponse,
          selectedRouteIndex ?? 0
        ),
        selectedRouteIndex: selectedRouteIndex,
      },
    },
  };
}

function reduceAddDestinationAction(
  state: TripPlannerDetails,
  action: AddDestinationAction
): TripPlannerDetails {
  let newPlaces: GeocodedPlace[] = [...state.places];

  // Append our new destination
  newPlaces.push(action.destination);

  return {
    ...state,
    places: newPlaces,
  };
}

export function reduceRemoveTransportAction(
  state: TripPlannerDetails,
  action: RemoveTransportAction
) {
  let newTransport: TripPlannerDetails["transport"] = { ...state.transport };

  if (newTransport.hasOwnProperty(action.originDestinationKey)) {
    delete newTransport[action.originDestinationKey];
  }

  return {
    ...state,
    transport: newTransport,
  };
}

function reduceRemovePlaceAction(
  state: TripPlannerDetails,
  action: RemovePlaceAction
): TripPlannerDetails {
  let newPlaces: TripPlannerDetails["places"] = [...state.places];

  // If there's only one place, remove it
  if (newPlaces.length < 2) {
    newPlaces = [];
  }

  newPlaces = deletePlacePair(newPlaces, action.index);
  const safePlaces = removeDuplicateConsequential(newPlaces, "canonicalName");

  const newTransport = removeTransportWithoutPlaces(
    state.transport,
    safePlaces
  );

  return {
    ...state,
    places: safePlaces,
    transport: newTransport,
  };
}

export function reorderTripAction(
  state: TripPlannerDetails,
  action: ReorderTripAction
): TripPlannerDetails {
  // If there are no places
  // we shouldn't keep the transport
  if (action.newOrder.length < 1) {
    return {
      ...state,
      places: [],
      transport: {},
    };
  }

  // remove duplicate following places
  const newOrder = removeDuplicateConsequential(
    action.newOrder,
    "canonicalName"
  );

  // remove id and content properties from places
  const newPlaces: GeocodedPlace[] = newOrder.map((place) => {
    const { id, content, ...rest } = place;
    return {
      ...rest,
    };
  });

  const newTransport = removeTransportWithoutPlaces(state.transport, newPlaces);

  return {
    ...state,
    places: newPlaces,
    transport: newTransport,
  };
}

export function reduceCreateTripFromSearchAction(
  state: TripPlannerDetails,
  action: CreateTripFromSearchAction
): TripPlannerDetails {
  // If there are no places
  // we shouldn't keep the transport
  if (action.places.length < 1) {
    return {
      ...state,
      places: [],
      transport: {},
    };
  }

  // remove duplicate following places
  const newPlaces = removeDuplicateConsequential(
    [...action.places],
    "canonicalName"
  );

  return {
    ...state,
    // Removing the ID ensures a new trip plan is initialized on the server.
    id: undefined,
    places: newPlaces,
  };
}

export function reduceReplaceTripFromSearchAction(
  state: TripPlannerDetails,
  action: ReplaceTripFromSearchAction
): TripPlannerDetails {
  // If there are no places
  // we shouldn't keep the transport
  if (action.places.length < 1) {
    return {
      ...state,
      places: [],
      transport: {},
    };
  }

  // remove duplicate following places
  const newPlaces = removeDuplicateConsequential(
    [...action.places],
    "canonicalName"
  );

  return {
    ...state,
    places: newPlaces,
  };
}

function reduceRemoveIDAction(
  state: TripPlannerDetails,
  action: RemoveIDAction
): TripPlannerDetails {
  return {
    ...state,
    id: undefined,
  };
}

function reduceEditStartDateAction(
  state: TripPlannerDetails,
  action: EditStartDateAction
) {
  return {
    ...state,
    startDate: action.date ? action.date.toISOString() : undefined,
  };
}

function editDestinationAction(
  state: TripPlannerDetails,
  action: EditDestinationAction
): TripPlannerDetails {
  const { index, newPlace } = action;

  const newPlaces = [...state.places];
  newPlaces[index] = newPlace as GeocodedPlace;

  // Remove duplicate following places
  const safePlaces = removeDuplicateConsequential(newPlaces, "canonicalName");
  const newTransport = removeTransportWithoutPlaces(
    state.transport,
    safePlaces
  );

  return {
    ...state,
    places: safePlaces,
    transport: newTransport,
  };
}

function addPlaces(action: SaveAction, state: TripPlannerDetails) {
  let newPlaces: GeocodedPlace[] = [...state.places];

  if (!getActionNeedsPlaces(action, state)) return newPlaces;

  if (isOriginSaved(state.places, action.searchResponse)) {
    // Add just the destination
    const destination = destinationPlaceFromSearch(action.searchResponse);
    newPlaces.push(destination as GeocodedPlace);
  } else {
    // Add both the origin and destination
    newPlaces.push(action.searchResponse.places[0] as GeocodedPlace);
    newPlaces.push(action.searchResponse.places[1] as GeocodedPlace);
  }

  return newPlaces;
}

function getActionNeedsPlaces(action: SaveAction, state: TripPlannerDetails) {
  const transportKey = getPlaceTransportKey(
    0,
    action.searchResponse.places as GeocodedPlace[]
  );

  if (transportKey) {
    return (
      !transportKeyHasPlacePair(state.places, transportKey) &&
      !state.transport.hasOwnProperty(transportKey)
    );
  }

  return true;
}

function isOriginSaved(
  places: GeocodedPlace[],
  searchResponse: SearchResponse
) {
  return (
    places.length > 0 &&
    places[places.length - 1]?.canonicalName ===
      searchResponse.places[0].canonicalName
  );
}

export function userOverwritten(
  tripPlannerDetails: TripPlannerDetails,
  canonicalPair: TripPlannerTransportKey
) {
  let hasUserOverwritten = false;

  if (tripPlannerDetails.transport?.hasOwnProperty(canonicalPair)) {
    hasUserOverwritten = true;
  }

  return hasUserOverwritten;
}

function deletePlacePair(
  places: TripPlannerDetails["places"],
  index: number
): GeocodedPlace[] {
  if (places.length === 0) return [];
  const newPlaces = [...places];
  newPlaces.splice(index, 1);
  return newPlaces;
}
