import type { Mode } from "src/utils/types/mode";
import styled from "styled-components";
import type { PriceRange } from "src/utils/adapters/priceRange";
import { type IntlShape, useIntl } from "react-intl";
import { FormattedPrice } from "src/components/FormattedPrice/FormattedPrice";
import { ChevronRight } from "src/svg/ChevronRight";
import { Icon } from "src/components/Icon/Icon";
import { FormattedDuration } from "src/components/FormattedDuration/FormattedDuration";
import { spacing } from "src/design-system/tokens/spacing";
import color, { primitive } from "src/design-system/tokens/color";
import {
  font_line_height,
  font_size,
  font_weight,
} from "src/design-system/tokens/typography";
import { border_radius } from "src/design-system/tokens/border";
import { largeDesktopLayout } from "src/utils/hooks/useLayout";
import { type Kind, transitKinds } from "src/api/Kind";
import { getSegmentHeadingMessage } from "src/domain/SegmentScreen/getSegmentHeadingMessage";
import { getModeMessage } from "src/domain/SegmentScreen/DynamicSchedules/Schedule/getModeMessage";
import { type PropsWithChildren, useState } from "react";
import Spotlight from "src/components/Spotlight/Spotlight";
import { Button } from "src/design-system/components/Button/Button";
import { ClickAwayListener } from "src/components/ClickAwayListener/ClickAwayListener";
import Cookies from "js-cookie";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { messages } from "../TripCard.messages";
import { TripCardModes } from "../TripCardModes";
import recommendedRouteMessages from "./SimpleRouteCard.messages";
import SimpleRouteCardLoading from "./SimpleRouteCardLoading";

type Props = {
  majorModes?: Mode[];
  durationInMinutes?: number;
  priceRange?: PriceRange;
  onboarding?: boolean;
  clickCallback?: () => void;
  isPendingDragChanges?: boolean;
  index?: number;
};

const CookieName = "tripTransportOnboardingDismissed";

export default function SimpleRouteCard({
  majorModes = [],
  priceRange,
  durationInMinutes,
  onboarding,
  clickCallback,
  isPendingDragChanges,
  index,
}: Props) {
  const intl = useIntl();

  if (isPendingDragChanges) {
    return <SimpleRouteCardLoading />;
  }

  const topThreeModes = majorModes.slice(0, 3);
  const title = getTransitTitle(majorModes, intl);

  return (
    <OnboardingSpotlight isEnabled={!!onboarding}>
      <Container onClick={clickCallback} id={`simple-route-card-${index}`}>
        <LHSContainer>
          <LHSRow>
            <TripCardModes separator={false} majorModes={topThreeModes} />
            <Title>{title}</Title>
          </LHSRow>
          {durationInMinutes && (
            <Duration>
              <FormattedDuration totalMinutes={durationInMinutes} />
            </Duration>
          )}
          {priceRange && (
            <>
              {durationInMinutes && <DotSeparator />}
              <PriceText>
                {intl.formatMessage(messages.fromPrice, {
                  price: (
                    <span>
                      <FormattedPrice
                        value={priceRange.low}
                        currency={priceRange.currencyCode}
                      />
                    </span>
                  ),
                })}
              </PriceText>
            </>
          )}
        </LHSContainer>

        <Icon size="sm">
          <ChevronRight tint="pink" />
        </Icon>
      </Container>
    </OnboardingSpotlight>
  );
}

function OnboardingSpotlight({
  children,
  isEnabled,
}: PropsWithChildren<{
  isEnabled: boolean;
}>) {
  const intl = useIntl();
  const [dismissedOnboarding, setDismissedOnboarding] = useState(() => {
    return !!Cookies.get(CookieName);
  });

  function closeOnboarding() {
    Cookies.set(CookieName, "true", { expires: 365 });
    setDismissedOnboarding(true);
  }

  return !isEnabled || dismissedOnboarding ? (
    <>{children}</>
  ) : (
    <ClickAwayListener
      onClickAway={() => {
        closeOnboarding();
        sendAnalyticsInteractionEvent({
          category: "TripPlanner",
          action: "Click:OutsideTransportOnboarding",
        });
      }}
    >
      <Spotlight
        trackPosition
        DialogContent={() => (
          <DialogContent>
            <h3>
              {intl.formatMessage(recommendedRouteMessages.onboardingDesc)}
            </h3>
            <Button
              onPress={() => {
                closeOnboarding();
                sendAnalyticsInteractionEvent({
                  category: "TripPlanner",
                  action: "Click:DismissTransportOnboarding",
                });
              }}
            >
              {intl.formatMessage(recommendedRouteMessages.onboardingDismiss)}
            </Button>
          </DialogContent>
        )}
      >
        {children}
      </Spotlight>
    </ClickAwayListener>
  );
}

function getTransitTitle(majorModes: Mode[], intl: IntlShape) {
  const mode = majorModes[0];

  const uniqueModes = [...new Set(majorModes)];

  if (!mode || uniqueModes.length > 1) {
    return intl.formatMessage(messages.multiModal);
  }

  // If we can, use the segment heading message.
  // If not, we can default to the "Mode" message.
  // For example, a "plane" mode would preferably be called "Fly" instead of "Plane" in this context.
  if (transitKinds.includes(mode as Kind)) {
    return intl.formatMessage(getSegmentHeadingMessage(mode as Kind));
  } else {
    return intl.formatMessage(getModeMessage(mode));
  }
}

const borderRadius = border_radius.rounded_lg;

const DotSeparator = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin: 0 ${spacing.md};
  width: 4px;
  height: 4px;
  background-color: ${color.icon.secondary};
  border-radius: ${border_radius.rounded_full};
`;

const Title = styled.h3`
  font-size: inherit;
  margin-left: ${spacing.xl};
  line-height: ${font_line_height.leading_normal};
`;

const Duration = styled.span`
  margin-left: ${spacing.sm};
`;

const PriceText = styled.span`
  margin-left: auto;
  margin-right: ${spacing.sm};
  font-weight: ${font_weight.medium};
  color: ${color.text.secondary};
  > span {
    color: ${primitive.product_500};
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: ${spacing.lg};
  font-size: ${font_size.text_base};
  border: 1px solid ${primitive.neutral_900};
  border-radius: ${borderRadius};
  cursor: pointer;
  background-color: ${color.bg.fill.fill};
  transition: background-color 0.2s ease;

  &:hover,
  &:focus,
  &:active {
    background-color: ${color.bg.surface.primaryHover};
    border: 1px solid ${primitive.neutral_1100};
  }
`;

const LHSContainer = styled.div`
  margin-right: auto;
  ${Duration} {
    margin-left: 0;
    color: ${color.text.secondary};
  }
  ${PriceText} {
    font-weight: ${font_weight.normal};
    > span {
      color: ${color.text.secondary};
    }
  }
`;

const LHSRow = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${spacing.sm};
  margin-bottom: ${spacing.xs};

  ${largeDesktopLayout} {
    margin-bottom: 0;
  }
`;

const DialogContent = styled.div`
  padding: ${spacing.xxl};
  border-radius: ${border_radius.rounded_md};
  background-color: white;
  color: ${primitive.neutral_1500};

  width: 256px;
  max-width: 100%;

  h3 {
    max-width: 20ch;
    font-size: ${font_size.text_lg};
    margin-bottom: ${spacing.xl};
  }
`;
