import Chicago from "./images/Chicago.webp";
import Florence from "./images/Florence.webp";
import Greenville from "./images/Greenville.webp";
import Lyon from "./images/Lyon.webp";
import Marseille from "./images/Marseille.webp";
import Milan from "./images/Milan.webp";
import Nice from "./images/Nice.webp";
import Rome from "./images/Rome.webp";
import SanJose from "./images/San-Jose.webp";

export type PromoItem = {
  promoText: string;
  destinationCanonical: string;
  imgSrc: string;
};

export const dmoPromos: Readonly<{
  [key: string]: PromoItem[];
}> = {
  US: [
    {
      promoText: "Experience Greenville",
      destinationCanonical: "Greenville-SC-USA",
      imgSrc: Greenville,
    },
    {
      promoText: "Explore Chicago",
      destinationCanonical: "Chicago",
      imgSrc: Chicago,
    },
    {
      promoText: "Explore San Jose",
      destinationCanonical: "San-Jose",
      imgSrc: SanJose,
    },
  ],
  IT: [
    {
      promoText: "Experience Rome",
      destinationCanonical: "Rome",
      imgSrc: Rome,
    },
    {
      promoText: "Explore Milan",
      destinationCanonical: "Milan",
      imgSrc: Milan,
    },
    {
      promoText: "Explore Florence",
      destinationCanonical: "Florence-Italy",
      imgSrc: Florence,
    },
  ],
  FR: [
    {
      promoText: "Experience Nice",
      destinationCanonical: "Nice",
      imgSrc: Nice,
    },
    {
      promoText: "Explore Lyon",
      destinationCanonical: "Lyon",
      imgSrc: Lyon,
    },
    {
      promoText: "Explore Marseille",
      destinationCanonical: "Marseille",
      imgSrc: Marseille,
    },
  ],
};
