import styled from "styled-components";
import Cookies from "js-cookie";
import { Button } from "src/components/Button/Button";
import { getUidCookie } from "src/utils/uid";
import { getApiHost } from "src/api/ApiConfigProvider";
import { assignUidEndpoint } from "src/api/endpoints";

export function AssignUidButton() {
  const uid = getUidCookie();

  const handleAssignUid = async () => {
    const endpoint = getApiHost();
    const data = await (await fetch(assignUidEndpoint(endpoint))).json();
    const uid = data.uid;
    if (uid) {
      Cookies.set("uid", uid);
    }
    window.location.reload();
  };

  return (
    <>
      <StyledButton
        textColor="primaryOnDark"
        backgroundColor="pink"
        size="small"
        inline
        disabled={uid !== undefined}
        onClick={handleAssignUid}
      >
        Assign UID
      </StyledButton>
      <Text>{uid ?? "No UID set"}</Text>
    </>
  );
}

const StyledButton = styled(Button)`
  margin: 16px 0px 0px 16px;
`;

const Text = styled.span`
  margin: 16px;
  display: inline;
  // Limit Character Length
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
