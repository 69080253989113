import { getApiHost } from "./ApiConfigProvider";

var initialCwvRequestId: string | undefined = undefined;

export function setCwvRequestId(requestId: string | undefined) {
  initialCwvRequestId = requestId;
}

let queuedVitals: {
  name: string;
  id: string;
  eventValue: number;
}[] = [];

function logCoreWebVitalsImpl() {
  // We can't use ApiConfig/ApiConfigProvider here because this function isn't
  // called in the React tree.
  if (queuedVitals.length === 0) return;

  const url = `${getApiHost()}/api/json/LogCoreWebVitals`;

  const bodyObject = { payload: queuedVitals, requestId: initialCwvRequestId };
  queuedVitals = [];

  try {
    navigator.sendBeacon(
      url,
      new Blob([JSON.stringify(bodyObject)], { type: "application/json" })
    );
  } catch {
    // We're deliberately leaving this Promise hanging, because there is no way
    // for us to cleanly handle it.
    fetch(url, { method: "POST", body: JSON.stringify(bodyObject) });
  }
}

export function queueCoreWebVital(name: string, id: string, value: number) {
  queuedVitals.push({ name, id, eventValue: value });
}

export function flushCoreWebVitalsQueue() {
  logCoreWebVitalsImpl();
}
