import { useState } from "react";
import { appendNavigationEventId } from "../../analytics/appendNavigationEventId/appendNavigationEventId";
import { generateNavigationEventId } from "../../analytics/generateNavigationEventId/generateNavigationEventId";

export function useTrackedUrl(
  baseTicketUrl: string
): [string, () => [string, string]] {
  const [eventId, setEventId] = useState<string | null>(null);

  const updatedUrl = eventId
    ? appendNavigationEventId(baseTicketUrl, eventId)
    : baseTicketUrl;

  const enableTracking = (): [string, string] => {
    const newEventId = generateNavigationEventId();
    setEventId(newEventId);
    const newUrl = appendNavigationEventId(baseTicketUrl, newEventId);
    return [newEventId, newUrl];
  };

  return [updatedUrl, enableTracking];
}
