import { dateInUtcOffset } from "./dateTime";

export function getDefaultDateTime(
  originUtcOffset: number,
  defaultTomorrow: boolean
): Date {
  const offsetDateTimeNow = dateInUtcOffset(new Date(), originUtcOffset);

  // Get time of origin location at the following day at 6am from now.
  const offsetDateTimeTomorrowMorning = new Date(offsetDateTimeNow);
  offsetDateTimeTomorrowMorning.setDate(
    offsetDateTimeTomorrowMorning.getDate() + 1
  );
  offsetDateTimeTomorrowMorning.setHours(6, 0, 0, 0);

  const useTomorrow = defaultTomorrow || offsetDateTimeNow.getHours() >= 16;

  // Reset fallback time to 00:00:00 to preserve cache
  offsetDateTimeNow.setHours(0, 0, 0, 0);

  const initialDateTime = useTomorrow
    ? offsetDateTimeTomorrowMorning
    : offsetDateTimeNow;

  return initialDateTime;
}
