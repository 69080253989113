import { searchResultViewSegments } from "src/domain/SearchResultsScreen/createSearchResultViewModel";
import { destAirportNameFromPlace } from "src/utils/adapters/place";
import { getMajorFlightSegmentIndex } from "src/utils/getSingleMajorFlightSegment";
import type { Mode } from "src/utils/types/mode";
import type { SearchResponse } from "../../api/SearchResponse";
import type { FeatureConfig } from "../../feature/FeatureConfig";
import {
  extractUidChannel,
  extractUidProduct,
  getAqidCookie,
  isAdWordsAcquisition,
} from "../../utils/uid";

export type Targeting = Record<string, string | string[]>;

type GetTargetingParams = {
  searchResponse: SearchResponse;
  adFillStrategy: FeatureConfig["FillAds"];
  tier?: string;
  refreshCount?: number;
};

export function getTargeting({
  searchResponse,
  adFillStrategy,
  tier,
  refreshCount,
}: GetTargetingParams) {
  const targeting: Targeting = {
    ...getTestTargeting(adFillStrategy),
    ...getAcquisitionTargeting(),
    ...getSearchTargeting(searchResponse),
    ...getTravelModesTargeting(searchResponse),
    ...getPlacesTargeting(searchResponse),
    ...getRefreshCountTargeting(refreshCount),
    ...(tier ? { tier } : {}),
  };

  return targeting;
}

function getTestTargeting(adFillStrategy: FeatureConfig["FillAds"]): {
  zTestEnabled?: string;
  zTestFill?: string;
  zTestAltSizes?: string;
} {
  if (adFillStrategy === "alwaysWithTestAds") {
    // This targeting makes us show test ads instead of making a real ads request.
    return {
      zTestEnabled: "true",
      zTestFill: "Always",
      zTestAltSizes: "true",
    };
  } else if (adFillStrategy === "never") {
    // This targeting makes us never fill an ad request.
    return {
      zTestEnabled: "true",
      zTestFill: "Never",
    };
  } else {
    return {};
  }
}

// Acquisition targeting is done so that we can attribute ad revenue
// to acquisitions.
function getAcquisitionTargeting() {
  const targeting: Record<string, string> = {};

  const aqidCookie = getAqidCookie();
  const channel = extractUidChannel(aqidCookie);
  const productCode = extractUidProduct(aqidCookie);

  if (channel) {
    targeting["acqchannel"] = channel;
  }

  if (productCode) {
    targeting["acqproduct"] = productCode;
  }

  if (isAdWordsAcquisition(aqidCookie) && productCode) {
    targeting["paidacqproduct"] = productCode;
  }

  return targeting;
}

function getSearchTargeting(searchResponse: SearchResponse) {
  const targeting = searchResponse.adsConfig?.custom_targeting ?? {};

  return Object.entries(targeting).reduce(
    (accumulator: Targeting, [key, value]) => {
      // The underlying Google js api accepts either strings or string arrays.
      // We could pass arrays every time but it logs things better when you pass
      // single strings, improving the developer experience immensely.
      if (value && value.length === 1) {
        accumulator[key] = value[0];
      } else if (value) {
        accumulator[key] = value;
      }
      return accumulator;
    },
    {}
  );
}

function getTravelModesTargeting(searchResponse: SearchResponse) {
  const travelModesSet = new Set<Mode>();
  searchResponse.routes.forEach((_, index) => {
    const segments = searchResultViewSegments(searchResponse, index);
    segments.forEach((segment) => {
      if (segment.transitMode && segment.isMajor) {
        travelModesSet.add(segment.transitMode);
      }
    });
  });
  return { travelModes: Array.from(travelModesSet) };
}

function getPlacesTargeting(searchResponse: SearchResponse) {
  const routes = searchResponse.routes;
  const routesWithMajorFlightSegment = routes.filter((_, index) => {
    const majorFlightSegmentIndex = getMajorFlightSegmentIndex(
      searchResponse,
      index
    );
    return majorFlightSegmentIndex !== undefined;
  });

  if (routesWithMajorFlightSegment.length === 0) {
    return;
  }

  const firstRoute = routesWithMajorFlightSegment[0];
  const firstRoutePlacesIndices = firstRoute.places;

  const airportPlaceIndices = firstRoutePlacesIndices.flatMap((place) => {
    return searchResponse.places[place].kind === "airport" ? place : [];
  });

  if (airportPlaceIndices.length < 2) {
    return;
  }

  const flightOriginCode = searchResponse.places[airportPlaceIndices[0]].code;
  const flightDestCode = searchResponse.places[airportPlaceIndices[1]].code;
  const destAirportName = destAirportNameFromPlace(
    searchResponse,
    flightDestCode
  );

  return {
    origin_airportcode: flightOriginCode,
    dest_airportcode: flightDestCode,
    ...(destAirportName !== undefined && {
      leg1DestAirportName: destAirportName,
    }),
  };
}

function getRefreshCountTargeting(refreshCount?: number): Targeting {
  return refreshCount !== undefined ? { refresh_count: `${refreshCount}` } : {};
}
