import { defineMessages } from "react-intl";

export const messages = defineMessages({
  showMoreResults: {
    id: "tripPlanner.tripCard.showMoreResults",
    description: "Button label for showing more search results",
    defaultMessage: "Show {count} more options",
  },
  showLessResults: {
    id: "tripPlanner.tripCard.showLessResults",
    description: "Button label for showing less search results",
    defaultMessage: "Show fewer options",
  },
});
