import { useIntl } from "react-intl";
import { ButtonBase } from "src/components/Button/ButtonBase";
import { DrawerCloseButton } from "src/components/Drawer/Drawer";
import { Icon } from "src/components/Icon/Icon";
import { NavigationBreadcrumbs } from "src/domain/NavigationBreadcrumbs/NavigationBreadcrumbs";
import type { SegmentBreadcrumbProps } from "src/domain/PaneNav/PaneNav";
import { type LayoutType, useLayout } from "src/utils/hooks/useLayout";
import styled, { css } from "styled-components";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { ArrowBackward } from "../../../svg/ArrowBackward";
import { color, fontSize, fontWeight, spacing } from "../../../theme";
import { useClosePane } from "../hooks/useClosePane";
import messages from "./TripNavbar.messages";

type TripNavbarVariant = "default" | "fullScreen";

type Props = {
  isSaved?: boolean;
  onBackClick?: () => void;
  onTripSaveClick?: () => void;
  isLoading?: boolean;
  isSaveableScreen?: boolean;
  headingText?: string;
  variant?: TripNavbarVariant;
  showSearchBreadcrumb?: boolean;
  showRouteBreadcrumb?: boolean;
  showSummaryBreadcrumb?: boolean;
  showDestinationBreadcrumb?: boolean;
  segment?: SegmentBreadcrumbProps;
  routeCanonical?: string;
  hoverBackButton?: boolean;
};

export const TRIP_NAVBAR_HEIGHT = 72;

export function TripNavbar(props: Props) {
  const layout = useLayout();
  const intl = useIntl();

  const { closePane } = useClosePane();

  const handleDrawerClose = () => {
    closePane();
    sendAnalyticsInteractionEvent({
      category: "TripPlannerTopNav",
      action: "Click:Close",
    });
  };

  return (
    <Header variant={props.variant}>
      <LHSWrapper>
        {props.onBackClick && (
          <BackButton
            layout={layout}
            aria-label={intl.formatMessage(messages.backLabel)}
            onClick={handleClick(props.onBackClick)}
            isLoading={props.isLoading}
            themeColor="black"
            $extendToBackdrop={layout === "desktop"}
          >
            <Icon size="lg">
              <ArrowBackward tint="black" />
            </Icon>
          </BackButton>
        )}
      </LHSWrapper>

      {props.variant === "fullScreen" && (
        <BreadCrumbWrapper>
          <NavigationBreadcrumbs
            showDestinationBreadcrumb={props.showDestinationBreadcrumb}
            showSearchBreadcrumb={props.showSearchBreadcrumb}
            showRouteBreadcrumb={props.showRouteBreadcrumb}
            routeCanonical={props.routeCanonical}
            segment={props.segment}
            showTripSummaryBreadcrumb={props.showSummaryBreadcrumb}
            isTripNavBar={true}
          />
        </BreadCrumbWrapper>
      )}

      {(props.variant === "default" || props.variant === undefined) && (
        <>
          <HeadingText data-testid="search-heading">
            {props.headingText}
          </HeadingText>
          <RHSWrapper>
            <DrawerCloseButton size="md" onClose={handleDrawerClose} />
          </RHSWrapper>
        </>
      )}
    </Header>
  );
}

function handleClick(callback: () => void) {
  return () => {
    sendAnalyticsInteractionEvent({
      category: "TripPlannerTopNav",
      action: "Click:BackButton",
    });
    callback();
  };
}

export const headerHeight = `${TRIP_NAVBAR_HEIGHT}px`;
const headerDefaultDialogStyles = css`
  justify-content: space-between;
  background-color: ${(props) => props.theme.navbar.background};
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.05);
`;
const headerFullScreenDialogStyles = css`
  background-color: ${color.white};
  position: relative;
`;

const Header = styled.header<{ variant?: TripNavbarVariant }>`
  height: ${headerHeight};
  text-align: center;
  line-height: ${headerHeight};
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 50;
  color: ${(props) => props.theme.navbar.text};
  display: flex;

  ${(props) => {
    if (props.variant === "default" || props.variant === undefined) {
      return headerDefaultDialogStyles;
    } else if (props.variant === "fullScreen") {
      return headerFullScreenDialogStyles;
    }
  }};
`;

const BreadCrumbWrapper = styled.div`
  flex: 1;
  text-align: left;
`;

const LHSWrapper = styled.div`
  display: flex;
  align-self: center;
`;

const RHSWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${spacing.md};

  & > :last-child {
    margin-left: ${spacing.md};
  }
`;

const HeadingText = styled.h1`
  font-size: ${fontSize.h6};
  font-weight: ${fontWeight.bold};
  padding: 0 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: ${headerHeight};
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const BackButton = styled(ButtonBase)<{
  $extendToBackdrop?: boolean;
  isLoading?: boolean;
  layout?: LayoutType;
  themeColor: string;
}>`
  margin: 0 ${spacing.xl};
  position: relative;

  ${(props) => {
    // This ensures the backdrop triggers the hover state of the back button.
    if (props.$extendToBackdrop) {
      return css`
        &::after {
          content: "";
          position: fixed;
          right: 100%;
          display: block;
          height: 100vh;
          width: 200px;
          z-index: 1001;
        }
      `;
    }
  }}

  /* This adds a semi-transparent circle around the back button on hover */
  &:hover:before {
    content: "";
    border-radius: 50%;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // This ensures the circle sits behind the icon.
    z-index: -1;
    background-color: ${(props) => props.theme.navbar.iconHoverBackground};
  }

  &:focus-visible {
    outline-style: solid;
    outline-width: 2px;
    outline-color: ${(props) => {
      if (props.themeColor === "cod") {
        return `${color.black}`;
      } else {
        return `${color.white}`;
      }
    }};
    outline-offset: 5px;
    border-radius: 50%;
  }
`;
