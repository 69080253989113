import { AssignUidButton } from "./AssignUidButton";
import { BackendExperimentSelector } from "./BackendExperimentSelector";
import { BackendFeatureSelector } from "./BackendFeatureSelector";
import { BackendFlagSelector } from "./BackendFlagSelector";
import { FrontendFeatureSelector } from "./FrontendFeatureSelector";
import MockFlagSelector from "./RequestMockFlagSelector";

export function DebugSelectors() {
  return (
    <>
      <FrontendFeatureSelector />
      <BackendFeatureSelector />
      <BackendFlagSelector />
      <BackendExperimentSelector />
      <MockFlagSelector />
      <AssignUidButton />
    </>
  );
}
