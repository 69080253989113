type ExperimentDefinition = {
  experimentId: string;
  variants: string[];
};

// Maps experiments to their ids and variant definitions as defined in the backend.
// The order of the variants matters and should match the order on the backend.
//
// See:
// * https://github.com/rome2rio/rome2rio-core/blob/develop/rome2rio.common/Experiments/ExperimentName.cs:
// * https://github.com/rome2rio/rome2rio-core/blob/develop/rome2rio.common/Experiments/ExperimentDefinitions.cs
export const experimentDefinitions = {
  LPINPDiagnosis: {
    experimentId: "hl",
    variants: ["Baseline", "NoCMP", "NoAds", "NoDataSharing"],
  },
  HotelsCTAPortuguese: {
    experimentId: "ht",
    variants: [
      "Baseline",
      "ViewOffer",
      "CheckOutTheHotel",
      "SeeTheHotel",
      "SeeMore",
      "SeeDetails",
      "ViewOptions",
    ],
  },
  HotelsCTAFrench: {
    experimentId: "hu",
    variants: [
      "Baseline",
      "MoreInfo",
      "DisplayDeal",
      "SeeHotel",
      "SeeStay",
      "ViewNow",
      "ViewTheOptions",
    ],
  },
  HotelsCTASpanish: {
    experimentId: "hv",
    variants: [
      "Baseline",
      "ViewDeal",
      "FindDeal",
      "SeeHotel",
      "SeeDetails",
      "ViewNow",
    ],
  },
  HotelsCTAGerman: {
    experimentId: "hw",
    variants: ["Baseline", "FindDeal", "ViewMore", "SeeDetails", "View"],
  },
  RightRailBP: {
    experimentId: "ie",
    variants: ["Baseline", "RightRailBP"],
  },
  SchedulesSearchDefaultTomorrow: {
    experimentId: "jb",
    variants: ["Baseline", "Enabled"],
  },
  InterstitialAdV2: {
    experimentId: "ja",
    variants: ["Baseline", "VideoAd", "Baseline", "VideoAd"],
  },
  HybridSchedules: {
    experimentId: "jf",
    variants: ["Baseline", "HybridSchedules"],
  },
  FlightConnections: {
    experimentId: "jg",
    variants: ["Baseline", "NoSkip", "Breadcrumbs"],
  },
  NoWaterfallAdsExploreV3: {
    experimentId: "jj",
    variants: ["Baseline", "NoWaterfall"],
  },
  MapPromoDMOs: {
    experimentId: "jk",
    variants: ["Baseline", "MapPromo"],
  },
} as const satisfies Record<string, ExperimentDefinition>;

export type ExperimentName = keyof typeof experimentDefinitions;

export type ExperimentVariant<T extends ExperimentName> =
  | (typeof experimentDefinitions)[T]["variants"][number]
  | "None";

// Given an experiment id it returns the human-readable string name for it or
// undefined if the experiment hasn't been added to experimentDefinitions above.
export function getExperimentNameFromId(
  experimentId: string
): ExperimentName | undefined {
  return experimentIdMap[experimentId];
}

// Given an experiment name and variant id it returns the human-readable string
// name for it or undefined if the experiment hasn't been added to
// `experimentDefinitions` above.
export function getExperimentVariantFromExperiment<T extends ExperimentName>(
  experiment: T,
  variantId: number
): ExperimentVariant<T> | undefined {
  const variantDefinition = experimentDefinitions[experiment]?.variants;
  if (!variantDefinition) {
    return undefined;
  }

  if (variantId === 0) {
    return "None";
  } else {
    return variantDefinition[variantId - 1];
  }
}

// Create a map of Experiment ids -> Experiment names once so we can efficiently
// get the ExperimentName for each id.
const experimentNames = Object.keys(experimentDefinitions) as ExperimentName[];
const experimentIdMap: {
  [id: string]: ExperimentName | undefined;
} = experimentNames.reduce((previousValue, currentValue) => {
  const experimentId = experimentDefinitions[currentValue].experimentId;
  return {
    ...previousValue,
    [experimentId]: currentValue,
  };
}, {});
