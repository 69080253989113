import { useEffect, useState } from "react";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";

export enum CcpaStatus {
  /** Something failed, in an unknown state. */
  UNKNOWN = 0,
  /** CCPA does not apply to this user. */
  CCPA_DOES_NOT_APPLY = 1,
  /** CPPA applies to this user, and the user has not opted out yet. */
  NOT_OPTED_OUT = 2,
  /** CPPA applies to this user, and the user has opted out. */
  OPTED_OUT = 3,
}

function useCcpaManagement(): [CcpaStatus, () => void] {
  const [ccpaStatus, setCcpaStatus] = useState<CcpaStatus>(CcpaStatus.UNKNOWN);

  useEffect(() => {
    window.googlefc?.callbackQueue?.push({
      INITIAL_CCPA_DATA_READY: () => {
        setCcpaStatus(googlefc.ccpa?.getInitialCcpaStatus());
      },
    });
  }, []);

  function handleCcpaLinkClick() {
    const ccpaCompletionCallback = (userOptedOut: boolean) => {
      if (userOptedOut) {
        sendAnalyticsInteractionEvent({
          category: "UsConsentModal",
          action: "OptOut",
        });
      }
    };
    window.googlefc?.ccpa.openConfirmationDialog?.(ccpaCompletionCallback);
  }

  return [ccpaStatus, handleCcpaLinkClick];
}

export default useCcpaManagement;
