import { useIntl } from "react-intl";
import { ButtonBase } from "src/components/Button/ButtonBase";
import { useState } from "react";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { ConfirmationModal } from "src/components/ConfirmationModal/ConfirmationModal";
import type { SortableObject } from "src/components/DragAndDropList/DraggableItem";
import tripPlannerMessages from "src/domain/TripPlanner/TripPlannerScreen.messages";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import styled, { css } from "styled-components";
import type { PlaceName } from "src/utils/useCreateTripFromSearch";
import { Button } from "../../../components/Button/Button";
import { Icon } from "../../../components/Icon/Icon";
import { Close } from "../../../svg/Close";
import { Rome2rioLogo } from "../../../svg/Rome2rioLogo";
import {
  color,
  fontSize,
  fontWeight,
  lineHeight,
  spacing,
} from "../../../theme";
import { localeToLanguageCode } from "../../../utils/conversions/languageCode";
import { useTheme } from "../../../utils/hooks/useTheme";
import { getHomepageUrl } from "../../../utils/url";
import { SearchBox } from "../SearchBox/SearchBox";
import messages from "./SearchEdit.messages";

type Props = {
  origin?: string;
  destination?: string;
  onCloseClick: () => void;
  onOriginClick: () => void;
  onDestinationClick: () => void;
  onSwitchClick: () => void;
  onSearchClick: () => void;
  places?: PlaceName[];
  onPlaceClick?: (index: number) => void;
  onAddDestinationClick?: () => void;
  setNewOrder?: (newOrder: SortableObject<{ index: number }>[]) => void;
};

export function SearchEdit(props: Props) {
  const intl = useIntl();
  const theme = useTheme();
  const homepageUrl = getHomepageUrl(localeToLanguageCode(intl.locale));
  const isCreatingTrip = props.places && props.places.length > 2;

  return (
    <StyledSearchEdit>
      <LogoContainer href={homepageUrl} addDestinationInSearch>
        <Rome2rioLogo
          title="logo"
          tint={theme.searchBar.iconTint}
          dotTint="pink"
        />
      </LogoContainer>
      <CloseButton onClick={props.onCloseClick}>
        <StyledIcon size="sm">
          <Close
            title={intl.formatMessage(messages.close)}
            tint={theme.searchBar.iconTint}
          />
        </StyledIcon>
      </CloseButton>
      <SearchBox
        origin={props.origin}
        destination={props.destination}
        onOriginClick={props.onOriginClick}
        onDestinationClick={props.onDestinationClick}
        onSwitchClick={props.onSwitchClick}
      />
      <SearchButtonContainer addDestinationInSearch>
        <SearchButton
          isCreatingTrip={isCreatingTrip}
          onSearchClick={props.onSearchClick}
          places={props.places}
        />
      </SearchButtonContainer>
    </StyledSearchEdit>
  );
}

type SearchButtonProps = {
  isCreatingTrip?: boolean;
  places?: PlaceName[];
  onSearchClick: () => void;
};
function SearchButton({
  isCreatingTrip,
  onSearchClick,
  places: newPlaces,
}: SearchButtonProps) {
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);
  const { tripPlannerDetails } = useTripPlannerContext();

  if (!isCreatingTrip) {
    return (
      <StyledButton
        backgroundColor="pink"
        textColor="primaryOnDark"
        size="large"
        leftIcon={undefined}
        onClick={onSearchClick}
        addDestinationInSearch
      >
        {intl.formatMessage(messages.search)}
      </StyledButton>
    );
  }

  const currentPlaces = tripPlannerDetails.places;
  const currentCanonicalPlaces = currentPlaces.map(
    (place) => place.canonicalName
  );

  const newPlaceCanonicals = newPlaces?.map((place) => place.canonicalName);

  const currentTripContainsNewPlaces = newPlaceCanonicals
    ?.join("")
    .includes(currentCanonicalPlaces.join(""));

  if (currentTripContainsNewPlaces) {
    return (
      <StyledButton
        data-testid="create-trip"
        backgroundColor="pink"
        textColor="primaryOnDark"
        size="large"
        leftIcon={undefined}
        onClick={() => {
          sendAnalyticsInteractionEvent({
            category: "SearchEdit",
            action: "Click:CreateNewTrip",
            label: "save",
          });
          onSearchClick();
        }}
        addDestinationInSearch
      >
        {intl.formatMessage(messages.createTrip)}
      </StyledButton>
    );
  }

  return (
    <>
      <StyledButton
        data-testid="create-trip"
        backgroundColor="pink"
        textColor="primaryOnDark"
        size="large"
        leftIcon={undefined}
        onClick={() => {
          sendAnalyticsInteractionEvent({
            category: "SearchEdit",
            action: "Click:CreateNewTrip",
            label: "needConfirmation",
          });
          setShowModal(true);
        }}
        addDestinationInSearch
      >
        {intl.formatMessage(messages.createTrip)}
      </StyledButton>
      <ConfirmationModal
        showModal={showModal}
        title={intl.formatMessage(messages.confirmationModalTitle)}
        description={intl.formatMessage(messages.confirmationModalDescription, {
          link: <MyTripTextLink />,
        })}
        confirmButtonText={intl.formatMessage(
          messages.confirmationModalConfirmText
        )}
        cancelButtonText={intl.formatMessage(
          messages.confirmationModalCancelText
        )}
        onConfirm={() => {
          sendAnalyticsInteractionEvent({
            category: "CreateTripConfirmation",
            action: "Confirm:CreateNewTrip",
          });
          onSearchClick();
        }}
        onCloseModal={() => {
          sendAnalyticsInteractionEvent({
            category: "CreateTripConfirmation",
            action: "Cancel:CreateNewTrip",
          });
          setShowModal(false);
        }}
      />
    </>
  );
}

function MyTripTextLink() {
  const intl = useIntl();
  const location = useTypedLocation();

  return (
    <TextLink
      href={`${location.pathname}${location.search}#trips`}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => {
        sendAnalyticsInteractionEvent({
          category: "CreateTripConfirmation",
          action: "Click:MyTrip",
        });
      }}
    >
      {intl.formatMessage(tripPlannerMessages.myTrip)}
    </TextLink>
  );
}

const StyledSearchEdit = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.searchBar.background};
  padding: ${spacing.md};
`;

const LogoContainer = styled.a<{ addDestinationInSearch: boolean }>`
  display: block;
  width: 160px;
  margin: auto;
  margin-top: ${spacing.md};
  ${({ addDestinationInSearch }) =>
    addDestinationInSearch &&
    css`
      margin-left: ${spacing.md};
      margin-bottom: ${spacing.xxl};
      & svg {
        height: 24px;
        width: auto;
      }
    `};
`;

const CloseButton = styled(ButtonBase)`
  position: absolute;
  top: 0;
  right: 0;
  padding: ${spacing.md};
  padding-top: ${spacing.xl};

  svg {
    fill: ${(props) => props.theme.searchBar.iconTint};
    stroke: ${(props) => props.theme.searchBar.iconTint};
  }
  &:focus-visible {
    outline: 3px solid ${color.pink};
    outline-offset: -2px;
  }
`;

const SearchButtonContainer = styled.div<{ addDestinationInSearch: boolean }>`
  padding: ${spacing.md};
  ${({ addDestinationInSearch }) =>
    addDestinationInSearch &&
    css`
      padding-top: ${spacing.xxxl};
    `};
`;

const StyledIcon = styled(Icon)`
  margin: 0 ${spacing.md};
  vertical-align: middle;
`;

const StyledButton = styled(Button)<{ addDestinationInSearch: boolean }>`
  ${({ addDestinationInSearch }) =>
    addDestinationInSearch &&
    css`
      min-height: 40px;
    `};
`;

const TextLink = styled.a`
  background: none;
  border: none;
  padding: 0;
  color: ${color.pink};
  text-decoration: none;
  cursor: pointer;
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.body};
  line-height: ${lineHeight.tight};
  &:hover {
    text-decoration: none;
  }
`;
