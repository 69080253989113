import {
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

export type FilterStops = "direct" | "onestop" | "twostops";
export type FilterTimeRange = {
  earliest: string;
  latest: string;
};
export const flightSortOptions = "best";
export type SortingOptions =
  | typeof flightSortOptions
  | "cheapest"
  | "fastest"
  | "soonest";
export type FilterOptions = {
  stops: FilterStops[];
  price?: FilterPriceRange;
  outboundTime?: FilterTimeRange;
  duration?: FilterDurationRange;
};
export type FilterDurationRange = {
  shortest: number;
  longest: number;
};
export type FilterPriceRange = {
  lowest: number;
  highest: number;
};

export const DEFAULT_SORT = "soonest";
export const DEFAULT_FILTER: FilterOptions = {
  stops: [],
};

type TravelSearchSettingsProviderProps = {
  initialDate?: string;
  initialTime?: string;
  sort?: SortingOptions;
  filter?: FilterOptions;
};

export function TravelSearchSettingsProvider(
  props: PropsWithChildren<TravelSearchSettingsProviderProps>
) {
  const [dateTimeObject, setDateTimeObject] = useState({
    departureDate: props.initialDate ?? "",
    time: props.initialTime ?? "",
  });
  const [sort, setSort] = useState<SortingOptions | undefined>();
  const [filter, setFilter] = useState(props.filter ?? DEFAULT_FILTER);

  const providerValue = useMemo(() => {
    const getActiveFilters = () => {
      return Object.keys(filter).filter((key) => {
        if (key === "stops") {
          return filter.stops.length > 0;
        }
        return filter[key as keyof FilterOptions] !== undefined;
      });
    };

    return {
      dateTimeObject,
      sort,
      filter,
      setDateTimeObject,
      setSort,
      setFilter,
      getActiveFilters,
    };
  }, [dateTimeObject, sort, filter]);

  return (
    <TravelSearchContext.Provider value={providerValue}>
      {props.children}
    </TravelSearchContext.Provider>
  );
}

export function useTravelSearchSettings() {
  const context = useContext(TravelSearchContext);
  if (!context) {
    throw new Error(
      `useTravelSearchSettings must be used within TravelSearchSettingsProvider`
    );
  }
  return context;
}

const TravelSearchContext = createContext<{
  dateTimeObject: {
    departureDate: string;
    returnDate?: string;
    time: string;
  };
  sort: SortingOptions | undefined;
  filter: FilterOptions;
  setDateTimeObject: Dispatch<
    SetStateAction<{
      departureDate: string;
      returnDate?: string;
      time: string;
    }>
  >;
  setSort: Dispatch<SetStateAction<SortingOptions | undefined>>;
  setFilter: Dispatch<SetStateAction<FilterOptions>>;
  getActiveFilters: () => string[];
}>({
  dateTimeObject: {
    departureDate: "",
    time: "",
  },
  sort: DEFAULT_SORT,
  filter: DEFAULT_FILTER,
  setDateTimeObject: () => {},
  setSort: () => {},
  setFilter: () => {},
  getActiveFilters: () => [],
});
