import { sendAnalyticsNonInteractionEvent } from "src/analytics/sendAnalyticsEvent";

export function logApiInfo(status: "Success" | "Error", message?: string) {
  sendAnalyticsNonInteractionEvent({
    category: "UserAccounts",
    action: `SessionRequest:${status}`,
    label: message,
  });
}

export function logFetchError(error: unknown) {
  let message = error instanceof Error ? error.message : undefined;
  logApiInfo("Error", message);
}
