import type { ApiConfig } from "../api/ApiConfig";
import { logExitEndpoint } from "../api/endpoints";
import type { Mode } from "../utils/types/mode";
import { urlConfig } from "../utils/urlConfig";
import type { LogExitCategory } from "./analyticsEventTypes";
import { generateExitLabel } from "./generateExitLabel/generateExitLabel";
import {
  type PartnerPageExitCategory,
  type Trigger,
  type View,
  generateExitPoint,
} from "./generateExitPoint/generateExitPoint";
import { sendAnalyticsExitEvent } from "./sendAnalyticsExitEvent";

export type LogExitParams = {
  category: LogExitCategory;
  requestId?: string;
  provider?: string;
  agencyIds?: string[];
  originCanonical?: string;
  destinationCanonical?: string;
  destinationUrl: string;
  exitPoint: {
    view: View;
    trigger: Trigger;
    transitMode?: Mode;
  };
  partnerPage?: PartnerPageExitCategory;
  navigationEventId?: string;
};

export function logExit(config: ApiConfig, params: LogExitParams) {
  const url = new URL(params.destinationUrl, urlConfig.exitHost);
  const isRedirect =
    url.pathname.match(/^\/redirects\//) &&
    (url.host.match(/rome2rio\.com/) || url.host.match(/localhost/));
  const {
    category,
    exitPoint: { view, trigger, transitMode },
    provider,
    navigationEventId,
  } = params;
  sendAnalyticsExitEvent(
    category,
    view,
    trigger,
    transitMode,
    provider,
    navigationEventId
  );
  // Redirects perform their own backend logging, so we don't need to log
  // them to the backend.
  if (!isRedirect) {
    logExitToBackend(config, params);
  }
}

export function logExitToBackend(config: ApiConfig, params: LogExitParams) {
  const bodyObject = createLogExitBodyObject(params);
  if (typeof navigator?.sendBeacon === "function") {
    navigator.sendBeacon(
      logExitEndpoint(config),
      new Blob([JSON.stringify(bodyObject)], { type: "application/json" })
    );
  } else {
    // We're deliberately leaving this Promise hanging, because there is no way
    // for us to cleanly handle it.
    fetch(logExitEndpoint(config), {
      method: "POST",
      body: JSON.stringify(bodyObject),
    });
  }
}

export function createLogExitBodyObject(params: LogExitParams) {
  const timestamp = new Date().getTime();
  const currentUrl = window.location.href;
  const exitPoint = generateExitPoint(
    params.exitPoint.view,
    params.exitPoint.trigger,
    params.exitPoint.transitMode
  );
  const exitLabel = generateExitLabel(exitPoint, params.requestId);

  return {
    category: params.category,
    timestamp,
    provider: params.provider,
    agencyIds: params.agencyIds,
    currentUrl,
    label: exitLabel,
    action: exitPoint,
    origin: params.originCanonical,
    destination: params.destinationCanonical,
    destinationUrl: params.destinationUrl,
    requestId: params.requestId,
    rawExitPoint: exitPoint,
    navigationEventId: params.navigationEventId,
  };
}
