import { useEffect, useRef, useState, type PropsWithChildren } from "react";

export function ClickOutsideListener({
  onClickOutside,
  children,
}: PropsWithChildren<{ onClickOutside: () => void }>) {
  const ref = useRef<HTMLDivElement>(null);
  const [active, setActive] = useState(false);

  useEffect(() => {
    function handleClickAway(event: MouseEvent | TouchEvent) {
      if (!ref.current) return;
      const path = event.composedPath();
      if (
        ref.current?.contains(event.target as Node) ||
        path.includes(ref.current)
      ) {
        setActive(true);
      } else if (active) {
        onClickOutside();
        setActive(false);
      }
    }
    document.addEventListener("mousedown", handleClickAway);
    document.addEventListener("touchstart", handleClickAway);

    return () => {
      document.removeEventListener("mousedown", handleClickAway);
      document.removeEventListener("touchstart", handleClickAway);
    };
  }, [active, onClickOutside]);

  return (
    <div style={{ display: "contents" }} ref={ref}>
      {children}
    </div>
  );
}
