import { useEffect, useRef } from "react";
import scrollIntoView from "scroll-into-view-if-needed";
import type { AutocompletePlace } from "src/api/AutocompleteResponse";
import { AddDestinationButton } from "src/components/TripPlanner/AddDestinationButton/AddDestinationButton";
import { spacing } from "src/theme";
import { useLayout } from "src/utils/hooks/useLayout";
import styled from "styled-components";
import AddDestinationInline from "./AddDestinationInline/AddDestinationInline";
import CarRentalPromo from "./CarRentalPromo/CarRentalPromo";
import { HotelsPromo } from "./HotelsPromo/HotelsPromo";
import { OnscreenReorder } from "./ReorderTrip/OnscreenReorder/OnscreenReorder";
import {
  LoadingCard,
  TripPlannerCard,
} from "./TripPlannerCard/TripPlannerCard";
import {
  TripPlanEntryWrapper,
  TripPlannerPlace,
} from "./TripPlannerCard/TripPlannerPlace";
import { UserAccountTakeover } from "./UserAccountTakeover/UserAccountTakeover";
import { UserPrompt } from "./UserPrompt/UserPrompt";
import { useUserPrompt } from "./UserPrompt/useUserPrompt";
import { useTripPlannerContext } from "./hooks/useTripPlannerContext";

export function TripPlannerScreenLoaded() {
  const {
    tripPlannerDetails,
    tripPlanningState,
    setHoveredPlaceIndex,
    tripDestination,
  } = useTripPlannerContext();

  const { isLoading, setDestination, shortName, longName } = tripDestination;

  const {
    hasBeenClosed,
    handleCloseUserPrompt,
    showUserPrompt,
    showUserPromptOverlay,
    handleSaveTripUserPrompt,
    handleClosePromptOverlay,
    userPromptRef,
  } = useUserPrompt();

  const layout = useLayout();
  const isLargerThanMobile = layout !== "mobile";

  const scrollRef = useRef<HTMLDivElement>(null);
  const addDestinationRef = useRef<HTMLDivElement>(null);

  function onSelectDestination(place: AutocompletePlace) {
    if (place.link) {
      window.open(place.link, "_blank");
    } else {
      setDestination(place);
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (scrollRef.current) {
        scrollIntoView(scrollRef.current, {
          behavior: "smooth",
          block: "center",
        });
      }
    }, 0);

    return () => clearTimeout(timeout);
  }, [tripPlanningState.updatedPlace.value]);

  // Scroll up to add destination inline when last place removed
  function handleRemoveOfLast() {
    setTimeout(() => {
      if (addDestinationRef.current) {
        scrollIntoView(addDestinationRef.current, {
          behavior: "smooth",
          block: "center",
        });
      }
    }, 0);
  }

  return (
    <>
      {showUserPromptOverlay && (
        <UserAccountTakeover
          handleClickOffDropdown={handleClosePromptOverlay}
          handleClose={handleClosePromptOverlay}
          isDropdownOpen
        />
      )}
      {isLargerThanMobile ? (
        <OnscreenReorder onRemove={handleRemoveOfLast} />
      ) : (
        <>
          {tripPlannerDetails.places.map((p, index) => {
            const key = `${index}_tripCard_${p.canonicalName}`;
            const callbacks = {
              hoverCallback: () => setHoveredPlaceIndex(index),
              unHoverCallback: () => setHoveredPlaceIndex(undefined),
            };
            const isUpdated = tripPlanningState.updatedPlace.value === index;
            return (
              <TripPlannerPlace
                isLast={index === tripPlannerDetails.places.length - 1}
                index={index}
                key={key}
              >
                <TripPlannerCard
                  scrollRef={isUpdated ? scrollRef : undefined}
                  places={tripPlannerDetails.places}
                  index={index}
                  callbacks={callbacks}
                  isUpdated={isUpdated}
                />
              </TripPlannerPlace>
            );
          })}
        </>
      )}
      {isLoading && (
        <LoadingContainer>
          <TripPlanEntryWrapper $hideTimeline={true}>
            <LoadingCard
              name={longName ?? shortName}
              index={tripPlannerDetails.places.length}
            />
          </TripPlanEntryWrapper>
        </LoadingContainer>
      )}
      <TripPlanEntryWrapper $hideTimeline={true}>
        {layout !== "mobile" ? (
          <AddDestinationInline
            addDestinationRef={addDestinationRef}
            onSelectDestination={onSelectDestination}
          />
        ) : (
          <AddDestinationButton onSelectDestination={onSelectDestination} />
        )}
      </TripPlanEntryWrapper>
      {showUserPrompt && hasBeenClosed && (
        <UserPrompt
          hasBeenClosed={hasBeenClosed}
          onSave={handleSaveTripUserPrompt}
          onClose={handleCloseUserPrompt}
          ref={userPromptRef}
        />
      )}

      <PromoButtons>
        {tripPlannerDetails.places.length === 2 && (
          <HotelsPromo
            location={tripPlannerDetails.places[1]}
            // TODO: When desktop trips has it's own tab, we can just pass TripPlanner.
            eventCategory={!isLargerThanMobile ? "TripPlanner" : undefined}
          />
        )}
        <CarRentalPromo
          // TODO: When desktop trips has it's own tab, we can just pass TripPlanner.
          eventCategory={!isLargerThanMobile ? "TripPlanner" : undefined}
        />
      </PromoButtons>
    </>
  );
}

const PromoButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.lg};
  margin-top: ${spacing.xxxl};
`;

const LoadingContainer = styled.div`
  padding-left: 50px;
`;
