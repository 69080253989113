import { useState } from "react";
import { DisplayAd } from "src/components/DisplayAd/DisplayAd";
import { border_radius } from "src/design-system/tokens/border";
import color from "src/design-system/tokens/color";
import styled from "styled-components";
import { spacing } from "src/design-system/tokens/spacing";
import { mediumBreakpoint } from "src/utils/hooks/useScreenSize";

const MOBILE_OFFSET = 4;

export function TripInlineSerpAd() {
  const [hasAd, setHasAd] = useState(false);

  return (
    <InlineAdWrapper test-id="desktop-inline-serp-ad" hasAd={hasAd}>
      <StyledDisplayAd
        onFilled={() => setHasAd(true)}
        slotConfigId="desktopInlineSerpAd"
      />
    </InlineAdWrapper>
  );
}

const InlineAdWrapper = styled.div<{ hasAd: boolean }>`
  display: ${({ hasAd }) => (hasAd ? "flex" : "none")};
  background-color: ${color.bg.fill.fill};
  margin: ${spacing.md} 0;
  border: 1px solid ${color.border.secondary};
  border-radius: ${border_radius.rounded_md};
  overflow: hidden;

  cursor: pointer;

  &:hover {
    // Note: hover effect cannot be applied on GAM ads, only the container
    background: ${color.bg.fill.hover};
  }

  ${mediumBreakpoint} {
    margin-bottom: 0;
  }
`;

const StyledDisplayAd = styled(DisplayAd)`
  // Note: media queries cannot be applied inside GAM ads. As mobile SERP
  // tiles require smaller outer padding we therefore achieve this by using
  // a negative margin that hides the excess padding from the parent container.
  margin: -${MOBILE_OFFSET}px;
  width: calc(100% + ${MOBILE_OFFSET * 2}px);
  height: calc(100% + ${MOBILE_OFFSET * 2}px);

  ${mediumBreakpoint} {
    margin: 0;
    width: 100%;
    height: auto;
  }
`;
