import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { useApiConfig } from "src/api/ApiConfigProvider";
import type { AutocompletePlace } from "src/api/AutocompleteResponse";
import { bottomNavBarHeight } from "src/components/BottomNavbar/BottomNavbar";
import { Icon } from "src/components/Icon/Icon";
import { Button } from "src/design-system/components/Button/Button";
import { Typography } from "src/design-system/components/Typography/Typography";
import { border_radius } from "src/design-system/tokens/border";
import color from "src/design-system/tokens/color";
import { spacing } from "src/design-system/tokens/spacing";
import type { GeocodedPlace } from "src/PrefetchData";
import { CheckCircle } from "src/svg/CheckCircle";
import Close from "src/svg/Close.svg?react";
import { ChevronLeft } from "src/svg/designSystem/ChevronLeft";
import { Rome2rioLogo } from "src/svg/Rome2rioLogo";
import { screenMinWidth } from "src/theme";
import { autocompleteToGeocodedPlace } from "src/utils/autocompleteToGeocodedPlace";
import { localeToLanguageCode } from "src/utils/conversions/languageCode";
import { useAnalyticsPageView } from "src/utils/hooks/useAnalyticsPageView";
import { useScreenMinWidth } from "src/utils/hooks/useScreenMinWidth";
import useSearch from "src/utils/hooks/useSearch";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import useUser from "src/utils/hooks/useUser";
import { navigateToNewStateHash } from "src/utils/location/navigateToNewStateHash";
import styled from "styled-components";
import { useTripPlannerContext } from "../hooks/useTripPlannerContext";
import { PlaceInputList } from "../TripEditorModal/PlaceInputList";
import messages from "./CreateTripScreen.messages";

export function CreateTripScreen() {
  const intl = useIntl();
  const { dispatch } = useTripPlannerContext();
  const navigate = useNavigate();
  const location = useTypedLocation();
  const apiConfig = useApiConfig();
  const languageCode = localeToLanguageCode(intl.locale);
  const queryClient = useQueryClient();
  const { user } = useUser();
  const isUserLoggedIn = !!user?.id;
  const [tripDestinations, setTripDestinations] = useState<GeocodedPlace[]>([]);
  const { isSmScreen, isMobile, isDesktop } = useScreenMinWidth();
  const { searchResponse } = useSearch();
  const [loadingIndex, setLoadingIndex] = useState<number | undefined>();

  const getPlaces = useCallback(() => {
    return Array.from(
      { length: Math.max(tripDestinations.length, 2) },
      (_, index) => {
        const geocodedPlace = tripDestinations[index];
        if (geocodedPlace) {
          return geocodedPlace.longName ?? geocodedPlace.shortName;
        }
        return "";
      }
    );
  }, [tripDestinations]);

  useAnalyticsPageView(
    {
      pagePath: location.pathname + location.search + location.hash,
      pageLocation: window.location.href,
    },
    searchResponse,
    "CreateTrip",
    undefined
  );

  function handleSelectDestination(
    autocompletePlace: AutocompletePlace,
    index: number
  ) {
    setLoadingIndex(index);
    sendAnalyticsInteractionEvent({
      category: "CreateTrip",
      action: "Select:EditDestination",
      label: autocompletePlace.canonicalName,
    });
    autocompleteToGeocodedPlace(
      autocompletePlace,
      (geocodedPlace: GeocodedPlace) => {
        setTripDestinations((prev) => {
          const newDestinations = [...prev];
          newDestinations[index] = geocodedPlace;
          return newDestinations;
        });
        setLoadingIndex(undefined);
      },
      languageCode,
      queryClient,
      apiConfig
    );
  }

  function handleCloseCreateTrip() {
    sendAnalyticsInteractionEvent({
      category: "CreateTrip",
      action: "Click:Close",
    });
    navigateToNewStateHash(
      navigate,
      {
        highlightedTab: "trips",
      },
      `#trips/saved/`,
      {
        ...location,
      }
    );
  }

  function handleRemoveDestination(index: number) {
    sendAnalyticsInteractionEvent({
      category: "CreateTrip",
      action: "Click:ClearDestination",
    });
    const newTripDestinations = tripDestinations.filter((_, i) => i !== index);
    setTripDestinations(newTripDestinations);
  }

  function handleCreateTrip() {
    sendAnalyticsInteractionEvent({
      category: "CreateTrip",
      action: "Click:CreateTrip",
    });
    // If the user is logged in under multiple trips, we want to create a new trip.
    // If the user is not logged in, we want to replace the trip.
    const action = user?.id
      ? "CREATE_TRIP_FROM_SEARCH_TRIP"
      : "REPLACE_TRIP_FROM_SEARCH_TRIP";
    dispatch({
      type: action,
      places: tripDestinations,
    });
  }

  return (
    <Content>
      {isUserLoggedIn && isDesktop && (
        <NavWrapper onClick={handleCloseCreateTrip}>
          <Icon size="lg">
            <ChevronLeft tint="primaryOnLight" strokeWidth="4" />
          </Icon>
          <Typography variant="body-lg">
            {intl.formatMessage(messages.back)}
          </Typography>
        </NavWrapper>
      )}
      <Header>
        <LogoWrapper>
          <a href="/">
            <Rome2rioLogo
              title={intl.formatMessage(messages.homepage)}
              tint="cod"
              dotTint="pink"
            />
          </a>
        </LogoWrapper>
        {isUserLoggedIn && isMobile && (
          <IconWrapper onClick={handleCloseCreateTrip}>
            <Close width={14} height={14} color={color.icon.icon} />
          </IconWrapper>
        )}
      </Header>
      <HeadingWrapper>
        <Typography variant="heading-md">
          {isUserLoggedIn
            ? intl.formatMessage(messages.loggedInHeading)
            : intl.formatMessage(messages.heading)}
        </Typography>
      </HeadingWrapper>
      <SearchWrapper>
        <ContentWrapper>
          <PlaceInputList
            loadingIndex={loadingIndex}
            places={getPlaces()}
            handleRemoveDestination={handleRemoveDestination}
            handleSelectPlace={handleSelectDestination}
            isInline={isSmScreen}
          />
        </ContentWrapper>
        <FixedBanner>
          <ButtonWrapper>
            <Button
              size="large"
              onPress={handleCreateTrip}
              isDisabled={tripDestinations.length < 2}
            >
              {intl.formatMessage(messages.cta)}
            </Button>
          </ButtonWrapper>
        </FixedBanner>
      </SearchWrapper>
      {isDesktop && (
        <Checks>
          <Typography variant="heading-sm">
            {intl.formatMessage(messages.withTrips)}:
          </Typography>
          <CheckList>
            <CheckItem>
              <CheckCircle tint="white" />
              <Typography variant="body-md">
                {intl.formatMessage(messages.addDestinations)}
              </Typography>
            </CheckItem>
            <CheckItem>
              <CheckCircle tint="white" />
              <Typography variant="body-md">
                {intl.formatMessage(messages.saveTrips)}
              </Typography>
            </CheckItem>
            <CheckItem>
              <CheckCircle tint="white" />
              <Typography variant="body-md">
                {intl.formatMessage(messages.bestWays)}
              </Typography>
            </CheckItem>
          </CheckList>
        </Checks>
      )}
    </Content>
  );
}

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.xxl};
  gap: ${spacing.sm};

  &:hover {
    cursor: pointer;

    span {
      text-decoration: underline;
    }
  }
`;

const Header = styled.div`
  margin-bottom: ${spacing.xxl};
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  ${screenMinWidth.sm} {
    display: none;
  }
`;

const LogoWrapper = styled.div`
  svg {
    width: 120px;
  }
`;

const IconWrapper = styled.div`
  margin: 0 ${spacing.md};
`;

const HeadingWrapper = styled.div`
  margin-bottom: ${spacing.xl};
`;

const ButtonWrapper = styled.div`
  margin: ${spacing.xl};
  ${screenMinWidth.sm} {
    margin: ${spacing.sm} 0;
  }
`;

const ContentWrapper = styled.div`
  padding-bottom: 77px; // height of fixed container
  ${screenMinWidth.sm} {
    padding-bottom: 0;
  }
`;

const FixedBanner = styled.div`
  width: 100%;
  position: fixed;
  bottom: ${bottomNavBarHeight};
  left: 0;
  border-top: 1px solid ${color.border.border};

  ${screenMinWidth.sm} {
    border: none;
    position: relative;
    bottom: unset;
  }

  button {
    width: 100%;
  }
`;

const SearchWrapper = styled.div`
  ${screenMinWidth.sm} {
    background-color: ${color.bg.fill.fill};
    padding: ${spacing.xxl};
    border-radius: ${border_radius.rounded_lg};
  }
`;

const Content = styled.div`
  width: 100%;
  position: relative;
  padding: ${spacing.xxl} ${spacing.xl} ${spacing.xl};
  // This is the height minus nav, fixed button and safari menu
  min-height: calc(100vh - 55px - 44px - env(safe-area-inset-bottom));

  ${screenMinWidth.sm} {
    min-height: calc(100vh - 64px);
    background-color: ${color.bg.surface.secondary};
  }
`;

const Checks = styled.div`
  margin-left: ${spacing.xl};
  margin-top: ${spacing.xxl};
`;

const CheckList = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: ${spacing.xl};
  margin-left: ${spacing.xs};
`;

const CheckItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.md};

  svg path {
    fill: ${color.primitive.green400};
  }

  span {
    margin-left: ${spacing.md};
  }
`;
