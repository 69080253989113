import { useEffect, useMemo, useState } from "react";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import type { SearchResponse } from "src/api/SearchResponse";
import { getTripCardAnalyticsLabel } from "src/components/TripPlanner/TripCard/TripCard";
import {
  type TripPlannerDetails,
  userOverwritten,
} from "../TripPlannerProvider";
import { getPlaceIndexFromTransportKey } from "../util/getPlaceIndexFromTransportKey";
import { getSearchTransportKey } from "../util/getSearchTransportKey";
import { isPlaceLast } from "../util/isPlaceLast";

export const UPDATE_TIMEOUT = 400;
const UPDATED_NOTIFICATION_TIMEOUT = 5000;

type GACategoryType = "Search" | "Route" | "Schedule" | "Segment";

export function useTripPlanningState() {
  const updatedPlace = useState<number | undefined>(undefined);
  const updatedNotification = useState<string | undefined>(undefined);

  const tripPlanningState = useMemo(
    () => ({
      updatedPlace: { set: updatedPlace[1], value: updatedPlace[0] },
      updatedNotification: {
        set: updatedNotification[1],
        value: updatedNotification[0],
      },
    }),
    [updatedPlace, updatedNotification]
  );

  useEffect(() => {
    let updatedPlaceTimeout: ReturnType<typeof setTimeout> | undefined =
      undefined;
    let updatedNotificationTimeout: ReturnType<typeof setTimeout> | undefined =
      undefined;

    if (tripPlanningState.updatedNotification.value !== undefined) {
      updatedNotificationTimeout = setTimeout(() => {
        tripPlanningState.updatedNotification.set(undefined);
      }, UPDATED_NOTIFICATION_TIMEOUT);
    }

    if (tripPlanningState.updatedPlace.value !== undefined) {
      updatedPlaceTimeout = setTimeout(() => {
        tripPlanningState.updatedPlace.set(undefined);
      }, UPDATE_TIMEOUT);
    }

    return () => {
      clearTimeout(updatedPlaceTimeout);
      clearTimeout(updatedNotificationTimeout);
    };
  }, [tripPlanningState]);

  return tripPlanningState;
}

export function updateTripPlanningState(
  tripPlanningState: ReturnType<typeof useTripPlanningState>,
  tripPlannerDetails: TripPlannerDetails,
  searchResponse: SearchResponse,
  routeIndex?: number,
  routeSegmentIndex?: number,
  gaCategory: GACategoryType = "Search",
  savedFromDrawer?: boolean,
  isScheduleDetail?: boolean
) {
  const canonicalPair = getSearchTransportKey(searchResponse);
  const [origin] = canonicalPair.split("_");
  const isUpdatedPlaceLast = isPlaceLast(origin, tripPlannerDetails.places);

  let placePairIndex;

  if (!isUpdatedPlaceLast) {
    placePairIndex = getPlaceIndexFromTransportKey(
      tripPlannerDetails.places,
      canonicalPair
    );
  } else {
    placePairIndex = tripPlannerDetails.places.length - 1;
  }

  if (placePairIndex !== undefined) {
    tripPlanningState.updatedPlace.set(placePairIndex);
  }

  const hasUserOverwritten = userOverwritten(tripPlannerDetails, canonicalPair);

  const action = isScheduleDetail
    ? "Select:TripOptionDetail"
    : "Select:TripOption";

  if (!savedFromDrawer) {
    sendAnalyticsInteractionEvent({
      category: gaCategory,
      action: action,
      label: getTripCardAnalyticsLabel(
        hasUserOverwritten,
        searchResponse,
        routeIndex,
        routeSegmentIndex
      ),
    });
  }
}
