import { sendAnalyticsNonInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import type { Mode } from "../utils/types/mode";
import {
  type Trigger,
  type View,
  compressTrigger,
  compressView,
} from "./generateExitPoint/generateExitPoint";
import { compressMode } from "./generateExitPoint/compressMode";
import type { LogExitCategory } from "./analyticsEventTypes";

export function sendAnalyticsExitEvent(
  productCategory: LogExitCategory | "Other",
  view: View,
  trigger: Trigger,
  transitMode?: Mode,
  bookingProvider?: string,
  navigationEventId?: string
) {
  // Label: <schema>:<v>:<t>:<m>:<bookingProvider>
  // * schema: "ex1" a schema identifier in case we change the packing later
  // * v: The <view> compressed by the same method as `generateExitPoint`
  // * t: The <trigger> compressed by the same method as `generateExitPoint`
  // * m: The <transitMode> compressed by the same method as `generateExitPoint`
  const parts = ["ex1"];
  parts.push(compressView(view));
  parts.push(compressTrigger(trigger));
  parts.push(compressMode(transitMode));
  if (bookingProvider) {
    parts.push(bookingProvider);
  }
  const label = parts.join(":");

  const extraInfo = navigationEventId
    ? { dimensions: { navigationEventId: navigationEventId } }
    : undefined;
  sendAnalyticsNonInteractionEvent({
    category: productCategory,
    action: "Exit",
    label: label,
    extraInfo,
  });
}
