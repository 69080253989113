import { useIntl } from "react-intl";
import { HotelAlternative } from "src/svg/HotelAlternative";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { useScroll } from "src/ScrollContext";
import { useNavigateToHotelsPage } from "src/utils/hooks/useNavigateToHotelsPage";
import useSearch from "src/utils/hooks/useSearch";
import { type GeoKind, isPointKind } from "src/utils/geoKind";
import { useEffect, useRef, useState } from "react";
import { useIsInViewport } from "src/utils/hooks/useIsInViewport";
import type { HotelProviderKind } from "src/utils/types/accommodationProviders";
import { sendAnalyticsNonInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import type { AccomPromoLoggingCategory } from "src/analytics/analyticsEventTypes";
import { useIsTripScreen } from "src/utils/hooks/useIsTripScreen";
import { useScreenMinWidth } from "src/utils/hooks/useScreenMinWidth";
import { SegmentPromoDriveVariant } from "../SegmentPromoDriveVariant/SegmentPromoDriveVariant";
import messages from "./SegmentAccommodationPromoDriveVariant.messages";

type Props = {
  location: string;
  kind: GeoKind;
  loggingFrom: AccomPromoLoggingCategory;
  hotelProvider: HotelProviderKind;
  id: string;
};

export function SegmentAccommodationPromoDriveVariant({
  location,
  kind,
  loggingFrom,
  hotelProvider,
  id,
}: Props) {
  const intl = useIntl();
  const { setScrollTop } = useScroll();
  const { navigateToHotels } = useNavigateToHotelsPage();
  const { origin, destination } = useSearch();
  const { isMdScreen } = useScreenMinWidth();
  const isFullSizePromo = !isMdScreen;
  const isTripsScreen = useIsTripScreen();
  const copyPreposition = isPointKind(kind) ? "Near" : "Around";
  const calloutMessage = messages[`findHotels${copyPreposition}`];

  // We want to see when an accommodation promo gets into view but only fire the log once.
  const accommodationPromoRef = useRef<HTMLButtonElement>(null);
  const accommodationPromoInViewport = useIsInViewport(accommodationPromoRef);
  const [hasAccommodationPromoBeenViewed, setHasAccommodationPromoBeenViewed] =
    useState(false);

  function handleClick() {
    const gaLoggingCategory =
      loggingFrom === "Schedule" ? "ScheduleDetails" : loggingFrom;

    sendAnalyticsInteractionEvent({
      category: gaLoggingCategory,
      action: "Click:HotelPromo",
    });
    setScrollTop(0);
    if (isTripsScreen) {
      sendAnalyticsInteractionEvent({
        category: "TripPlanner",
        action: "Click:HotelPromo",
      });
      navigateToHotels({
        originCanonical: origin?.canonicalName,
        destinationCanonical: destination?.canonicalName,
      });
    } else {
      navigateToHotels();
    }
  }

  useEffect(() => {
    if (accommodationPromoInViewport && !hasAccommodationPromoBeenViewed) {
      sendAnalyticsNonInteractionEvent({
        category: "Screen",
        action: "AccomPromoVisible",
        label: `ap0:${hotelProvider}`,
      });
      setHasAccommodationPromoBeenViewed(true);
    }
  }, [
    accommodationPromoInViewport,
    id,
    hotelProvider,
    hasAccommodationPromoBeenViewed,
  ]);

  return (
    <SegmentPromoDriveVariant
      ref={accommodationPromoRef}
      heading={intl.formatMessage(calloutMessage, {
        location,
      })}
      tagline={intl.formatMessage(messages.tagline)}
      icon={<HotelAlternative tint="cod" />}
      iconAlign={isFullSizePromo ? "left" : "right"}
      outline={isFullSizePromo}
      fullWidth={isFullSizePromo}
      onClick={handleClick}
    />
  );
}
