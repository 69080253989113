import { useEffect } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router";
import type { AnalyticsEventCategory } from "src/analytics/analyticsEventTypes";
import { RoundRadio } from "src/components/RadioGroup/RoundRadio";
import { TransitRadioIcon } from "src/components/RadioGroup/TransitRadioIcon";
import { border_radius } from "src/design-system/tokens/border";
import { primitive } from "src/design-system/tokens/color";
import { TransitIcons } from "src/domain/SearchResultsScreen/SearchResult/TransitIcons";
import messages from "src/domain/SearchResultsScreen/SearchResultsContent/SearchResultsContent.messages";
import type { SearchResultViewModel } from "src/domain/SearchResultsScreen/createSearchResultViewModel";
import { A11yOutline } from "src/utils/accessibility";
import useSearch from "src/utils/hooks/useSearch";
import { tripHashFromSearchResponse } from "src/utils/location/createTripHashForCard";
import { hashChange } from "src/utils/location/hashChange";
import styled, { css } from "styled-components";
import { useSkipToFlightSegment } from "src/utils/hooks/useSkipToFlightSegment";
import { getSchedulesPaintPerformanceKey } from "src/utils/performance";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { useHoveredIndex } from "../../../../HoveredIndexProvider";
import { formatDistance } from "../../../../components/FormattedDistance/FormattedDistance";
import { FormattedDuration } from "../../../../components/FormattedDuration/FormattedDuration";
import { FormattedPriceRange } from "../../../../components/FormattedPriceRange/FormattedPriceRange";
import { Icon } from "../../../../components/Icon/Icon";
import { ChevronRightBold } from "../../../../svg/ChevronRightBold";
import { Gas } from "../../../../svg/Gas";
import {
  color,
  fontSize,
  fontWeight,
  lineHeight,
  notificationColor,
  screenMinWidth,
  spacing,
} from "../../../../theme";

import { desktopLayout, useLayout } from "../../../../utils/hooks/useLayout";
import { useTypedLocation } from "../../../../utils/hooks/useTypedLocation";
import useUser from "../../../../utils/hooks/useUser";

export type SearchResultTweakedProps = {
  analyticsCategory?: AnalyticsEventCategory;
  className?: string;
  routeLabel: string;
  selectedRouteIndex?: string;
  isRadioHidden?: boolean;
  $size?: "sm" | "md";
  $border?: boolean;
  isSearchScreen?: boolean;
} & SearchResultViewModel;

export function TripSearchResult({
  analyticsCategory = "SearchResults",
  ...props
}: SearchResultTweakedProps) {
  const intl = useIntl();
  const { distanceSystem } = useUser();
  const { setHoveredRouteIndex } = useHoveredIndex();
  const { searchResponse } = useSearch();
  const layout = useLayout();
  const singleFlightSegmentIndex = useSkipToFlightSegment(props.routeIndex);

  function handleMouseEnter() {
    setHoveredRouteIndex(props.routeIndex);
  }

  // Skip straight to segment screen if only one segment in the route or one major flight.
  let segmentIndex: null | number = null;
  if (props.segments.length === 1) {
    segmentIndex = 0;
  } else if (singleFlightSegmentIndex !== undefined) {
    segmentIndex = singleFlightSegmentIndex;
  }
  const suffix = segmentIndex !== null ? `/s/${segmentIndex}` : "";
  const tripHash = tripHashFromSearchResponse(searchResponse);
  const hash = props.isSearchScreen
    ? `#r/${props.canonicalName}${suffix}`
    : `${tripHash}/r/${props.canonicalName}${suffix}`;
  const location = useTypedLocation();
  const { state, ...to } = hashChange(hash, location);

  // Handler for react-router navigation on an anchor tag.
  function handleClick() {
    if (segmentIndex !== null) {
      performance.mark(
        getSchedulesPaintPerformanceKey(
          "NavigateStart",
          props.routeIndex,
          segmentIndex
        )
      );
    }
    sendAnalyticsInteractionEvent({
      category: analyticsCategory,
      action: "Click:Result",
      label: props.routeLabel,
    });
  }

  const majorModes = props.segments
    .filter((it) => it.isMajor)
    .map((it) => it.transitMode);

  let resultTitle = props.title;

  const isDriveRoute = props.canonicalName === "Drive";
  // A drive route should only have one segment, which we can get the distance from.
  if (isDriveRoute && props.totalDistance) {
    const distance = formatDistance(props.totalDistance, distanceSystem, intl);
    resultTitle = `${props.title} ${distance}`;
  }

  useEffect(() => {
    return () => {
      setHoveredRouteIndex(undefined);
    };
  }, [setHoveredRouteIndex]);

  return (
    <Wrapper
      data-testid={`trip-search-result-${props.routeIndex}`}
      $size={props.$size ?? "md"}
      onMouseEnter={layout === "desktop" ? handleMouseEnter : undefined}
    >
      {!props.isRadioHidden ? (
        <RadioWrapper $border={props.$border}>
          <RoundRadio
            left
            cover
            value={props.routeIndex.toString()}
            CustomIcon={TransitRadioIcon}
          />
        </RadioWrapper>
      ) : null}
      <Container
        className={props.className}
        $isRadioHidden={props.isRadioHidden}
        $isTrips={!props.isSearchScreen}
        onClick={handleClick}
        state={{
          fromHash: location.hash,
        }}
        to={to}
        $border={props.$border}
      >
        <SpaceBetween>
          <TypographyHeadingCompact>{resultTitle}</TypographyHeadingCompact>
          {props.tag && (
            <TagContainer>
              <Tag>{intl.formatMessage(messages[props.tag])}</Tag>
            </TagContainer>
          )}
        </SpaceBetween>

        <SpaceBetween $wrap>
          <TransitContainer $compact>
            <TransitIcons modes={majorModes} />
            <DurationContainer>
              <FormattedDuration totalMinutes={props.durationInMinutes} />
            </DurationContainer>
          </TransitContainer>
          <PriceRangeCompact>
            {props.priceRange && isDriveRoute && (
              <Icon size="md">
                <Gas tint="n300" />
              </Icon>
            )}
            <TypographyCta>
              {props.priceRange && (
                <FormattedPriceRange {...props.priceRange} />
              )}
              <StyledIcon size="sm-1" data-testid="chevron">
                <ChevronRightBold tint="pink" />
              </StyledIcon>
            </TypographyCta>
          </PriceRangeCompact>
        </SpaceBetween>
      </Container>
    </Wrapper>
  );
}

const StyledIcon = styled(Icon)`
  position: relative;
  left: 0;
  transition: left 0.3s ease;
  display: block;
`;

const RadioWrapper = styled.div<{
  $border?: boolean;
}>`
  display: flex;
  align-items: center;
  border: 2px solid transparent;
  background-color: ${color.white};
  border-radius: ${border_radius.rounded_lg} ${border_radius.rounded_sm}
    ${border_radius.rounded_sm} ${border_radius.rounded_lg};
  margin-right: ${spacing.sm};
  min-width: 64px;

  ${({ $border }) =>
    $border &&
    css`
      border: 1px solid ${color.n30};
    `}

  &:focus-within {
    outline: 2px solid ${primitive.blue_500};
  }

  &:hover {
    border-color: ${color.n10};
    > * {
      background-color: ${color.n10};
    }
  }
`;

const Container = styled(Link)<{
  $isRadioHidden?: boolean;
  $isTrips?: boolean;
  $border?: boolean;
}>`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  background-color: ${color.white};
  padding: ${spacing.lg};
  border-radius: ${border_radius.rounded_lg};
  border: 2px solid transparent;
  cursor: pointer;
  flex: 1;
  ${A11yOutline};

  ${({ $isRadioHidden }) =>
    $isRadioHidden &&
    css`
      border: 1px solid ${color.n30};
      border-radius: ${border_radius.rounded_md};
    `}

  ${({ $isTrips }) =>
    $isTrips &&
    css`
      border-radius: ${border_radius.rounded_sm} ${border_radius.rounded_lg}
        ${border_radius.rounded_lg} ${border_radius.rounded_sm};
    `}

  ${({ $border }) =>
    $border &&
    css`
      border: 1px solid ${color.n30};
    `}

  &:hover {
    background-color: ${color.n10};

    ${StyledIcon} {
      left: ${spacing.sm};
    }
  }

  &:active {
    background-color: ${color.grey1};
    -webkit-tap-highlight-color: ${color.grey1};
  }

  &:not(:last-child) {
    margin-bottom: ${spacing.md};
  }

  ${desktopLayout} {
    padding: ${spacing.xl};
  }
`;

const SpaceBetween = styled.div<{
  $wrap?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  gap: ${spacing.xs};

  ${({ $wrap }) =>
    $wrap &&
    css`
      flex-wrap: wrap;
      row-gap: ${spacing.md};
    `}
`;

const TransitContainer = styled.div<{
  $compact?: boolean;
}>`
  display: flex;
  width: 100%
    ${({ $compact }) =>
      $compact &&
      css`
        width: inherit;
        flex: 1;
        text-wrap: nowrap;
      `}
    ${screenMinWidth.md} {
    width: auto;
  }
`;

const TagContainer = styled.div`
  padding-top: ${spacing.xs};
  margin-left: auto;
  margin-bottom: ${spacing.xs};
`;

const Tag = styled.div`
  font-weight: ${fontWeight.normal};
  font-size: ${fontSize.sm};
  line-height: 18px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: ${notificationColor.low};
  background-color: #e6f2ee;
  border-radius: ${border_radius.rounded_sm};
  padding: 0 ${spacing.md};
`;

const TypographyHeading = styled.h1`
  font-weight: ${fontWeight.medium};
  color: ${color.cod};
  margin: ${spacing.md} 0;
`;

const TypographyHeadingCompact = styled.h1`
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.lg};
  color: ${color.cod};
  margin: 0 0 ${spacing.md} 0;
`;

const DurationContainer = styled.div`
  font-weight: ${fontWeight.normal};
  font-size: ${fontSize.lg};
  line-height: ${lineHeight.tight};
  color: ${color.cod};
  padding: ${spacing.sm} 0 0 ${spacing.md};
`;

const PriceRange = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.md};
  text-wrap: nowrap;
  justify-content: end;
`;

const PriceRangeCompact = styled(PriceRange)`
  flex-direction: row-reverse;
  flex: 1;
  word-wrap: inherit;

  ${screenMinWidth.md} {
    width: auto;
    flex-direction: inherit;
  }
`;

const TypographyBody = styled.div`
  font-weight: ${fontWeight.normal};
  font-size: ${fontSize.lg};
  line-height: ${lineHeight.tight};
  color: ${color.cod};
`;

const TypographyCta = styled(TypographyBody)`
  font-weight: ${fontWeight.medium};
  color: ${color.pink};
  display: flex;
  align-items: center;
  gap: 2px;
`;

const Wrapper = styled.div<{
  $size?: "sm" | "md";
}>`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: stretch;
  margin-top: ${spacing.md};

  border-radius: ${border_radius.rounded_lg} ${border_radius.rounded_md}
    ${border_radius.rounded_md} ${border_radius.rounded_lg};

  ${({ $size }) => {
    if ($size === "sm") {
      return css`
        ${TypographyHeading} {
          font-size: ${fontSize.lg};
          line-height: ${lineHeight.tight};

          ${desktopLayout} {
            margin: ${spacing.md} 0;
          }
        }
      `;
    } else if ($size === "md") {
      return css`
        ${TypographyHeading} {
          font-size: ${fontSize.h4};
          line-height: ${lineHeight.tight};
          ${desktopLayout} {
            font-weight: ${fontWeight.semibold};
            line-height: ${lineHeight.snug};
            margin: ${spacing.lg} 0;
          }
        }
      `;
    }
  }};
`;
