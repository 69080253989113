import styled from "styled-components";
import { spacing } from "src/design-system/tokens/spacing";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { getScreenKey } from "src/domain/TripPlanner/util/getScreenKey";
import { TRIP_PANE_GAP } from "src/utils/hooks/useGetStackedMapOffset";
import { useEffect, useState } from "react";
import { useScroll } from "src/ScrollContext";
import { dmoPromos } from "./dmoPromos";
import { PromoLink } from "./PromoLink";

type MapPromosProps = {
  destinationCountry: string;
  destinationCanonical: string;
  originCanonical: string;
};

export function MapPromos({
  destinationCountry,
  destinationCanonical,
  originCanonical,
}: MapPromosProps) {
  const [isFirstActive, setIsFirstActive] = useState(true);
  const promoMessages = (dmoPromos[destinationCountry] ?? []).filter(
    (promo) =>
      promo.destinationCanonical !== destinationCanonical &&
      promo.destinationCanonical !== originCanonical
  );
  const location = useTypedLocation();
  const paneStackOpen = getScreenKey(location.hash) !== undefined;
  const { scrollRef } = useScroll();

  useEffect(() => {
    const layoutContainer = scrollRef?.current;

    const closeActivePromo = () => {
      setIsFirstActive(false);
      scrollRef?.current.removeEventListener("click", closeActivePromo);
    };

    if (layoutContainer && isFirstActive) {
      // We only want the first promo to be open when the screen first loads.
      // Once the user starts interacting with explore however, we want to
      // close the promo and revert to standard hover to handle hide/show.
      layoutContainer.addEventListener("click", closeActivePromo);
    }

    return () => {
      layoutContainer?.removeEventListener("click", closeActivePromo);
    };
  }, [scrollRef, isFirstActive]);

  return (
    promoMessages.length && (
      <PromosContainer
        isPaneOpen={paneStackOpen}
        onMouseEnter={() => setIsFirstActive(false)}
      >
        {promoMessages.map((promo, index) => (
          <PromoLink
            key={promo.promoText}
            isActive={index === 0 && isFirstActive}
            {...promo}
          />
        ))}
      </PromosContainer>
    )
  );
}

const PromosContainer = styled.div<{ isPaneOpen: boolean }>`
  position: absolute;
  bottom: ${spacing.xxl};
  left: ${({ isPaneOpen }) =>
    isPaneOpen ? `calc(${spacing.xl} + ${TRIP_PANE_GAP}px)` : `${spacing.xl}`};
  right: 62px; // Space on the right so that the promos do not overlap map controls.
  transition: left 0.3s ease-in-out;

  display: flex;
  flex-wrap: wrap-reverse;
  gap: ${spacing.xl};
`;
