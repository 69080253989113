import styled from "styled-components";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { Button } from "../../../components/Button/Button";
import { spacing } from "../../../theme";
import { TicketExitConfirmation } from "../../../components/TicketExitConfirmation/TicketExitConfirmation";
import { Drawer } from "../../../components/Drawer/Drawer";
import { Dialog } from "../../../components/Dialog/Dialog";
import { useLayout } from "../../../utils/hooks/useLayout";
import useSearch from "../../../utils/hooks/useSearch";
import { getTransitModeFromFirstMajorSegment } from "../createRouteScreenViewModel";
import { useTypedLocation } from "../../../utils/hooks/useTypedLocation";
import { navigateToNewState } from "../../../utils/location/navigateToNewState";
import messages from "./RouteTicketsPromo.messages";

type Props = {
  routeIndex: number;
  className?: string;
};

export function RouteTicketsPromo(props: Props) {
  const intl = useIntl();
  const layout = useLayout();
  const navigate = useNavigate();
  const location = useTypedLocation();
  const { searchResponse } = useSearch();

  const transitMode = searchResponse
    ? getTransitModeFromFirstMajorSegment(searchResponse, props.routeIndex)
    : undefined;

  const handleFindTicketsClick = () => {
    sendAnalyticsInteractionEvent({
      category: "Route",
      action: "Click:TicketExitPicker",
    });
    navigateToNewState(navigate, { ticketPickerView: "route" }, location);
  };

  function closeModal() {
    navigateToNewState(navigate, { ticketPickerView: undefined }, location);
  }

  const isModalOpen = !!location.state?.ticketPickerView;

  return (
    <>
      <Wrapper className={props.className}>
        <StyledButton
          backgroundColor="pink"
          textColor="primaryOnDark"
          size="large"
          onClick={handleFindTicketsClick}
        >
          {intl.formatMessage(messages.selectDatesCta)}
        </StyledButton>
      </Wrapper>
      {layout === "mobile" ? (
        <Drawer
          isOpen={isModalOpen}
          onBackdropClicked={closeModal}
          onCloseClicked={closeModal}
        >
          <TicketExitConfirmation
            routeIndex={props.routeIndex}
            exitPoint={{
              view: "route",
              trigger: "ticketCta",
              transitMode: transitMode,
            }}
          />
        </Drawer>
      ) : (
        <Dialog isOpen={isModalOpen} onBackdropClicked={closeModal}>
          <DialogInner>
            <TicketExitConfirmation
              routeIndex={props.routeIndex}
              exitPoint={{
                view: "route",
                trigger: "ticketCta",
                transitMode: transitMode,
              }}
            />
          </DialogInner>
        </Dialog>
      )}
    </>
  );
}

const Wrapper = styled.div`
  padding: ${spacing.xl};
`;

const StyledButton = styled(Button)`
  margin-top: ${spacing.xl};
`;

const DialogInner = styled.div`
  padding: ${spacing.md} 0 ${spacing.xxl};

  .react-calendar {
    padding: ${spacing.md} ${spacing.xxl} 0;
  }
`;
