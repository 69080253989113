import {
  type ReturnStage,
  useTypedLocation,
} from "../../utils/hooks/useTypedLocation";
import { MultiTripScreen } from "./MultiTrip/MultiTripScreen";
import { getScreenKey } from "./util/getScreenKey";
import { SavedTripsScreen } from "./SavedTripsScreen/SavedTripsScreen";
import { CreateTripScreen } from "./CreateTripScreen/CreateTripScreen";

type Props = {
  returnsFlowLocation?: ReturnStage;
};

export function TripPlannerScreen({ returnsFlowLocation }: Props) {
  const location = useTypedLocation();
  const screenKey = getScreenKey(location.hash);
  const tripScreenKey = getTripScreenKey(screenKey);
  const TripScreen = getTripScreen(tripScreenKey);

  return <TripScreen returnsFlowLocation={returnsFlowLocation} />;
}

type TripScreenKey = "multi" | "saved" | "create";

function getTripScreen(key: TripScreenKey) {
  switch (key) {
    case "multi":
      return MultiTripScreen;
    case "saved":
      return SavedTripsScreen;
    case "create":
      return CreateTripScreen;
  }
}

function getTripScreenKey(screenKey?: string): TripScreenKey {
  switch (screenKey) {
    case "saved":
      return "saved";
    case "create":
      return "create";
    default:
      return "multi";
  }
}
