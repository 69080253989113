import type { Mode } from "src/utils/types/mode";
import { getCountryCode } from "src/utils/countryCode";
import type { SupportedLanguageCode } from "src/utils/language";

type TripTypes = "one-way" | "roundtrip" | "multi-city";

type BaseEvent = {
  arrivalCity?: string;
  arrivalState?: string;
  arrivalCountry?: string;
  siteLanguage?: SupportedLanguageCode;
};

export type HotelEvent = BaseEvent & {
  event: "hotelExit" | "hotelSearch";
  hotelName?: string;
  hotelCheckInDate?: string;
  hotelCheckOutDate?: string;
  numNights?: number;
  hotelStarRating?: number;
  avgDailyRate?: number;
  currencyType?: string;
  numAdults?: number;
  numChildren?: number;
  numRooms?: number;
};

export type TransportSearch = BaseEvent & {
  event: "transportSearch";
  departureCity: string;
  departureState?: string;
  departureCountry?: string;
  travelProductType?: Mode;
  departureDate?: string;
  arrivalDate?: string;
  numPassengers?: number;
  departureAirportCode?: string;
  arrivalAirportCode?: string;
  tripType?: TripTypes;
  price?: string;
  currencyType?: string;
  ticketClass?: string;
};

export type TransportExit = BaseEvent & {
  event: "transportExit";
  departureCity?: string;
  departureState?: string;
  departureCountry?: string;
  travelProductType?: Mode;
  ticketCarriers?: string[];
  departureDate?: string;
  arrivalDate?: string;
  numPassengers?: number;
  departureAirportCode?: string;
  arrivalAirportCode?: string;
  tripType?: TripTypes;
  price?: string;
  currencyType?: string;
  ticketClass?: string;
};

type CarHireExit = BaseEvent & {
  event: "carHireExit";
  departureCity?: string;
};

export type AdaraEvent =
  | TransportSearch
  | TransportExit
  | CarHireExit
  | HotelEvent;

export type AdaraSend = {
  (
    eventMethod: "send",
    eventData: AdaraEvent & {
      referrer: string;
      siteCountry: string | undefined;
    }
  ): void;
};

export function sendAdaraEvent(event: AdaraEvent) {
  window.adara =
    window.adara ||
    function () {
      window.adara.q = window.adara.q || [];
      window.adara.q.push(arguments);
    };

  window.adara("send", {
    ...event,
    siteCountry: getCountryCode(),
    referrer: document.referrer,
  });
}
