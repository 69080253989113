import type { Mode } from "src/utils/types/mode";
import {
  snowplowEntities,
  type SnowplowEntityName,
  type SnowplowEntity,
} from "src/analytics/snowplowEntities";
import type { Analytics } from "../api/SearchResponse";
import { UNSAFE_featureConfigEscapeHatch } from "../feature/UNSAFE_useConfigureFeatureEscapeHatch";
import { browserDomId, browserSessionId } from "./browserIdentifier";
import { formatGaLocationsLabel } from "./formatGaLocationsLabel/formatGaLocationsLabel";
import type { ScreenLocations } from "./getLocations/getLocations";
import type { Screen } from "./generateScreenComponentsRendered/Screen";
import type { ScreenComponent } from "./generateScreenComponentsRendered/ScreenComponent";

type SnowplowGlobalContext = Partial<
  Record<Exclude<SnowplowEntityName, "extraInfo">, SnowplowEntity>
>;
let cachedSnowplowGlobalContext: SnowplowGlobalContext = {};

export type GaPageConfig = {
  pagePath: string;
  pageLocation: string;
};

export type CustomDimensions = Analytics["custom_dimensions"];

type configureAnalyticsParams = {
  pageConfig: GaPageConfig;
  customDimensions?: CustomDimensions;
  screenLabel?: string;
  modeLabel?: string;
  locations?: ScreenLocations;
  userId?: string;
  screen?: Screen;
  modes?: Mode[];
  components?: ScreenComponent[];
};

export function configureAnalytics({
  pageConfig,
  customDimensions = {},
  locations,
  userId,
  screen,
  modes,
  components,
}: configureAnalyticsParams) {
  const domId = browserDomId();
  const localSessionId = browserSessionId();
  const screenLabel = screen
    ? `v1:${screen}:${(components || []).join(":")}`
    : undefined;
  const modeLabel = screen
    ? `v1:${screen}:${(modes || []).join(":")}`
    : undefined;
  const locationLabel =
    screen && locations ? formatGaLocationsLabel(screen, locations) : undefined;

  const commonConfig = {
    ...customDimensions, // this must come first in case we need to override anything
    send_page_view: false,
    page_path: pageConfig.pagePath,
    page_location: pageConfig.pageLocation,
    dimension48: domId,
    dimension49: localSessionId,
    ...(screenLabel && { dimension45: screenLabel }),
    ...(modeLabel && { dimension46: modeLabel }),
    ...(locationLabel && { dimension47: locationLabel }),
    ...(userId && {
      dimension50: userId,
    }),
  };

  const isCustomDimensionsPresent = Object.keys(customDimensions).length !== 0;

  if (!!window.snowplow) {
    cachedSnowplowGlobalContext.windowIdentifiers =
      snowplowEntities.windowIdentifiers.create(domId, localSessionId);

    if (isCustomDimensionsPresent) {
      cachedSnowplowGlobalContext.serverRequest =
        snowplowEntities.serverRequest.create(customDimensions);
      cachedSnowplowGlobalContext.requestIdentifiers =
        snowplowEntities.requestIdentifiers.create(customDimensions);
      cachedSnowplowGlobalContext.search =
        snowplowEntities.search.create(customDimensions);
      cachedSnowplowGlobalContext.seoStore =
        snowplowEntities.seoStore.create(customDimensions);
      cachedSnowplowGlobalContext.experiments =
        snowplowEntities.experiments.create(customDimensions);
      cachedSnowplowGlobalContext.contentSources =
        snowplowEntities.contentSources.create(customDimensions);
    }

    if (screen || components) {
      cachedSnowplowGlobalContext.screenLayout =
        snowplowEntities.screenLayout.create(screen, components);
    }

    if (screen || modes || locations) {
      cachedSnowplowGlobalContext.screenContent =
        snowplowEntities.screenContent.create(screen, modes, locations);
    }

    /**
     * USER GUIDE : Enable GTM preview mode in snowplow pipeline
     * 1. Enter Preview Mode in Google Tag Manager
     * 2. Click on the three dots in the top right corner of the screen and click “Send requests manually”
     * 3. You will see a popup with a preview header, for example (not a real value): ZW52LTh8UDlyMDNRRk1ZWU1MSFRzRWx0QVJ6QXwxOTFlOTg2ZTcxYjAzNDlhNjgwMzE=
     * 4. Copy the header value and set it as the value for gtmPreviewHeader variable
     * 5. Update index.html to log to the production collector url when in dev mode
     */
    const gtmPreviewHeader = null; // stub
    if (gtmPreviewHeader) {
      cachedSnowplowGlobalContext.gtmPreview =
        snowplowEntities.gtmPreview.create(gtmPreviewHeader);
    }

    window.snowplow("clearGlobalContexts");
    window.snowplow(
      "addGlobalContexts",
      Object.values(cachedSnowplowGlobalContext)
    );
    if (userId) {
      window.snowplow("setUserId", userId);
    }
  }

  if (UNSAFE_featureConfigEscapeHatch.LogAnalyticsEventsToConsole) {
    console.log("» %cConfigure Sp", "color:green;", commonConfig);
  }
}
