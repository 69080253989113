import type { MouseEvent, SyntheticEvent } from "react";
import { useIntl } from "react-intl";
import { Skeleton } from "src/components/Skeleton/Skeleton";
import { border_radius } from "src/design-system/tokens/border";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";
import { createTransportKey } from "src/domain/TripPlanner/util/createTransportKey";
import type { PartialSearchResponse } from "src/domain/TripPlanner/util/getPartialSearchResponse";
import { ChevronRightBold } from "src/svg/ChevronRightBold";
import { Close } from "src/svg/Close";
import { desktopLayout, useLayout } from "src/utils/hooks/useLayout";
import styled, { type CSSProperties, css } from "styled-components";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import type { Place, SearchResponse } from "../../../api/SearchResponse";
import type {
  TripPlannerCardType,
  TripPlannerTransportKey,
  TripPlannerURL,
} from "../../../domain/TripPlanner/TripPlannerProvider";
import {
  color,
  fontSize,
  fontWeight,
  lineHeight,
  spacing,
} from "../../../theme";

import type { PriceRange } from "../../../utils/adapters/priceRange";
import type { Mode } from "../../../utils/types/mode";
import { Button } from "../../Button/Button";
import { ButtonBase } from "../../Button/ButtonBase";
import { Icon } from "../../Icon/Icon";

import { transitModeFromSegment } from "../../../utils/adapters/transitMode";
import { placeNumberOffsetPx } from "../Headings/PlaceTitle";
import { messages } from "./TripCard.messages";
import { TripCardModes } from "./TripCardModes";

type TripCardProps = {
  TimelineDetailsContent: JSX.Element;
};

export type TripCardCallback = (url: TripPlannerURL, index: number) => void;

export type TripOriginDestination = {
  origin: Place;
  destination: Place;
};

export type TripCardTypeProps = {
  type: TripPlannerCardType;
  transitMode: Mode;
  url: {
    pathname: string;
    hash: string | undefined;
  };
  searchResponse?: SearchResponse | PartialSearchResponse;
  priceRange?: PriceRange;
  majorModes?: Mode[];
  durationInMinutes?: number;
  clickCallback?: () => void;
  gaLabel?: string;
  isScheduleable?: boolean;
  ContainerOverride?: React.ComponentType<
    React.PropsWithChildren<{
      isPromptLike: boolean;
      isTripsAsCoreHoldback?: boolean;
      onClick?: (event: SyntheticEvent) => void;
    }>
  >;
  modeSummary?: Mode[] | [];
  index?: number;
} & TripOriginDestination;

export function TripCard({
  type,
  majorModes,
  durationInMinutes,
  TimelineDetailsContent,
  origin,
  destination,
  url,
  clickCallback,
  gaLabel,
  isScheduleable,
  ContainerOverride,
}: TripCardProps & TripCardTypeProps) {
  const intl = useIntl();
  const layout = useLayout();
  const buttonTitle = intl.formatMessage(messages.unsave);
  const isPromptLike = ["search", "searchPrompt"].includes(type);
  const ctaText = isScheduleable ? messages.schedulesCTA : messages.overview;
  const Container = ContainerOverride ?? TripCardContainer;
  const { dispatch } = useTripPlannerContext();

  const tripCardClickHandler = (event: SyntheticEvent) => {
    event.preventDefault();
    if (clickCallback) {
      clickCallback();
    }
  };

  let canonicalPair: TripPlannerTransportKey | undefined;
  if (origin.canonicalName && destination.canonicalName) {
    canonicalPair = createTransportKey(
      origin.canonicalName,
      destination.canonicalName
    );
  }

  if (!canonicalPair) {
    throw new Error(
      `Invalid canonicalPair for ${origin.canonicalName} -> ${destination.canonicalName}`
    );
  }

  function handleRemoveClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    if (canonicalPair) {
      dispatch({
        type: "REMOVE_TRANSPORT",
        originDestinationKey: canonicalPair,
      });
      sendAnalyticsInteractionEvent({
        category: "TripPlanner",
        action: "Deselect:TripOption",
        label: gaLabel,
      });
    }
  }

  return (
    <Container
      onClick={tripCardClickHandler}
      href={url.hash}
      isPromptLike={isPromptLike}
    >
      {!isPromptLike && (
        <TripCardRow $margin={`0 0 ${spacing.sm}`} align="center">
          {majorModes && (
            <TripCardModes
              majorModes={majorModes}
              duration={durationInMinutes}
            />
          )}
          <DeleteButton onClick={handleRemoveClick}>
            <Icon size={layout === "desktop" ? "sm-1" : "xs"}>
              <Close title={buttonTitle} tint="n50" />
            </Icon>
          </DeleteButton>
        </TripCardRow>
      )}

      <TripCardRow align="stretch">
        <TripCardDetails>{TimelineDetailsContent}</TripCardDetails>
      </TripCardRow>

      {!isPromptLike && (
        <TripCardRow align="center">
          <CTAButton
            rightIcon={<ChevronRightBold tint="n300" />}
            size="small"
            rightIconSize="sm"
            backgroundColor="n20"
            borderRadius="rounded_xxl"
            textColor="primaryOnLight"
            title={intl.formatMessage(ctaText)}
            inline
            onClick={tripCardClickHandler}
          >
            {intl.formatMessage(ctaText)}
          </CTAButton>
        </TripCardRow>
      )}
    </Container>
  );
}

export function getTripCardAnalyticsLabel(
  overwrite: boolean,
  searchResponse: SearchResponse | PartialSearchResponse,
  routeIndex?: number,
  segmentIndex?: number
) {
  const label: (string | number)[] = ["tc0"];
  if (overwrite) {
    label.push("Override");
  }
  const origin = searchResponse.places[0];
  const destination = searchResponse.places[1];

  if (origin && origin.canonicalName) {
    label.push(origin.canonicalName);
  }
  if (destination && destination.canonicalName) {
    label.push(destination.canonicalName);
  }

  if (typeof routeIndex === "number" && !isNaN(routeIndex)) {
    label.push(searchResponse.routes[routeIndex].canonicalName ?? routeIndex);
  }

  if (typeof segmentIndex === "number" && !isNaN(segmentIndex)) {
    label.push(segmentIndex);
  }

  return label.join(":");
}

export function getTripRouteTransitModes(
  searchResponse: SearchResponse | PartialSearchResponse,
  routeIndex: number
): Mode[] {
  const route = searchResponse.routes[routeIndex];
  const segmentModes = route.segments.map((segment, i) => {
    const isMajor = searchResponse.segments[segment].isMajor;
    return isMajor
      ? transitModeFromSegment(searchResponse, routeIndex, i)
      : false;
  });
  return segmentModes.filter((mode): mode is Mode => Boolean(mode));
}

export function TripCardSkeleton() {
  return (
    <TripCardContainer isPromptLike={false}>
      <TripCardRow align="center" justify="space-between">
        <Skeleton
          borderRadius="rounded_xl"
          margin={`0 0 ${spacing.xs} 0`}
          width="28px"
          height="16px"
        />
        <Skeleton
          borderRadius="rounded_xl"
          margin={`${spacing.xs} 0`}
          width="20px"
          height="16px"
        />
      </TripCardRow>
      <TripCardRow align="center">
        <Skeleton
          borderRadius="rounded_xl"
          margin={`${spacing.xs} 0`}
          width="180px"
          height="16px"
        />
      </TripCardRow>
      <TripCardRow align="center">
        <Skeleton
          borderRadius="rounded_xl"
          margin={`${spacing.xs} 0`}
          width="180px"
          height="16px"
        />
      </TripCardRow>
      <TripCardRow align="center" justify="flex-end">
        <Skeleton
          borderRadius="rounded_xl"
          margin={`${spacing.xs} 0`}
          width="105px"
          height="32px"
        />
      </TripCardRow>
    </TripCardContainer>
  );
}

const DeleteButton = styled(ButtonBase)`
  padding: ${spacing.md};
  margin: -${spacing.md} -${spacing.md} -${spacing.md} auto;
  ${desktopLayout} {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
`;

const TripCardFooter = css`
  padding-top: ${spacing.md};
  margin-top: ${spacing.md};
  font-weight: ${fontWeight.medium};
  border-top: 1px solid ${color.n30};
`;

const TripCardRow = styled.div<{
  borderTop?: boolean;
  footer?: boolean;
  justify?: CSSProperties["justifyContent"];
  align: "center" | "stretch";
  $margin?: string;
}>`
  display: flex;
  position: relative;
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  ${({ footer }) => footer && TripCardFooter};
  ${({ $margin }) => $margin && `margin: ${$margin};`}
`;
const TripCardDetails = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  width: 100%;
  font-weight: ${fontWeight.medium};
`;

const CTAButton = styled(Button)`
  margin-left: auto;
  font-size: ${fontSize.h6};

  ${desktopLayout} {
    border: 1px solid transparent;

    &:hover {
      background-color: ${color.n20};
    }
  }
`;

const TripCardContainer = styled.a<{
  isPromptLike: boolean;
}>`
  display: block;
  text-decoration: none;
  background-color: ${({ isPromptLike }) =>
    isPromptLike ? color.n10 : color.white};
  border: 1px solid transparent;
  border-radius: ${border_radius.rounded_md};

  padding: ${({ isPromptLike }) => {
    return !isPromptLike
      ? `${spacing.lg} ${spacing.lg} ${spacing.lg} ${spacing.xl}`
      : `${spacing.lg} ${spacing.md} ${spacing.lg} ${
          placeNumberOffsetPx - 5
        }px`;
  }};

  color: ${color.cod};
  font-size: ${fontSize.h6};

  ${desktopLayout} {
    &:hover {
      border-color: ${color.n40};

      ${CTAButton} {
        background-color: ${color.n20};
        border-color: ${color.n40};
      }
      ${DeleteButton} {
        opacity: 1;
      }
    }
  }
`;

export const CardDetailTitle = styled.span`
  color: ${color.n80};
  display: block;
  font-size: ${fontSize.h5};
  line-height: ${lineHeight.tight};
  margin-bottom: ${spacing.xs};
`;
export const CardDetailSubtitle = styled.span`
  display: block;
  font-size: ${fontSize.h6};
  color: ${color.n90};
  font-weight: ${fontWeight.normal};
  margin-bottom: ${spacing.sm};
`;
