import { useEffect, useRef } from "react";
import { LabelMd } from "src/design-system/components/Typography/Typography";
import { border_radius } from "src/design-system/tokens/border";
import color from "src/design-system/tokens/color";
import {
  sendAnalyticsInteractionEvent,
  sendAnalyticsNonInteractionEvent,
} from "src/analytics/sendAnalyticsEvent";
import { useIsInViewport } from "src/utils/hooks/useIsInViewport";
import { font_weight } from "src/design-system/tokens/typography";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import styled from "styled-components";
import { spacing } from "src/design-system/tokens/spacing";
import type { PromoItem } from "./dmoPromos";

const POPOVER_GAP = spacing.md;

type PromoLinkProps = PromoItem & {
  isActive: boolean;
};

export function PromoLink({
  promoText,
  destinationCanonical,
  imgSrc,
  isActive,
}: PromoLinkProps) {
  const { pathname, search, hash } = useTypedLocation();
  const linkRef = useRef<HTMLAnchorElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const isInView = useIsInViewport(linkRef);

  useEffect(() => {
    if (isInView) {
      sendAnalyticsNonInteractionEvent({
        category: "MapView",
        action: "Viewed:DMOMapPromo",
        label: promoText,
      });
    }
  }, [isInView, promoText]);

  useEffect(() => {
    sendAnalyticsNonInteractionEvent({
      category: "MapView",
      action: "Render:DMOMapPromo",
      label: promoText,
    });
  }, [promoText]);

  const handleClick = () => {
    sendAnalyticsInteractionEvent({
      category: "MapView",
      action: "Click:DMOMapPromo",
      label: promoText,
    });
    // We will be overriding the destination so we only keep the path
    // parts of the 'map' and 'origin'.
    const basePath = pathname.slice(0, pathname.lastIndexOf("/"));
    const newPath = `${basePath}/${destinationCanonical}`;

    window.open(`${newPath}${search}${hash}`, "_blank");
  };

  const handleMouseEnter = () => {
    sendAnalyticsInteractionEvent({
      category: "MapView",
      action: "Hover:DMOMapPromo",
      label: promoText,
    });

    // Reposition image popover if it's too close to the right
    // so that it doesn't get chopped off.
    // For simplicity, this assumes the window right rather than
    // factoring in the right rail, as that only shows on wider
    // screens where there should be enough room for 2-3 promos.
    if (
      popoverRef.current &&
      window.innerWidth - popoverRef.current.getBoundingClientRect().right < 16
    ) {
      popoverRef.current.style.left = "auto";
      popoverRef.current.style.right = "0";
    }
  };

  return (
    <LinkContainer
      ref={linkRef}
      onMouseEnter={handleMouseEnter}
      onClick={handleClick}
      className={isActive ? "active" : ""}
    >
      <LinkText>{promoText}</LinkText>
      <ImgPopover ref={popoverRef}>
        <ImgBorder>
          <PromoImg src={imgSrc} alt={promoText} />
        </ImgBorder>
      </ImgPopover>
    </LinkContainer>
  );
}

const LinkContainer = styled.a`
  position: relative;
  cursor: pointer;
  padding: ${spacing.lg};
  background-color: ${color.bg.fill.fill};
  border-radius: ${border_radius.rounded_lg};
`;

const LinkText = styled(LabelMd)`
  position: relative;
  color: ${color.text.text};
  font-weight: ${font_weight.medium};
  text-decoration: underline;

  a:hover > &,
  a.active > & {
    text-decoration: none;

    &::after,
    &::before {
      content: "";
      position: absolute;
      top: -${spacing.xxl};
      left: 50%;
      transform: translateX(-50%);
    }

    // Adds a small triangle underneath the image.
    &::after {
      z-index: 2;
      border-left: ${spacing.md} solid transparent;
      border-right: ${spacing.md} solid transparent;
      border-top: ${spacing.md} solid ${color.bg.fill.fill};
    }

    // Applies grey border to the triangle.
    &::before {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid ${color.border.border};
    }
  }
`;

const ImgPopover = styled.div`
  display: none;
  position: absolute;
  left: 0;
  bottom: calc(100% + ${POPOVER_GAP});
  z-index: 1;

  border-radius: ${border_radius.rounded_lg};
  background-color: ${color.border.border};
  padding: 1px;

  // Ensures hover is maintained when moving the
  // cursor between the link text and the image.
  &::after {
    content: "";
    display: block;
    position: absolute;
    inset: 100% 0 -${POPOVER_GAP};
  }

  a:hover > &,
  a.active > & {
    display: block;
  }
`;

const ImgBorder = styled.div`
  position: relative;
  display: block;
  height: 120px;
  width: 180px;

  border-radius: ${border_radius.rounded_lg};
  border: ${spacing.sm} solid ${color.bg.fill.fill};
  overflow: hidden;
`;

const PromoImg = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;
