import type { GeocodedPlace } from "src/PrefetchData";
import type { TripPlannerDetails } from "../TripPlannerProvider";

export type PlacePair = {
  origin: GeocodedPlace;
  destination: GeocodedPlace;
};

export function getPlacePairs(
  tripPlannerDetails: TripPlannerDetails
): PlacePair[] {
  const { places } = tripPlannerDetails;
  const placePairs: PlacePair[] = [];

  places.forEach((_, i) => {
    const doesNextPlaceExist = i + 1 < places.length;
    if (doesNextPlaceExist) {
      placePairs.push({ origin: places[i], destination: places[i + 1] });
    }
  });
  return placePairs;
}
